import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../contexts/store'
import { TransitionGroup } from 'react-transition-group'
import api from '../../../api/api'
import { Op } from '../../../../../types/Ops'
import { Box, Button, Collapse, List, Skeleton, Stack } from '@mui/material'

import DateIndicator from './DateIndicator'
import IconStream from './IconStream'
import Operation from './Operation'
import { PodClass } from '../../../classes/Pod'

const Activity = ({initScrollPos}: {initScrollPos: (tab: string) => void}) => {
  const { t } = useTranslation()
  const { podStore, uiStore } = useStore()
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isLoadingMore, setIsLoadingMore] = useState(false)
  const [ isReady, setIsReady] = useState(false)
  const [ moreActivity, setMoreActivity ] = useState<Op[]>([])
  const pod:PodClass|null = podStore.pod
  const podId = pod ? pod.podId : ''
  const activityData:Op[] = pod ? podStore.getPodActivity(pod.podId) : []

  useEffect(() => {
    const refreshPodActivity = async() => {
      setIsLoading(true)
      const data = await api.getPodActivity(podId, 0)
      if (data && data.status === 200) {
        podStore.setPodActivity(data.body)
      }
      setIsLoading(false)
    }

    if ((!isLoading) && (!activityData?.length)) {
      if (podId) refreshPodActivity()
    }

    setIsReady(true)
  }, [activityData?.length, isLoading, podId, podStore])

  useLayoutEffect(() => {
    initScrollPos("activity")
  })

  if (!pod) return null
  const deviceWidth = uiStore.deviceWidth
  const activities = [...activityData, ...moreActivity]

  // Determine the lowest currently rendered oid
  const lowestOidInActivity = activityData[activityData.length-1]?.oid
  const lowestOidInMoreActivity = moreActivity[moreActivity.length-1]?.oid
  let before:number = 0
  if (lowestOidInActivity) before = lowestOidInActivity
  if (lowestOidInActivity && lowestOidInMoreActivity) before = Math.min(lowestOidInActivity, lowestOidInMoreActivity)

  const loadMore = async () => {
    setIsLoadingMore(true)
    const data = await api.getPodActivity(podId, before)
    if (data && data.status === 200) {
      setMoreActivity([...moreActivity, ...data.body])
    }
    setIsLoadingMore(false)
  }

  const scrollTop = uiStore.getPodScrollPos(podId, "activity")
  if (isLoading || !isReady) return <Stack spacing={1}><div>&nbsp;</div><Box sx={{ height: scrollTop ? scrollTop : 0}} />{Array.from({length: 10}, (x, i) => <Box key={i} sx={{
    alignItems: "center",
    display: "grid",
    gridGap: "30px",
    gridTemplateColumns: "min-content auto min-content min-content",
    margin: "10px 30px"
  }}>
    <Skeleton variant="circular" width={40} height={40} />
    <Box>
      <Skeleton variant="text" sx={{ fontSize: '1rem', width: "60%" }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem', width: "20%" }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem', width: "18%" }} />
    </Box>
    <Skeleton variant="rectangular" width={40} height={40} sx={{alignSelf: "start"}} />
  </Box>)}
 </Stack>

  const objectExists = (op:Op) => {
    switch(op.op) {
      case 'addComment':
        if (pod.content.pdfFiles[op.data.anchor.nodeId] && (!pod.nodeIsHidden(op.data.anchor.nodeId)) && pod.content.pdfFiles[op.data.anchor.nodeId].comments[op.data.interactionId] && pod.isVisible('comment', op.data.interactionId)) return true
        break
      case 'addMessage':
        const interaction = pod.getInteractionFromThreadId(op.data.threadId)
        if (interaction && pod.content.pdfFiles[interaction.anchor.nodeId] && (!pod.nodeIsHidden(interaction.anchor.nodeId))) {
          const thread = pod.content.threads[op.data.threadId]
          if (thread) {
            const messageIndex = thread.messages.findIndex((m) => m.messageId === op.data.messageId)
            if ((messageIndex > -1) && (pod.isVisible('message', op.data.messageId))) return true
          }
        }
        break
      case 'addLink':
        if (pod.content.pdfFiles[op.data.anchor.nodeId] && (!pod.nodeIsHidden(op.data.anchor.nodeId)) && pod.content.pdfFiles[op.data.anchor.nodeId].links[op.data.interactionId]) {
          const link = pod.content.pdfFiles[op.data.anchor.nodeId].links[op.data.interactionId]
          const other = pod.getLinkOther(link)
          if ((link && pod.isVisible('link', op.data.interactionId)) && (other && pod.isVisible('link', other.interactionId))) return true
        }
        break
      case 'addTagging':
        if (pod.content.pdfFiles[op.data.anchor.nodeId] && (!pod.nodeIsHidden(op.data.anchor.nodeId)) && pod.content.pdfFiles[op.data.anchor.nodeId].taggings[op.data.interactionId] && pod.isVisible('tagging', op.data.interactionId)) return true
        break
      case 'addWeblink':
        if (pod.content.pdfFiles[op.data.anchor.nodeId] && (!pod.nodeIsHidden(op.data.anchor.nodeId)) && pod.content.pdfFiles[op.data.anchor.nodeId].weblinks[op.data.interactionId] && pod.isVisible('weblink', op.data.interactionId)) return true
        break
      case 'addEmotion':
        if (pod.content.pdfFiles[op.data.anchor.nodeId] && (!pod.nodeIsHidden(op.data.anchor.nodeId)) && pod.content.pdfFiles[op.data.anchor.nodeId].emotions[op.data.interactionId] && pod.isVisible('emotion', op.data.interactionId)) return true
        break
      case 'addUserToPod':
        return true
      case 'removeUserFromPod':
        return true
      case 'addPdfFile':
        if (pod.content.pdfFiles[op.data.nodeId] && (!pod.nodeIsHidden(op.data.nodeId)) && pod.isVisible('pdfFile', op.data.nodeId)) return true
        break
      default:
        console.warn("unknown op:", op.op)
    }
    return false
  }

  // optimized for tiny screens
  const TinyScreenStyle = ({activity, prevTCreated, userChanged}: {activity : Op, prevTCreated: number | null | undefined, userChanged: boolean}) => (
    <Collapse key={activity.oid}>
      <DateIndicator tCreated={activity.tCreated} prevTCreated={prevTCreated} sx={{fontSize: "10px", padding: "10px"}} />
      <Box sx={{display: "grid", gridTemplateColumns: `min-content auto`}}>
        <IconStream interactionType={activity.op} />
        <Operation op={activity} userChanged={userChanged} />
      </Box>
    </Collapse>
  )

  // remember previous activity
  let prevActivity: Op | null = null
  return <>
          <List >
            <TransitionGroup>
              {activities.filter((activity) => objectExists(activity)).map((activity) => {
                const prevTCreated = prevActivity?.tCreated
                const prevUserId = prevActivity?.data.userId
                const userChanged = activity.data.userId !== prevUserId ? true : false
                prevActivity = activity
                if(deviceWidth === "tiny") return TinyScreenStyle({activity, prevTCreated, userChanged})
                else return (
                  <Collapse key={activity.oid}>
                    <Box sx={{display: "grid", gridTemplateColumns: "110px min-content auto"}}>
                      <DateIndicator tCreated={activity.tCreated} prevTCreated={prevTCreated} sx={{fontSize: "14px"}} />
                      <IconStream interactionType={activity.op} />
                      <Operation op={activity} userChanged={userChanged} />
                    </Box>
                  </Collapse>
                )
              })}
            </TransitionGroup>
          </List>
          <Box sx={{display: "grid"}}>
            <Button disabled={(!Boolean(before)) || (isLoadingMore)} onClick={loadMore}>{t('Load More')}</Button>
          </Box>
        </>
}

export default observer(Activity)