import React from "react"
import { Box, Slider } from "@mui/material"
import { useStore } from "../../../contexts/store"
import { observer } from "mobx-react-lite"

const ZoomSlider = ({storeId}: {storeId: string}) => {

  const { pdfMetaStore } = useStore()

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      pdfMetaStore.setScale(storeId, (newValue/50)+.5)
    }
  }  

  return <Box sx={{ backgroundColor: "whitesmoke", display: "grid", width: "55px", height:"100px", p: "8px" }}>
    <Slider
      size="small"
      aria-label="Temperature"
      orientation="vertical"
      valueLabelDisplay="off"
      value={(pdfMetaStore.getScale(storeId)-.5)*50}
      //onChange={debounce(handleChange, 100)}
      onChange={handleChange}
    />
  </Box>
}

export default observer(ZoomSlider)
