import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PodClass } from '../../classes/Pod'
import { Box, IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../contexts/store'
import PdfFileLine from './PdfFile'
import { CreateNewFolder, UploadFile } from '@mui/icons-material'
import { Op_addFolder } from '../../../../types/Ops'
import { PdfFile } from '../../../../types/Content'
import { Folder as FolderType } from '../../../../types/Content'
import Folder from './Folder'
import Prompt from '../Elements/Prompt'

const Contents = ({pod, initScrollPos}: {pod: PodClass, initScrollPos: (tab: string) => void}) => {
  const { sessionStore, podStore, opStore } = useStore()
  const { t } = useTranslation()
  const [ newFolderDialog, setNewFolderDialog ] = useState(false)
  const [ isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  useLayoutEffect(() => {
    initScrollPos("contents")
  })

  // show blank page until elements are rendered
  if(isRendered === false) return null

  const isAdmin = pod.isAllowed('deletePod', pod.podId)

  const createFolder = (folderName:string) => {

    if (folderName) {
      var weight = 0
      for (const nodeId in pod.content.pdfFiles) {
        weight = Math.max(weight, pod.content.pdfFiles[nodeId].weight)
      }

      const op:Op_addFolder = {
        op: 'addFolder',
        podId: pod.podId,
        data: {
          userId: sessionStore.session.user.userId,
          userName: podStore.userPseudonym || '',
          folderId: sessionStore.createUuid(),
          name: folderName,
          weight: weight + 100,
          coid: null,
          tCreated: Date.now(),
          tModified: Date.now()
        }
      }

      opStore.doOp(op)
    }
  }

  // generates view of folders and files
  const PodPanelElement = () => {
    // function: return files inside a folder
    const fileListing = (folderId: string) => {
      return pod.getPdfFiles().filter((file: PdfFile) => ((file.status === 'complete') && (!file.hidden || isAdmin) && (file.folderId === folderId))).sort((a: any, b: any) => { if (a.weight<b.weight) return -1; if (a.weight>b.weight) return 1; return 0 }).map((file: PdfFile) => {
        return (
          <li key={file.nodeId}>
            <PdfFileLine pdfFile={file} />
          </li>
        )
      })
    }
    // data tree
    const elements: object[] = []
    // files without folder
    pod.getPdfFiles().filter((file: PdfFile) => ((file.status === 'complete') && (!file.hidden || isAdmin) && (!file.folderId))).forEach((file: PdfFile) => {
      elements.push({
        weight: file.weight,
        obj: (
          <li key={file.nodeId}>
            <PdfFileLine pdfFile={file} />
          </li>
        )
      })
    })
    // folder with files inside
    pod.getFolders().filter((folder:FolderType) => (!folder.hidden || isAdmin)).forEach((folder: FolderType) => {
      elements.push({
        weight: folder.weight,
        obj: (
          <li key={folder.folderId}>
              <Folder isAdmin={isAdmin} folder={folder} fileListing={fileListing} />
          </li>
        )
      })
    })
    return (
      <ul style={{listStyleType: "none", padding: 5 }}>
          {elements.sort((a: any, b: any) => { if (a.weight<b.weight) return -1; if (a.weight>b.weight) return 1; return 0 }).map((element: any) => element.obj)}
      </ul>
    )
  }

  const PodPanel = observer(PodPanelElement)

  return <>
      <PodPanel />
      { !isAdmin ? null : <Box style={{textAlign:'right', marginTop: 48}}>

        <IconButton size='medium' onClick={() => setNewFolderDialog(true)}>
          <CreateNewFolder fontSize='medium' />
        </IconButton>

        <IconButton size='medium' onClick={(e:React.MouseEvent) => { alert('Hopefully coming soon') } }>
          <UploadFile fontSize='medium' />
        </IconButton>
      </Box> }
      <Prompt title={t('New Folder')} info={t('Please enter a name for the new folder') as string} open={newFolderDialog} handleClose={() => setNewFolderDialog(false)} onOk={createFolder} />
  </>
}

export default observer(Contents)