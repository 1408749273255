import { useLayoutEffect } from "react"
import { observer } from "mobx-react-lite"
import { PodClass } from "../../classes/Pod"
import { Box, Checkbox, FormControlLabel, Switch } from "@mui/material"
import { useStore } from '../../contexts/store'
import { Usergroup } from "../../../../types/Pod"
import { useTranslation } from "react-i18next"
import { green } from '@mui/material/colors';
import { OpCode } from "../../../../types/OpCodes"
import React from "react"

const Settings = ({initScrollPos}: {initScrollPos: (tab: string) => void}) => {
  const { podStore } = useStore()
  const { t } = useTranslation()
  const isAdmin:boolean = podStore.pod?.isAllowed('deletePod', podStore.pod?.podId) ? true : false

  useLayoutEffect(() => {
    initScrollPos("settings")
  })

  const [showReduced, setShowReduced] = React.useState<boolean>(!isAdmin)

  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  const perm = (group:Usergroup, opCode:string) => {
    if (group.permissions.indexOf(opCode)>-1)
      return <div key={opCode} style={{}}><Checkbox disabled={true} defaultChecked sx={{padding:0,'&.Mui-checked': {color: green[600]}}} />{t(opCode)}</div>
    else
      return showReduced ? null : <div key={opCode} style={{}}><Checkbox disabled={true} sx={{padding:0}} />{t(opCode)}</div>
  }

  const permissionSet = (name:string, group:Usergroup, perms:OpCode[]) => {
    const permissions = perms.map(p => perm(group, p))
    if (permissions.filter(p => p !== null).length) return <div>
      {name}
      <Box style={{paddingLeft:10, paddingBottom:10}}>
        {permissions}
      </Box>
    </div>

    return null
  }

  const permissionsGroup = (name:string, group:Usergroup, sets:any) => {
    if (sets.filter((s:any) => s !== null).length) return <>
      <h4>{name}</h4>
      {sets.map((s:any, i:number) => <div key={i}>{s}</div>)}
    </>

    return null
  }

  return <Box>
    <h2>{t('Groups in this Pod')}</h2>
    <FormControlLabel control={<Switch checked={showReduced} onChange={() => setShowReduced(!showReduced)} />} label={t('Show only permissions I have')} />
    {Object.keys(pod.usergroups).map((usergroupId:string) => {
      const group:Usergroup = pod.usergroups[usergroupId]

      return <Box key={usergroupId} style={{clear:'both'}}>
        <h3>{t('Via Group "{{groupname}}"', {groupname: group.name})}</h3>
        {
        permissionsGroup('Pod Administration', group, [
          permissionSet('Folder', group, ['addFolder', 'editFolder', 'deleteFolder']),
          permissionSet('Files:', group, ['addPdfFile', 'editPdfFile', 'deletePdfFile']),
          permissionSet('Users:', group, ['addUserToPod', 'removeUserFromPod', 'editUserInfo'])
        ])
        }
        {
        permissionsGroup('Pod Collaboration', group, [
          permissionSet('Tags:', group, ['addTag', 'editTag', 'deleteTag']),
          permissionSet('Conversations:', group, ['addThread', 'addMessage', 'editMessage', 'deleteMessage']),
        ])
        }
        {
        permissionsGroup('Interactions', group, [
          permissionSet('Annotations:', group, ['addAnnotation', 'editAnnotation', 'deleteAnnotation']),
          permissionSet('Comments:', group, ['addComment', 'editComment', 'deleteComment']),
          permissionSet('Emotions:', group, ['addEmotion', 'editEmotion', 'deleteEmotion']),
          permissionSet('Links:', group, ['addLink', 'editLink', 'deleteLink']),
          permissionSet('Taggings:', group, ['addTagging', 'editTagging', 'deleteTagging']),
          permissionSet('Weblinks:', group, ['addWeblink', 'editWeblink', 'deleteWeblink']),
        ])
        }
      </Box>
    })}
  </Box>



}

export default observer(Settings)