import { observer } from "mobx-react-lite"
import { useStore } from "../../../contexts/store"
import { OpCode } from "../../../../../types/OpCodes"
import InteractionIcon from "../../Elements/InteractionIcon"
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material"

const InteractionSelector: React.FC = () => {
  const { uiStore, podStore } = useStore()
  const selectedInteraction = uiStore.getSelectedInteraction()
  const pod = podStore.pod
  if (!pod) return null

  // check which add operations are allowed
  const operations:OpCode[] = ['addAnnotation', 'addComment', 'addLink', 'addTagging', 'addWeblink', 'addEmotion']
  const allowedOperations: string[] = []
  for(const op of operations) {
    const isAllowed = pod.isAllowed(op)
    // TODO: remove op addEmotion exception, if fixed in backend
    if(isAllowed || op === "addEmotion") allowedOperations.push(op)
  }

  const handleInteractionChange = (
    _event: React.MouseEvent<HTMLElement>,
    selectedInteraction: OpCode,
  ) => {
    // at least one button must be active
    if(selectedInteraction !== null) {
      // update component
      uiStore.setSelectedInteraction(selectedInteraction)
    }
  }
  const interactionType = (op: string) => {
    if(op === "addAnnotation") return "annotation"
    if(op === "addComment") return "comment"
    if(op === "addLink") return "link"
    if(op === "addTagging") return "tagging"
    if(op === "addWeblink") return "weblink"
    if(op === "addEmotion") return "emotion"
    return null
  }

  return (
    <Box sx={{ backgroundColor: "whitesmoke", display: "grid", width: "55px", p: "3px" }}>
      <ToggleButtonGroup
        aria-label="select interaction type"
        color="secondary"
        exclusive
        onChange={handleInteractionChange}
        orientation="vertical"
        value={selectedInteraction}
        disabled={uiStore.copyMode}
      >
        {allowedOperations.map((op: string) => (
          <ToggleButton key={op} value={uiStore.copyMode ? `${op}-disabled`:op} aria-label={op} disabled={uiStore.copyMode}>
            <InteractionIcon interactionType={interactionType(op)}/>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  )
}

export default observer(InteractionSelector)
