import { observer } from "mobx-react-lite"
import { useEffect, useLayoutEffect, useState } from "react"
import { useStore } from "../../../contexts/store"
import { useParams } from "../../../helper/Helper"
import { ConversationItem } from "../../../../../types/Miscs"

import ActiveConversation from "./AcitveConversation"
import ConversationList from "./ConversationList"
import { Box } from "@mui/material"


const Conversations = ({initScrollPos}: {initScrollPos: (tab: string) => void}) => {
  const { podId } = useParams()
  const { podStore, sessionStore, uiStore } = useStore()
  const [ isRendered, setIsRendered] = useState(false)
  const userId = sessionStore.session.user.userId
  // load list of conversations in which the user is involved
  const conversationList = podStore.getConversations(userId)

  useLayoutEffect(() => {
    initScrollPos("conversations")
  })

  useEffect(() => {
    setIsRendered(true)
  }, [])

  // show blank page until elements are rendered
  if(isRendered === false) return null
  if(!userId || conversationList === null) return null

  // sort conversation list by time of last written message
  conversationList.sort((a: ConversationItem, b: ConversationItem) => {
    if (a.tLastMessage > b.tLastMessage) return -1
    if (a.tLastMessage < b.tLastMessage) return 1
    return 0
  })

  // large screen: show list of conversations and the thread side by side
  const SharedView = () => {
    return (
      <Box className="overflowWrapper" sx={{display: "grid", gridTemplateColumns: "2fr 3fr"}}>
        <ConversationList conversationList={conversationList} podId={podId} />
        <ActiveConversation conversationList={conversationList} podId={podId} />
      </Box>
    )
  }

  return (
    <Box className="overflowWrapper" sx={{borderTop: "1px solid rgba(63, 67, 80, 0.16)"}}>
      {(uiStore.deviceWidth === "tiny" || uiStore.deviceWidth === "small") ? (
        // small screen: show either the list of conversations or the active thread
        <ActiveConversation conversationList={conversationList} podId={podId} />
      ) : (
        <SharedView />
      )}
    </Box>
  )
}

export default observer(Conversations)
