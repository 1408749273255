import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../contexts/store'
import { InteractionType } from '../../../../types/Interaction'

import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { navigate } from 'wouter/use-location'
import { Op, Op_deleteAnnotation, Op_deleteComment, Op_deleteEmotion, Op_deleteLink, Op_deleteMessage, Op_deleteTagging, Op_deleteWeblink } from '../../../../types/Ops'
import ConfirmDialog from './ConfirmDialog'
import { OpCode } from '../../../../types/OpCodes'

const EditMenu = ({
    interactionId,
    interactionType,
    pdfId,
    podId,
    threadId,
    messageId
  }: {
    interactionId: string,
    interactionType: InteractionType,
    pdfId: string,
    podId: string,
    threadId?: string
    messageId?: string
  }) => {
  const { t } = useTranslation()
  const { opStore, podStore, uiStore, sessionStore } = useStore()
  const pdfUrl = `/pod/${podId}/pdf/${pdfId}`
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)
  const open = Boolean(anchorEl)
  const pod = podStore.pod
  if (!pod) return null

  // test which operation is allowed with this interaction
  const capitalType = interactionType.charAt(0).toUpperCase() + interactionType.slice(1)
  const editIsAllowed = pod.isAllowed(`edit${capitalType}` as OpCode, interactionType !== "message" ? interactionId : messageId)
  const deleteIsAllowed = pod.isAllowed(`delete${capitalType}` as OpCode, interactionType !== "message" ? interactionId : messageId)

  // hide edit menu
  if(!editIsAllowed && !deleteIsAllowed) return null

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    handleClose()
    // if link: navigate to editLink view
    if(interactionType === "link") navigate(`/pod/${podId}/pdf/${pdfId}/editLink/${interactionId}`)
    // if message: set messageId in uiStore
    else if(interactionType === "message" && messageId) {uiStore.setEditedMessageId(messageId)}
    // otherwise: open edit modal
    else {
      uiStore.setEditedMessageId(null)
      uiStore.openInteractionModal(interactionId)
    }
  }

  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    handleClose()
    setConfirmDialogOpen(true)
  }

  const deleteInteracation = () => {
    let op:Op|null = null;
    switch(interactionType) {

      case 'annotation':
        const castOpDelAnn:Op_deleteAnnotation = {
          op: 'deleteAnnotation',
          podId,
          data: {
            userId: sessionStore.session.user.userId,
            userName: podStore.userPseudonym as string,
            usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
            interactionId,
          }
        }
        op = castOpDelAnn
        break

      case 'comment':
        const castOpDelCom:Op_deleteComment = {
            op: 'deleteComment',
            podId,
            data: {
              userId: sessionStore.session.user.userId,
              userName: podStore.userPseudonym as string,
              usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
              interactionId
            }
          }
          op = castOpDelCom
          break

      case 'link':
        const castOpDelLink:Op_deleteLink = {
            op: 'deleteLink',
            podId,
            data: {
              userId: sessionStore.session.user.userId,
              userName: podStore.userPseudonym as string,
              usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
              interactionId
            }
          }
          op = castOpDelLink
          break

      case 'tagging':
        const castOpDelTagging:Op_deleteTagging = {
            op: 'deleteTagging',
            podId,
            data: {
              userId: sessionStore.session.user.userId,
              userName: podStore.userPseudonym as string,
              usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
              interactionId
            }
          }
          op = castOpDelTagging
          break

      case 'weblink':
        const castOpDelWebWLink:Op_deleteWeblink = {
            op: 'deleteWeblink',
            podId,
            data: {
              userId: sessionStore.session.user.userId,
              userName: podStore.userPseudonym as string,
              usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
              interactionId
            }
          }
          op = castOpDelWebWLink
          break

      case 'emotion':
        const castOpDelEmo:Op_deleteEmotion = {
            op: 'deleteEmotion',
            podId,
            data: {
              userId: sessionStore.session.user.userId,
              userName: podStore.userPseudonym as string,
              usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
              interactionId
            }
          }
          op = castOpDelEmo
          break

      case 'message':
      const castOpDelMsg:Op_deleteMessage = {
          op: 'deleteMessage',
          podId,
          data: {
            userId: sessionStore.session.user.userId,
            userName: podStore.userPseudonym as string,
            usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
            messageId: messageId as string,
            threadId: threadId as string
          }
        }
        op = castOpDelMsg
        break

      default:
        console.warn(`interaction type ${interactionType} is not supported`)
    }

    if (op) opStore.doOp(op)
    // close drawer
    if(interactionType !== "message") navigate(`${pdfUrl}`, {replace: true})
  }


  return (
      <>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="edit-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{m: 0, p: 0}}
        >
            <MenuList sx={{m: 0, p: 0}}>
              {/* annotations do not need editing */}
              {(editIsAllowed && interactionType !== "annotation") &&
                <MenuItem onClick={(event) => handleEdit(event)} sx={{p: "10px 20px"}}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('Edit')}</ListItemText>
                </MenuItem>
              }
              {deleteIsAllowed &&
                <MenuItem onClick={(event) => handleDelete(event)} sx={{p: "10px 20px", color: "#d24b4e"}}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" sx={{color: "#d24b4e"}} />
                  </ListItemIcon>
                  <ListItemText>{t('Delete')}</ListItemText>
                </MenuItem>
              }
            </MenuList>
        </Menu>
        <ConfirmDialog title={t('Warning')} info={t('Do you really want to delete this interaction?') as string} open={confirmDialogOpen} handleClose={() => { setConfirmDialogOpen(false) }} onOk={deleteInteracation} />
    </>
  )
}

export default EditMenu