import { IconButton } from "@mui/material"
import { Interaction } from "../../../../../types/Interaction"
import { navigate } from "wouter/use-location"
import { observer } from "mobx-react-lite"
import { useStore } from "../../../contexts/store"

// shows interaction as marginalia icon
const MarginaliaEmoji = (
  {
    item,
    pdfId,
    pdfUrl,
    radius
  }: {
    item: Interaction,
    pdfId: string,
    pdfUrl: string,
    radius: number
  }
) => {
  const { podStore } = useStore()
  // register changes to emoji
  const emotion = podStore.getInteraction(pdfId, item.interactionId)
  if(emotion === null) return null

  // call emoji again explicitly to register changes of the label
  const handleClick = (interactionType: string, interactionId: string) => {
    navigate(`${pdfUrl}/${interactionType}/${interactionId}`, {replace: true})
  }

  return (
    <IconButton
      aria-label={item.interactionType}
      onClick={() => handleClick(item.interactionType, item.interactionId)}
      sx={{
        backgroundColor: item.style.color,
        color: "#646464",
        fontSize: radius-6,
        height: `${radius}px`,
        margin: "1px",
        padding: 0,
        overflow: "hidden",
        width: `${radius}px`,
        "&:hover": {color: "rgba(0, 0, 0, 0.54)"}
      }}
    >
      <span role="img" aria-label={emotion.interactionId}>
        {emotion.label}
      </span>
    </IconButton>
  )
}

export default observer(MarginaliaEmoji)