export const versions:{version:number, key:string, description:string}[] = [
  {
    version: 0.723,
    key: 'Dec 23 / M6',
    description: "Conversations + many minor fixes"
  },{
    version: 0.71,
    key: 'Dec 23 / M6',
    description: "Conversations"
  },{
    version: 0.7,
    key: 'Dec 23 / M6',
    description: "Conversations"
  },{
    version: 0.65,
    key: 'Swimming 1.5 / M5',
    description: "Feature completion on activity stream; many bugfixes"
  },{
    version: 0.62,
    key: 'Swimming 1.3 / M4',
    description: "Third round of post-launch improvements: Activity Stream; Background Pod Integrity Checks"
  },{
    version: 0.61,
    key: 'Swimming 1.3 / M4',
    description: "Third round of post-launch improvements: Activity Stream; Background Pod Integrity Checks"
  },{
    version: 0.60,
    key: 'Swimming 1.3 / M4',
    description: "Third round of post-launch improvements: Activity Stream; Background Pod Integrity Checks"
  },{
    version: 0.541,
    key: 'Swimming 1.1',
    description: "A first round of post-launch improvements of the backend and frontend"
  },{
    version: 0.53,
    key: 'Into the Open (3)',
    description: "podadmin basics"
  },{
    version: 0.52,
    key: 'Into the Open (2)',
    description: "userjoin fixes and stability fixes"
  },{
    version: 0.51,
    key: 'Into the Open',
    description: "Microfixes"
  },{
    version: 0.50,
    key: 'Into the Open',
    description: "Pre-Launch fixes around JoinPod"
  },{
    version: 0.49,
    key: 'prelaunch crossmerge',
    description: "alle branches"
  },{
    version: 0.453,
    key: 'quickfix account page',
    description: "joinPod bug / issue #41"
  },{
    version: 0.452,
    key: 'safer storage estimate',
    description: "for iOS"
  },{
    version: 0.451,
    key: 'ios struggles 1',
    description: "gnhhh"
  },{
    version: 0.45,
    key: 'smallFixes_2023-10-08',
    description: "Various small bugfixes"
  },{
    version: 0.44,
    key: 'permission handling in client, marginalia on paper',
    description: "Various minor upgrades"
  },{
    version: 0.43,
    key: 'tracking pdf/backend/prelaunchsprint',
    description: "Various minor upgrades"
  },  {
    version: 0.42,
    key: 'new interaction display',
    description: "Display interactions differently; SATOSA + Keycloak on Safari"
  },  {
    version: 0.413,
    key: 'marginalia-2023-09-28',
    description: "crossmerge for marginalia"
  },  {
    version: 0.412,
    key: 'staging-2023-09-22',
    description: "On small devices, only show second-to-last element form breadcrumb"
  },{
    version: 0.411,
    key: 'master-2023-09-22',
    description: "updates from pdf branch, minor tweaks (e.g. reduce breadcrumb on very small devices)"
  },{
    version: 0.410,
    key: 'master-2023-09-20',
    description: "Touch selection experiments"
  },{
    version: 0.409,
    key: 'master-2023-09-17',
    description: "New Selection Method (stylus compatible)"
  },
  {
    version: 0.408,
    key: 'master-2023-09-12b',
    description: "Test forcing updates"
  },
  {
    version: 0.407,
    key: 'master-2023-09-12',
    description: "Consolidated changes from all frontend branches"
  },
  {
    version: 0.406,
    key: 'prelaunch-DeviceInfo',
    description: "Stores the last user persistently and wipes the database if need be; basic commenting features ; restructured serviceWorker file layout ; sync-watcher ; eyecandy"
  },
  {
    version: 0.405,
    key: 'prelaunch-Satosa',
    description: "Allows login via Satosa @ idm.shrimpp.de"
  },
  {
    version: 0.404,
    key: 'KEV-client-sync2',
    description: "Uses a separate oid-tracker to keep the client synced on pods not yet / no longer joined; supports addUserToPod/removeUserFromPod"
  },
  {
    version: 0.403,
    key: 'KEV-client-sync',
    description: "Uses a separate oid-tracker to keep the client synced on pods not yet / no longer joined"
  },
  {
    version: 0.402,
    key: 'KEV-leavejoin',
    description: 'Kevin: Implements leavePod and joinPod procedures',
  },
  {
    version: 0.401,
    key:'KEV-Debug',
    description: `Kevin: Some additional debugging work: version included as a header in the API-fetch; errror boundaries.`,
  },
  {
    version: 0.400,
    key:'Wouter full',
    description: `Kevin: Fully on Wouter now, including error boundaries.`,
  },
]
