import { navigate } from "wouter/use-location"
import uiStore from "../stores/uiStore"


// get url params which does not trigger a reload of the page when changed
export const useParams = () => {
  const location = window.location.pathname
  let parms:RegExpMatchArray | null = null
  let urlParams:{[key:string]:string} = {}

  parms = location.match(/^\/pod\/([^/]+)\/?(pdf\/([^/]+))?\/?(([a-zA-Z]+)\/([^/]+))?/)
  if (parms && parms[1]) urlParams.podId  = parms[1]
  if (parms && parms[3]) urlParams.nodeId = urlParams.pdfId = parms[3]      // best practice: use nodeId, but we will provide pdfId for legacy reasons
  if (parms && parms[5]) urlParams.interactionType = parms[5]
  if (parms && parms[6]) urlParams.interactionId = parms[6]

  return urlParams
}

export const navigateToInteraction = (pdfId: string, podId: string, interactionId: string, interactionType: string, destinationNodeId: string) => {
  if(pdfId && podId && interactionId && interactionType && destinationNodeId) {
    // navigate to destinated interaction
    navigate(`/pod/${podId}/pdf/${destinationNodeId}/${interactionType}/${interactionId}`)
    // scrolling is done when the pdf is initialized, if the interaction is on the same page: trigger viewer rerender
    if(destinationNodeId === pdfId) {
      uiStore.forceViewerUpdate()
    }
  }
}

export const sanitize = (val:string | number | undefined, type: string) => {

	const podsUuidCharsRegEx = /[^a-zA-Z0-9_\+\.\-]/;

	switch(type) {

		case 'string':
			return String(val)
				.replace(/;/, '\;')
				.replace(/'/, '\'')
				.replace(/--/, '\--')

		/**
         * @todo: integrate base64url standard (below) in uuid-pipeline
		 * schema: '<base64url>.<base64url>.<base64url>'
         */
		case 'uuid':
			return String(val).replace(podsUuidCharsRegEx, '')

		case 'fileUuid':
			const tmp = String(val).replace(/^\/pdf\//, '').split("?")[0]
			return tmp.replace(podsUuidCharsRegEx, '')

    case 'tag':
		case 'username':
			// Nothing from UTF character classes Control (C), Separator (Z) and Punctuation (P), except hyphen and period
			return String(val).replace(/[\p{C}\p{Z}]*[^-\._\P{P}]*/gu, '')

		case 'color':
			return String(val).replace(/[^a-zA-Z0-9#]/, '')


		case 'email':
			return String(val).replace(/[^a-zA-Z0-9\-\._@]/, '') // is this too narrow?


		case 'integer':
			return parseInt(String(val))


		case 'none':
			return null


		default:
			throw new Error('WARNING: unknown sanitation ' + type)
	}

}