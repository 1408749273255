import { t } from "i18next"
import { Box } from "@mui/material"
import { ConversationItem } from "../../../../../types/Miscs"
import { useStore } from "../../../contexts/store"
import ConversationListItem from "./ConversationListItem"

const ConversationList = ({conversationList, podId}: {conversationList: ConversationItem[], podId:string}) => {
  const { uiStore } = useStore()

  const changeActiveConversationId = (interactionId: string | null) => {
    uiStore.setActiveConversation(podId, interactionId)
  }

  return (
    <Box className="overflowWrapper" sx={{alignContent: "start"}}>
      {/* List Header */}
      {uiStore.deviceWidth !== "tiny" ?
        <Box sx={{
            alignItems: "center",
            backgroundColor: "#edededdb",
            borderBottom: "1px solid rgba(63, 67, 80, 0.16)",
            borderLeft: "1px solid rgba(63, 67, 80, 0.16)",
            borderRight: "1px solid rgba(63, 67, 80, 0.16)",
            color: "#3b3b3b",
            display: "grid",
            fontSize: "18px",
            height: "56px",
            padding: "0 20px"
          }}>
            <Box sx={{fontWeight: "450"}}>
              {t('All your Conversations')}
            </Box>
          </Box>
        :
          <Box />
      }
      {/* List Content */}
      <Box sx={{display: "grid", borderRight: "1px solid rgba(63, 67, 80, 0.16)", overflowY: "auto"}}>
        {conversationList.map((conversation) =>
          <Box key={conversation.interactionId} onClick={() => changeActiveConversationId(conversation.interactionId)}>
            <ConversationListItem item={conversation} podId={podId} />
          </Box>
        )}
      </Box>
    </Box>
  )
}


export default ConversationList