import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { useStore } from "../../contexts/store"
import { PdfFile } from "../../../../types/Content"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import { Op_editPdfFile } from "../../../../types/Ops"
import React from "react"
import { PodClass } from "../../classes/Pod"

// message from thread
const EditPdfFileDialog = ({pdfFile, open, handleClose}: {pdfFile: PdfFile, open: boolean, handleClose: any}) => {
  const [ newFolderId, setNewFolderId ] = React.useState<string>('')
  const [ newName, setNewName ] = React.useState<string>('')
  const [ newWeight, setNewWeight ] = React.useState<number>(0)
  const { podStore, sessionStore, opStore } = useStore()
  const { t } = useTranslation()
  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  const doChanges = () => {
    const op:Op_editPdfFile = {
      op: 'editPdfFile',
      podId: pod.podId,
      data: {
        nodeId: pdfFile.nodeId,
        usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
        userId: sessionStore.session.user.userId,
        userName: podStore.userPseudonym || '',
        mods: {}
      }
    }

    if (newName && (newName !== pdfFile.name)) op.data.mods.name = newName
    if (newFolderId && (newFolderId !== pdfFile.folderId)) op.data.mods.folderId = (newFolderId === '-' ? '' : newFolderId)
    if (newWeight && (newWeight !== pdfFile.weight)) op.data.mods.weight = newWeight
    opStore.doOp(op)
  }

  const nameValue = newName ? newName : pdfFile.name
  const folderIdValue = ((newFolderId ? newFolderId : pdfFile.folderId)) || '-'
  
  const folderIds:string[] = Object.keys(pod.content.folders)
  folderIds.unshift('-')
  
  return <Dialog open={open}>
    <DialogTitle>Edit File</DialogTitle>
    <DialogContent>
      <TextField fullWidth={true} style={{margin:'8px 0'}} label={t('Name')} value={nameValue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { if (event.target.value.trim()) setNewName(event.target.value) }} /><br />
      <Select fullWidth={true} style={{margin:'8px 0'}} value={folderIdValue} onChange={(event: SelectChangeEvent) => { setNewFolderId(event.target.value) }}>
        {folderIds.map((folderId:string) => {
          return <MenuItem key={folderId} value={folderId}>
            { folderId === '-' ? <em>{t('No Folder')}</em> : pod.content.folders[folderId].name }
          </MenuItem>
        })}
      </Select>
      <TextField fullWidth={true} style={{margin:'8px 0'}} label={t('Weight')} value={newWeight ? newWeight : pdfFile.weight} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { if (parseInt(event.target.value)) setNewWeight(parseInt(event.target.value)) }} /><br />
    </DialogContent>
    <DialogActions style={{textAlign:'right'}}>
      <Button onClick={handleClose}>{t('Cancel')}</Button>
      <Button variant='contained' onClick={() => { doChanges(); handleClose() }}>{t('OK')}</Button>
    </DialogActions>
  </Dialog>

}

export default observer(EditPdfFileDialog)