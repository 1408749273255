import { OpCode } from "../../../types/OpCodes";

export const activityOps:OpCode[] = [
  'addPdfFile',
  'addComment',
  'addEmotion',
  'addLink',
  'addTagging',
  'addWeblink',
  'addMessage',
  'editTag',
  'addUserToPod',
  'removeUserFromPod',
]