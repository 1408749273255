import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { HexColorPicker } from "react-colorful";
import { UserInfo } from "../../../../types/User"
import { t } from "i18next";
import { sanitize } from '../../helper/Helper'


const EditUserInfo = ({userInfo, handleClose, title}:{ userInfo:UserInfo|null, handleClose:any, title?:string }) => {
  const [userName, setUserName ] = React.useState<string|null>(userInfo ? userInfo.userName : null)  
  const [color, setColor] = React.useState<string|null>(userInfo && userInfo.color ? userInfo.color : null)
  
  useEffect(() => {
    if (!userName && userInfo) setUserName(userInfo.userName)
    if (!color && userInfo && userInfo.color) setColor(userInfo.color)
  }, [userName, userInfo, color])

  let open = false
  if (userInfo) {
    open = Boolean(userInfo) && Boolean(userInfo.userId) && Boolean(userInfo.userName) && Boolean(userInfo.color)
  }


  return  <Dialog open={ open }>
      <DialogTitle>{title ? title : t('Set Userinfo')}</DialogTitle>
      <DialogContent>
        <TextField 
          label='Username in this Pod'
          value={userName || ''} 
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) => {
              setUserName(sanitize(event.target.value, 'username') as string)
            }} 
          style={{ margin:'8px 0'}}            
          />
        <HexColorPicker color={color ? color : 'aaa'} onChange={setColor} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { handleClose()}}>{t('Cancel')}</Button>
        <Button variant='contained' onClick={() => { handleClose({userId: userInfo?.userId || 0, color, userName}) }} autoFocus>{t('Ok')}</Button>
      </DialogActions>
  </Dialog>

}

export default observer(EditUserInfo)