import { useStore } from "../../contexts/store"
import { UserInfo } from "../../../../types/User"
import { AccountCircle } from '@mui/icons-material'
import { Avatar, Badge, Box, Tooltip } from "@mui/material"

const UserInfoAvatar = ({userId, podId, sx, variant}: {userId: number | null | undefined, podId: string | undefined | null, variant?:'avatar'|'icon'|undefined, sx?:object|undefined}) => {
  const { podStore } = useStore()
  // get user object
  const userInfo: UserInfo | null = (userId) ? podStore.getUserInfo(userId, podId) : null

  // if there is no user information, return anonymous avatar
  if(userInfo === null) return <Avatar sx={{...sx, borderRadius: "5px"}} />

  const avatarStyle = {
    ...sx,
    borderRadius: "5px",
    backgroundColor: userInfo.color || '#f4f4f4',
  }
  // create initials for user names
  const userInitials = userInfo.userName.includes(' ') ? `${userInfo.userName.split(' ')[0][0]}${userInfo.userName.split(' ')[1][0]}` : userInfo.userName[0]


  if (variant === 'icon') {
    return (
      <Box style={{display:'inline-block', width:'1em', height: '1em'}} title={userInfo.userName}>
        <Badge badgeContent={userInitials}>
          <AccountCircle sx={avatarStyle} />
        </Badge>
      </Box>
    )
  }

  return (
    <Avatar sx={avatarStyle}>
      {userInitials}
    </Avatar>
  )

}

export default UserInfoAvatar