import i18next from 'i18next'
import uiStore from '../stores/uiStore'
import alertStore, { alert } from '../stores/alertStore'
import sessionStore from '../stores/sessionStore'
import { PodClass } from '../classes/Pod'
import { Op, idbStoredOp } from '../../../types/Ops'
import { syncPullRequest } from '../../../types/Pod'
import { versions } from '../version'
import { environment } from '../environment'
//import throttle from '@jcoreio/async-throttle'

const version = versions[0]

export const env = {
  backendBaseUrl: (typeof environment.backendBaseUrl !== 'undefined') ? environment.backendBaseUrl             : 'http://localhost:5000',
  dev:            (typeof environment.dev !== 'undefined') ? environment.dev                                   : true,
  httpDefaultTimeout: (typeof environment.httpDefaultTimeout !== 'undefined') ? environment.httpDefaultTimeout : 8000,
  idps:           (typeof environment.idps !== 'undefined') ? environment.idps                                 : [{
                                                                                                                   name: 'keycloak',
                                                                                                                   loginEndpoint: 'login',
                                                                                                                   description: '',
                                                                                                                 }]
}

export const baseUrl = environment.backendBaseUrl

const api = {
  /** redirect to server login route,
   *  wait for the callback from the server, which will set a cookie with the sessionId
   */
  async login(provider:string) {
    const providerInfo = environment.idps.find((idp:any)=>idp.name===provider)
    if (providerInfo) {
      const location = window.location.href
      localStorage.setItem('LoginDestination', location)
      window.location.href = `${baseUrl}/${providerInfo.loginEndpoint}?target=${encodeURI(location)}`
    }
    else {
      console.log(`Unknown provider ${provider}`)
    }
    await new Promise((resolve) => {
      setTimeout(resolve, 5000)
    })
  },

  async getPodActivity(podId: string, before:number=0, doCache:boolean=false) {
    const sessionId = sessionStore.session ? sessionStore.session.sessionId : null
    const res = await this.fetch(`${baseUrl}/activity`, {
      method:'POST',
      headers: {
        'X-SHRIMP-ID': sessionId,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
       podId,
       before: before ? before : null,
       doCache
      })
    })
    return res
  },

  async reportError(error: Error|string, info: { componentStack: string } | string) {
    const sessionId = sessionStore.session ? sessionStore.session.sessionId : null
    const userId = sessionStore.session ? sessionStore.session.user.userId : null
    return this.fetch(`${baseUrl}/reportError`, {
      method:'POST',
      headers: {
        'X-SHRIMP-ID': sessionId,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        version: version.version,
        report: {
          error,
          info,
        }
      })
    })
  },

  async resetCache(cacheSelection:object) {
    this.fetch(`${baseUrl}/resetCache`, {
      method:'post',
      body: JSON.stringify(cacheSelection)
    })    
  },

  async syncFull(pulling: Array<syncPullRequest>, pushing: Op[] | idbStoredOp[], sessionId: string, clientLastSyncOid: number) {
    //if (sessionId === '') sessionId = sessionStore.session ? sessionStore.session.sessionId : ""
    const res = await this.fetch(`${baseUrl}/syncFull`, {
      method: 'POST',
      timeout: env.httpDefaultTimeout,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-SHRIMP-ID': sessionId,
      },  
      body: JSON.stringify({
        pulling: pulling,
        pushing: pushing,
        clientLastSyncOid: clientLastSyncOid // sessionStore.session.clientLastSyncOid
      })
    })
    if (res) return res
    return undefined
  },

  async getPendingOps(podId: string|null = null) {
    const res = await api.fetch(`${baseUrl}/getPendingOps`, {
      method: 'POST',
      timeout: env.httpDefaultTimeout,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-SHRIMP-ID': sessionStore.session ? sessionStore.session.sessionId : "",
      },  
      body: JSON.stringify({podId})    
    })
    if (res) return res.body as idbStoredOp[]
    return undefined
  },


  async getPodFingerprint(podId: string, hashed:boolean = true, fromBackend:boolean = false) {
    const res = await this.fetch(`${baseUrl}/getPodFingerprint`, {
      method: 'POST',
      timeout: env.httpDefaultTimeout,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-SHRIMP-ID': sessionStore.session ? sessionStore.session.sessionId : "",
      },  
      body: JSON.stringify({podId, hashed, fromBackend})    
    })
    if (res) return res.body
    return undefined
  },

  async reportDivergentFingerprint(podId: string, sessionId:string, fingerprint:string) {
    const res = await this.fetch(`${baseUrl}/getPodFingerprint`, {
      method: 'POST',
      timeout: env.httpDefaultTimeout,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-SHRIMP-ID': sessionId,
      },  
      body: JSON.stringify({podId, hashed:false, fromBackend:false, clientFingerprint: fingerprint})    
    })
    if (res.status === 200) console.log(`Reported divergent fingerprint for debugging`); else console.warn(`reportDivergentFingerprint failed: `, res)
  },

  /** attempt to load a pod and receive either a fully populated pod or an empty
   *  init pod, which is then populated through ops during the chunked loading
   *  cycles
   */

  async loadPod(podId: string, reset: boolean = false) {
    const obj = await this.fetch(`${baseUrl}/loadPod`, { 
      method: 'POST',
      timeout: env.httpDefaultTimeout,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-SHRIMP-ID': sessionStore.session ? sessionStore.session.sessionId : "",
      },  
      body: JSON.stringify({ podId, reset })
    })

    if ((obj) && (obj.body?.pod)) {
      const pod = new PodClass(obj.body.pod, true)
      return pod  
    }
    return undefined
  },

  /** load a server-determined number of operations that will populate an originally empty 
   *  init pod to the extent defined by initMaxCoid.
   */
  async loadPodChunk(podId: string, lastSyncOid: number, initMaxCoid: number) {
    const res = await this.fetch(`${baseUrl}/loadPodChunk`, { 
      method: 'POST',
      timeout: env.httpDefaultTimeout,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-SHRIMP-ID': sessionStore.session ? sessionStore.session.sessionId : "",
      },  
      body: JSON.stringify({ podId, lastSyncOid, initMaxCoid })
    })

    if (res) {
      const { ops, totalOps } = res.body
      return {
        ops: ops as Op[],
        totalOps: totalOps as number,
      }  
    }
    
    return undefined
  },

  /** request the service worker whether an active session exists and can be returned
   *  the cookie sent with the request is used for authentication,
   *  this cookie is deleted from the server after the request is made
   *  if no session available, login screen is displayed
  */
  async isSession(sessionId:string|null = null) {
    // TODO: use typescript
    return new Promise<any | null>(async (resolve) => {
      const headers = sessionId ? { 'X-SHRIMP-Id': sessionId } : null
      try {
        const res = await this.fetch(`${baseUrl}/isSession`, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'include',
          headers
        })
        if(res.status === 200) {
          handleNetworkSuccess()
          const session = res.body
          if(session && session.sessionId) {
            resolve(session)
          }
        }
      } catch(err) {
        handleNetworkError(err)
      }
      resolve(null)
    })
  },
  /** test route: authenticate data request with sessionId stored in X-SHRIMP-Id header */
  async test() {
    // TODO: use typescript
    return new Promise<any | null>(async (resolve) => {
      try {
        const res = await this.fetch(`${baseUrl}/test`, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
              'X-SHRIMP-Id': sessionStore.session ? sessionStore.session.sessionId : "",
            }
        })

        if(res.status === 200) {
            handleNetworkSuccess()
            const test = await res.body
            resolve(test)
        }
      } catch(err) {
        handleNetworkError(err)
      }
      resolve(null)
    })
  },
  getCachedPdf() {
    return new Promise<string[] | null>(async (resolve) => {
      try {
        const res = await this.fetch(`${baseUrl}/getCachedPdf`, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'X-SHRIMP-Id': sessionStore.session ? sessionStore.session.sessionId : "",
          }
        })

        if(res.status === 200) {
          handleNetworkSuccess()
          const cachedPdf = await res.body
          resolve(cachedPdf)
        }
      } catch(err) {
        handleNetworkError(err)
      }
      resolve(null)
    })
  },
  /** redirect to the logout route,
   * use hidden input field to transfer the session id to server
   */
  logout() {
    // TODO: use typescript
    const form = document.createElement('form');
    form.method = 'POST'
    form.action = `${baseUrl}/logout`
    // create input field with sessionId
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = "sessionId"
    hiddenField.value = sessionStore.session ? sessionStore.session.sessionId : ""
    form.appendChild(hiddenField)
    // add input field to html
    document.body.appendChild(form)
    form.submit()
  },

  async fetch(target:string|Request, options:any = {}) {
    const { timeout = env.httpDefaultTimeout, pipeThrough = false, ...fetchOptions } = options;
    
    if (!fetchOptions.headers) fetchOptions.headers = {}
    fetchOptions.headers['X-SHRIMP-VERSION'] = `${version.version} ${version.key}`
    fetchOptions.headers['X-SHRIMP-CC'] = (typeof window === 'object') ? 'Tab' : 'SW'
    let response: Response
    try {
      const controller = new AbortController();
      const id = setTimeout(() => controller.abort(`timeout of ${timeout}ms`), timeout);

      response = await fetch(target, {
        signal: controller.signal,
        ...fetchOptions,
      })

      clearTimeout(id)

      if (pipeThrough) return response as Response

      try {
        const body = await response.json()
        return {
          ok: response.ok,
          status: response.status,
          body: body
        }  
      }
      catch(e) {
        return {
          ok: false,
          status: response.status,
        }        
      }
    }
    catch(e) {
      console.warn(e, target)
      const res = {
        ok: false,
        status: 502,
        statusText: 'Could not perform api.fetch(). Reason: ' + e,
        body: undefined
      }
      if (pipeThrough) return new Response(JSON.stringify(res), {
        status: 502,
        statusText: 'Could not perform api.fetch(). Reason: ' + e
      })
      return res
    }
  },

}

const handleNetworkError = (err: unknown) => {
  if(navigator.serviceWorker && navigator.serviceWorker.controller) {
    uiStore.setIsOffline(true)
  } else {
    alertStore.push(alert(i18next.t('the changes could not be saved and will be lost at the next startup'), 'error', i18next.t('No Internet connection') ?? '', 'WifiOffIcon'))
  }
  console.warn("Network Error:",err)
}

const handleNetworkSuccess = () => {
  if(navigator.serviceWorker && navigator.serviceWorker.controller) {
    uiStore.setIsOffline(false)
  }
}

export default api