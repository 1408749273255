import React, { CSSProperties, memo } from "react"
import { useStore } from "../../../contexts/store"
import { observer } from "mobx-react-lite"
import { FixedSizeList, VariableSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Box } from "@mui/material"
import Overlay from "../Overlay"
import { useParams } from "../../../helper/Helper"
import ActiveThumbnail from "./ActiveThumbnail"
import ThumbnailScroll from "./ThumbnailScroll"

const Thumbnails = ({
    pageListRef,
    storeId,
    thumbListRef,
    thumbnailUrl
  }:{
    pageListRef: any,
    storeId: string,
    thumbListRef: any,
    thumbnailUrl: string
  }) => {
  const { pdfMetaStore, uiStore } = useStore()
  const { pdfId } = useParams()
  const numPages = pdfMetaStore.getNumPage(storeId)
  const pageHeights = pdfMetaStore.getStaticPageHeights(storeId)
  const pageWidths = pdfMetaStore.getStaticPageWidths(storeId)
  const hasVariableSize = pdfMetaStore.getHasVariableSize(storeId)
  const marginHorizontal = 30
  const marginVertical = 40

  // prevent access to non-existent pdf metafiles
  if(numPages === null || pageHeights === null || pageWidths === null || pageHeights.length === 0 || pageWidths.length === 0) return null

  const handleClick = (pageNumber: number) => {
    if(pageListRef.current) {
      pageListRef.current.scrollToItem(pageNumber-1, "start")
    }
  }

  // renders thumbnail as long as it is in the visible area
  const Thumbnail = memo( ({ index, style }: {index: number, style: CSSProperties}) => {
    const pageNumber = index+1
    const pageId = `${storeId}-thumbnail-${index}`
    const thumbHeight = getThumbnailHeight(index)
    const scale = (thumbHeight)/pageHeights[index]

    return (
      <div id={pageId} style={{...style,
          margin: "5px 25px 0 15px",
          width: uiStore.getThumbContainerWidth() - marginVertical
        }}>
        <Box onClick={() => handleClick(pageNumber)} id={thumbnailUrl+"/"+pageNumber} sx={{
            border: "1px solid #a1a1a1",
            cursor: "pointer",
            overflowY: "hidden",
            '&:hover': {
              background: "lightgrey",
            }
          }}>
            <img
              alt={`preview thumbnail for pdf page ${pageNumber}`}
              src={thumbnailUrl+"/"+pageNumber}
              width={"100%"}
            />
            <Overlay
              childrenDepth={0}
              linkEditId={null}
              pageId={pageId}
              pdfId={pdfId}
              pageNumber={pageNumber}
              scale={scale}
            />
          </Box>
          <ActiveThumbnail
            pageNumber={pageNumber}
            storeId={storeId}
          />
      </div>
    )
  })


  // calculate relative height for thumbnail item,
  const getThumbnailHeight = (pageIndex: number) => {
    const thumbContainerWidth = uiStore.getThumbContainerWidth()
    if(Array.isArray(pageHeights) && pageHeights.length && thumbContainerWidth) return (
      ((thumbContainerWidth - marginVertical) / pageWidths[pageIndex]) * pageHeights[pageIndex]
    )
    else {
      console.error("empty height array")
      return 0
    }
  }

  // get thumbnail item height, consider horizontal margin
  const getItemSize = (pageIndex: number) => {
    const thumbnailHeight = getThumbnailHeight(pageIndex)
    return thumbnailHeight + marginHorizontal
  }

  // the height and width of all pdf pages is the same
  const FixedPageList = ({height, width}: {height: number, width: number}) => (
    <FixedSizeList
      height={height}
      itemCount={numPages}
      itemSize={getItemSize(0)}
      overscanCount={1}
      ref={thumbListRef}
      width={width}
    >
      {Thumbnail}
    </FixedSizeList>
  )

  // the height and width of all pdfs may differ
  const VariablePageList = ({height, width}: {height: number, width: number}) => (
    <>
      <VariableSizeList
        estimatedItemSize={getThumbnailHeight(0)}
        height={height}
        itemCount={numPages}
        itemSize={getItemSize}
        overscanCount={1}
        ref={thumbListRef}
        width={width}
      >
        {Thumbnail}
      </VariableSizeList>
    </>
  )

  const ThumbsContainer = ({height, width}: {height: number, width: number}) => {
    if(hasVariableSize) return <VariablePageList height={height} width={width} />
    else return <FixedPageList height={height} width={width} />
  }

  return (
    <Box sx={{
      overflowY: "auto",
      backgroundColor: "white",
    }}>
      <AutoSizer>
      {({ height, width }) => (
        <ThumbsContainer height={height} width={width} />
      )}
      </AutoSizer>
      <ThumbnailScroll
        storeId={storeId}
        thumbnailHeight={getThumbnailHeight(0)}
        thumbListRef={thumbListRef}
        thumbnailUrl={thumbnailUrl}
      />
    </Box>
  )
}

export default observer(Thumbnails)
