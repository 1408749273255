import { Box } from "@mui/material"
import { iEmotion } from "../../../../types/Interaction"
import { observer } from "mobx-react-lite"

const Emotion = ({emotion}: {emotion: iEmotion}) => {

  return (
    <Box sx={{padding: "20px", display: "grid", fontSize: "50px"}}>
      {emotion.label}
    </Box>
  )
}

export default observer(Emotion)