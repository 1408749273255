import React from 'react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../contexts/store'
import { Box } from '@mui/material';
import { PodClass } from '../../classes/Pod';
import { iComment } from '../../../../types/Interaction';
import { Message } from '../../../../types/Message';
import { Link } from 'wouter';
import MessageComponent from '../Elements/Message';

const Mrc = () => {
  const { podStore } = useStore()
  const pod:PodClass|null = podStore.pod
  if (!pod) return <div>no pod</div>
  /*const comments = Object.keys(pod.content.pdfFiles).reduce((acc:iComment[], nodeId: string) => { 
    const node = pod.content.pdfFiles[nodeId]
    const nodeComments = Object.keys(node.comments).map((interactionId:string) => node.comments[interactionId])
    return [...acc, ...nodeComments] 
  }, [])*/
  
  type Entry = {
    tCreated: number,
    type: 'comment',
    obj: iComment
    nodeId: string,
    interactionId: string,
  } | {
    tCreated: number,
    type: 'message',
    obj: Message,
    nodeId: string,
    interactionId: string,
  }

  const entries:Entry[] = []
  Object.keys(pod.content.pdfFiles).forEach((nodeId:string) => {
    const node = pod.content.pdfFiles[nodeId]
    Object.keys(node.comments).forEach((interactionId:string) => {
      const entry:Entry = {
        tCreated: node.comments[interactionId].tCreated,
        type: 'comment',
        obj: node.comments[interactionId],
        nodeId,
        interactionId
      }
      entries.push(entry)
    })
  })

  Object.keys(pod.content.threads).forEach((threadId:string) => {
    const thread = pod.content.threads[threadId]
    thread.messages.forEach((message:Message) => {
      const { nodeId } = pod.findInteraction(thread.interactionId, 'pdfFiles', 'comments')
      const entry:Entry = {
        tCreated: message.tCreated || 0,
        type: 'message',
        obj: message,
        nodeId,
        interactionId: thread.interactionId
      }
      entries.push(entry)
    })
  })

  entries.sort((a, b) => { if (a.tCreated > b.tCreated) return -1; if (a.tCreated < b.tCreated) return 1; return 0 })

  return <Box sx={{ alignContent: "start", p: 5 }}>
    <h1>Most Recent Comments</h1>
    {
      entries.map((entry:Entry) => {
          var message:Message = entry.type === 'message' ? entry.obj : {
            messageId: entry.obj.interactionId,
            threadId: '',
            text: entry.obj.label,
            refMessageId:null,
            coid: entry.obj.coid,
            userId: entry.obj.userId,
            userName: entry.obj.userName,
            tCreated: entry.obj.tCreated,
            tModified: entry.obj.tModified,
          }
        return <Link key={message.messageId} to={`/pod/${pod.podId}/pdf/${entry.nodeId}/comment/${entry.interactionId}`} title={`/pod/${pod.podId}/pdf/${entry.nodeId}/comment/${entry.interactionId}`}><Box>
          <MessageComponent content={message} /></Box>
        </Link>

/**
 
        if (entry.type === 'comment') return <Link key={entry.obj.interactionId} to={`/pod/${pod.podId}/pdf/${entry.obj.anchor.nodeId}/comment/${entry.obj.interactionId}`}>
          <Box style={{border:'1px solid #000', borderRadius:4, padding:4, margin: '8px 0', backgroundColor:'#ffe'}}>
            <small>{dayjs(entry.obj.tCreated * 1000).format()} * {entry.obj.userName}</small>
            <Box>{entry.obj.label}</Box>
          </Box>
        </Link>

        if (entry.type === 'message') return <Link key={entry.obj.messageId} to={`/pod/${pod.podId}/pdf/${entry.nodeId}/comment/${entry.interactionId}`}>
        <Box style={{border:'1px solid #000', borderRadius:4, padding:4, margin: '8px 0', backgroundColor:'#ffe'}}>
          <small>{dayjs(entry.obj.tCreated || 0 * 1000).format()} * {entry.obj.userName}</small>
          <Box>{entry.obj.text}</Box>
        </Box>
        </Link>

 */
      })
    }
  </Box>

}

export default observer(Mrc)