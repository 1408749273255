import { useTranslation } from "react-i18next"
import { observer } from "mobx-react-lite"
import { Box } from "@mui/material"
import { useStore } from "../../contexts/store"
import Message from "../Elements/Message"
import MessageArea from "./MessageArea"
import { Message as Messagetype, Thread as ThreadType } from "../../../../types/Message"
import { Op_addMessage, Op_addThread } from "../../../../types/Ops"
import { PodClass } from "../../classes/Pod"

const Thread = ({interactionId, nodeId}:{interactionId: string | null, nodeId: string | null}) => {
  const { t } = useTranslation()
  const { uiStore } = useStore()
  const { podStore, opStore, sessionStore } = useStore()
  const { session } = sessionStore
  const pod: PodClass | null = podStore.pod

  if(interactionId === null || nodeId === null || pod === null) return null
  const { podId } = pod

  // get actual selected comment
  const comment = podStore.getInteraction(nodeId, interactionId)
  // get thread from actual selected comment
  const thread = podStore.getThread(interactionId)
  // TODO: check if its allowed to add messages in this thread
  // const addMessageIsAllowed = podStore.pod.isAllowed('addMessage', thread?.threadId)

  // list of thread messages
  const threadMessages = []

  // add comment label as first message in thread
  const label: Messagetype = {
    messageId: interactionId,
    threadId: thread ? thread.threadId : "",
    refMessageId: "",
    coid: comment ? comment.coid : null,
    userId: comment ? comment.userId : 0,
    userName: comment ? comment.userName : "",
    text: comment ? comment.label : "",
    reactionCount: [],
    tCreated: comment ? comment.tCreated : 0,
    tModified: comment ? comment.tModified : 0
  }
  if(label) {
    threadMessages.push(
      <li key={interactionId}>
        <Message content={label}/>
      </li>
    )
  }

  // add thread comments
  if(thread) {
    thread.messages.map(message =>
      threadMessages.push(
        <li key={message.messageId}>
          <Message content={message}/>
        </li>
      )
    )
  }

  function submitInput(input: string) {
    let threadId = thread?.threadId
    // create new thread, if this is the first reply
    if(thread === null) {
      threadId = `T.${interactionId}` // for pod-public comments, base the threadId on the interactionId
      opStore.doOp({
        op: "addThread",
        podId: podId,
        data: {
          coid: null,
          usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
          interactionId: interactionId,
          messages: [],
          threadName: "",
          tCreated: Math.floor(Date.now()/1000),
          tModified: Math.floor(Date.now()/1000),
          threadId: threadId,
          userId: session.user.userId,
          userName: podStore.userPseudonym,
        } as ThreadType
      } as Op_addThread)
    }
    // create message inside comment
    opStore.doOp({
      op: "addMessage",
      podId: podId,
      data: {
        usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
        messageId: sessionStore.createUuid(),
        threadId: threadId,
        refMessageId: null,
        coid: null,
        userId: session.user.userId,
        userName: podStore.userPseudonym,
        text: input,
        reactionCount: [],
        tCreated: Math.floor(Date.now()/1000),
        tModified: Math.floor(Date.now()/1000)
      } as Messagetype
    } as Op_addMessage)
  }

  return (
    <Box>
      <ul style={{listStyleType: "none", padding: "5px", margin: 0}}>
        {threadMessages}
      </ul>
      <Box sx={{position: "sticky", bottom: 0, backgroundColor: "white"}}>
        <MessageArea placeholder={(uiStore.deviceWidth === "tiny") ? t('reply')+" ..." : t("Reply to this comment ...")} submitInput={submitInput} value="" key={interactionId} disabled={!(pod.isAllowed('addMessage') && (pod.isAllowed('addThread') || threadMessages.length>1))} />
      </Box>
    </Box>
  )
}

export default observer(Thread)
