import i18next from 'i18next'
import { makeObservable, observable, action } from 'mobx'
import alertStore, {alert} from './alertStore'
import api from '../api/api'
import { Pod } from '../../../types/Pod'

type ResetCacheSelection = {
    cache?: boolean, 
    idbStatic?: boolean, 
    idbOpLog?: boolean,
    idbTotal?: boolean,
    serviceWorker?: boolean,
}

export interface sessionStoreModel {
    sessionIsLoading: boolean
    appIsReady: boolean
    session: {
        cookie: any,
        idpUser: any,
        pods: Pod[],
        sessionId: string
        user: {
            userId: number
            name: string,
            idpProvidedUserName: string,
            loginWasAt: string,
            provider: string,
        },
        clientCounter: number,
        sessionCounter:number,
        clientLastSyncOid: number,
    }
    setAppIsReady: (appIsReady: boolean) => void
    login: (provider:string) => void
    logout: () => void
    createUuid: () => string
    resetCache: (ResetCacheSelection: ResetCacheSelection) => void
}

class sessionStore {
    // TODO: use typescript
    session: any = null
    sessionIsLoading: boolean = false
    appIsReady: boolean = false
    opCounter: number = 1
    broadcastChannel: BroadcastChannel | null = null
    convertBase64: any

    constructor() {
        makeObservable(this, {
            //state: represents state of the store
            session: observable,
            sessionIsLoading: observable,
            appIsReady: observable,
            opCounter: observable,

            // actions: asynchronous operations
            isSession: action,
            prepareApp: action,
            login: action,
            logout: action,
            createUuid: action,
            clearSession: action,
            setSession: action,
            setAppIsReady: action,
            setSessionIsLoading: action,
            setPodLastSyncOid: action,
            setClientLastSyncOid: action,
            setPods: action,
        })

        // A helper function to more efficiently encode the uuid's three elements on a base of 64
        // Cf. https://stackoverflow.com/questions/6213227/fastest-way-to-convert-a-number-to-radix-64-in-javascript/27696695#27696695
        const base64 = () => {
            const digitsStr:string =
            //   0       8       16      24      32      40      48      56     63
            //   v       v       v       v       v       v       v       v      v
                "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz+-";
            var digits = digitsStr.split('');
            var digitsMap: Record<string, number> = {};
            for (var i = 0; i < digits.length; i++) {
                digitsMap[digits[i]] = i;
            }
            
            return {
                fromInt: function(int32: number) {
                    var result = '';
                    while (true) {
                        result = digits[int32 & 0x3f] + result;
                        int32 >>>= 6;
                        if (int32 === 0)
                            break;
                    }
                    return result;
                },
                toInt: function(digitsStr: string) {
                    var result = 0;
                    var digits = digitsStr.split('');
                    for (var i = 0; i < digits.length; i++) {
                        result = (result << 6) + digitsMap[digits[i]];
                    }
                    return result;
                }
            }
        }
        
        this.convertBase64 = base64()
    }

    async resetCache(ResetCacheSelection:ResetCacheSelection) {
        this.clearSession()

        await api.resetCache(ResetCacheSelection)

        if (ResetCacheSelection.serviceWorker) {
            const registeredServiceWorkers = await navigator.serviceWorker.getRegistrations()
                for (const registration of registeredServiceWorkers) {
                await registration.unregister()
            }   
        }
    }

    async isSession() {
        return new Promise<boolean>(async (resolve) => {
            if(this.session === null) {
                this.setSessionIsLoading(true)
                let session = await api.isSession()
                if(session) this.setSession(session)
                this.setSessionIsLoading(false)

                if (localStorage.getItem('LoginDestination')) {
                    const location = localStorage.getItem('LoginDestination')
                    if (location && (location.trim() !== window.location.href.trim())) window.location.href = location
                    localStorage.removeItem('LoginDestination')
                }
        
            }
            resolve(true)
        })
    }

    async prepareApp() {
        await this.isSession()
        this.setAppIsReady(true)
    }

    async login(provider:string) {
        await api.login(provider)
    }

    logout() {
        api.logout()
    }

    /** create new uuid in the form: sessionCounter.clientCounter.opCounter */
    // TODO: use Typescript to guarantee that the session object contains the parameter sessionCounter and clientCounter
    createUuid() {
        const session = this.session

        if(session.sessionCounter && session.clientCounter && this.opCounter) {
            const uuid = this.convertBase64.fromInt(session.sessionCounter) + '.' + this.convertBase64.fromInt(session.clientCounter) + '.' + this.convertBase64.fromInt(this.opCounter)
            this.opCounter++
            return uuid
        }
        else{
            alertStore.push(alert(i18next.t('Could not create operation, broken session. Please refresh the page'), 'error'))
            throw new Error("Missing sessionCounter or clientCounter in session", session)
        }
    }

    clearSession() {
        this.setSession(null)
    }

    setSession(session: any) {
        this.session = session
        // set pod meta data
        if(session && session.podsInfo) {
          // TODO: set pod meta data setPodsInfo(session.podsInfo)
        }
    }

    setAppIsReady(appIsReady: boolean) {
        this.appIsReady = appIsReady
    }

    setSessionIsLoading(sessionIsLoading: boolean) {
        this.sessionIsLoading = sessionIsLoading
    }

    setPodLastSyncOid(podId: number, value: number) {
        this.session.pods[podId].lastSyncOid = value
    }

    setClientLastSyncOid(value: number) {
        this.session.clientLastSyncOid = value
    }

    setPods(pods: Array<Pod>) {
        this.session.pods = pods
    }

}

const exportSessionStore = new sessionStore()
export default exportSessionStore