import { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "../../../stores/uiStore"
import { useStore } from "../../../contexts/store"
import { iEmotion, iWeblink, interactionAnchor } from "../../../../../types/Interaction"
import { Box, Button, TextField } from "@mui/material"
import { Op_addEmotion, Op_addWeblink, Op_editEmotion, Op_editWeblink } from "../../../../../types/Ops"
import Emoji from "../../Elements/Emoji"
import { hover } from "@testing-library/user-event/dist/hover"

const InteractionModalEmotion  = ({
    anchor,
    color,
    interaction
  }:
  {
    anchor: interactionAnchor | null,
    color: string | null,
    interaction: iEmotion | undefined
  }) => {
  const { t } = useTranslation()
  const [emotionId, setEmotionId] = useState<string>("")
  const { podId, pdfId } = useParams()
  const { sessionStore, podStore, opStore, uiStore } = useStore()
  const { session } = sessionStore

  const emotionList = [
    {label: "grinning face", symbol: "😀"},
    {label: "beaming face with smiling eyes", symbol: "😁"},
    {label: "winking face", symbol: "😉"},
    {label: "smiling face with smiling eyes", symbol: "😊"},
    {label: "smiling face with heart-eyes", symbol: "😍"},
    {label: "winking face with tongue", symbol: "😜"},
    {label: "thumbs up", symbol: "👍"},
    {label: "flushed face", symbol: "😳"},
    {label: "enraged face", symbol: "😡"},
    {label: "face screaming in fear", symbol: "😱"},
    {label: "downcast face with sweat", symbol: "😓"},
    {label: "pile of poo", symbol: "💩"},
    {label: "face with open mouth", symbol: "😮"},
    {label: "upside-down face", symbol: "🙃"}
  ]

  useEffect(() => {
    // if weblink is edited: load existing weblink data
    if(interaction) {
      const emotion = podStore.getInteraction(pdfId, interaction.interactionId) as iEmotion
      if(emotion) {
        setEmotionId(emotion.emotionId)
      }
    }
  }, [podStore, interaction])

  if(anchor === null) return null

  const handleSave = (emotion: {label: string, symbol: string}) => {
    // distinguish whether comment is edited or newly created
    if(interaction) {
      // save only if something changes in the input
      if(emotion.symbol !== interaction.emotionId || emotion.label !== interaction.label) {
        opStore.doOp({
          op: "editEmotion",
          podId: podId,
          data: {
            interactionType: 'emotion',
            usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
            userId: session.user.userId,
            userName: podStore.userPseudonym,
            interactionId: interaction.interactionId,
            mods: {
              label: emotion.symbol,
              tModified: Math.floor(Date.now()/1000),
              emotionId: emotion.label
            }
          }
        } as Op_editEmotion)
      }
    } else {
      opStore.doOp({
        op: "addEmotion",
        podId: podId,
        data: {
          interactionType: 'emotion',
          usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
          interactionId: sessionStore.createUuid(),
          userId: session.user.userId,
          userName: podStore.userPseudonym,
          anchor: {
            nodeId: anchor.nodeId,
            relText: anchor.relText,
            rects: JSON.parse(JSON.stringify(anchor.rects)),
          },
          style: { color: color },
          label: emotion.symbol,
          tCreated: Math.floor(Date.now()/1000),
          tModified: Math.floor(Date.now()/1000),
          lastModUuid: sessionStore.createUuid(),
          emotionId: emotion.label,
          coid: null,
        }
      } as Op_addEmotion)
    }
    handleClose()
  }

  function handleClose() {
    // close modal
    uiStore.closeInteractionModal()
    // delete selected anchor in store
    uiStore.setSelectedAnchor(null)
  }

  const emotions = emotionList.map(emotion => {
    return (
      <Box key={emotion.label} onClick={() => handleSave(emotion)} sx={{
        backgroundColor: emotion.label === emotionId ? "#e8e8e8" : "",
        border: emotion.label === emotionId ? " 1px solid #b8b8b8" : "",
        borderRadius: "5px",
        cursor: "pointer",
        display: "grid",
        fontSize: "50px",
        justifyItems: "center",
        width: "70px",
        "&:hover": {
          backgroundColor: "lightgrey"
        }
      }}>
        <Emoji label={emotion.label} symbol={emotion.symbol} />
      </Box>
    )
  })

  return (
    <Box sx={{margin: "5px 10px"}}>
      <Box sx={{display: "flex", flexWrap: "wrap", maxWidth: "500px"}}>
        {emotions}
      </Box>
      <Button onClick={handleClose} variant="outlined" sx={{margin: "20px 0", backgroundColor: "white"}}>
        {t('Cancel')}
      </Button>
    </Box>
  )
}

export default InteractionModalEmotion