import React, {useState} from 'react'
import sessionStore from '../stores/sessionStore'
import LanguageSwitch from './LanguageSwitch'
import { useTranslation } from "react-i18next"
import Loading from './Loading'

import { Button, Box } from '@mui/material'
import Infobox from './Infobox'
import { env } from '../api/api'

const Login: React.FC = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const handleKeycloakLogin = (async() =>{
      setLoading(true)
      await sessionStore.login('keycloak')
      setLoading(false)
    })

    const handleSatosaLogin = (async() =>{
      setLoading(true)
      await sessionStore.login('satosa')
      setLoading(false)
    })

    if(loading) return <Infobox>
      <Loading info="Waiting for login" />
    </Infobox>

    return (
      <>
        <div style={{justifySelf: "end", margin: "10px"}}>
          <LanguageSwitch />
        </div>
        <Infobox>
          <div style={{float:'left'}}>
            <img src="/img/logo512.png" style={{width:64, float:'left'}} alt="SHRIMP Logo" />
          </div>
          <div style={{marginLeft:64}}>
            <p>
              {t("You must log in to view the page")}
            </p>
            <Box>
              { (env.idps.findIndex((idp:any)=>idp.name==='keycloak') === -1) ? null : <Button sx={{marginRight:1, marginBottom:1}} variant="contained" onClick={handleKeycloakLogin} disabled={loading}>
                {t('Login via Keycloak')}
              </Button>}
              { (env.idps.findIndex((idp:any)=>idp.name==='satosa') === -1) ? null : <Button sx={{marginRight:1, marginBottom:1}} variant="outlined" onClick={handleSatosaLogin} disabled={loading}>
                {t('Login via Satosa')}
              </Button> }
            </Box>
          </div>
        </Infobox>
      </>
    )
  }

export default Login