import { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "../../../stores/uiStore"
import { useStore } from "../../../contexts/store"
import { iWeblink, interactionAnchor } from "../../../../../types/Interaction"
import { Box, Button, TextField } from "@mui/material"
import { Op_addWeblink, Op_editWeblink } from "../../../../../types/Ops"

const InteractionModalWeblink  = ({
    anchor,
    color,
    interaction
  }:
  {
    anchor: interactionAnchor | null,
    color: string | null,
    interaction: iWeblink | undefined
  }) => {
  const { t } = useTranslation()
  const [label, setLabel] = useState<string>("")
  const [url, setUrl] = useState<string>("")
  const { podId, pdfId } = useParams()
  const { sessionStore, podStore, opStore, uiStore } = useStore()
  const { session } = sessionStore

  useEffect(() => {
    // if weblink is edited: load existing weblink data
    if(interaction) {
      const weblink= podStore.getInteraction(pdfId, interaction.interactionId) as iWeblink
      if(weblink) {
        setLabel(weblink.label)
        setUrl(weblink.url)
      }
    }
  }, [podStore, interaction])

  if(anchor === null) return null

  const handleSave = () => {
    if(url === "") {
      alert(t('a url must be specified'))
      return
    }
    // distinguish whether comment is edited or newly created
    if(interaction) {
      // save only if something changes in the input
      if(url !== interaction.url || label !== interaction.label) {
        opStore.doOp({
          op: "editWeblink",
          podId: podId,
          data: {
            interactionType: 'weblink',
            usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
            userId: session.user.userId,
            userName: podStore.userPseudonym,
            interactionId: interaction.interactionId,
            mods: {
              label: label,
              tModified: Math.floor(Date.now()/1000),
              url: url
            }
          } 
        } as Op_editWeblink)
      }
    } else {
      opStore.doOp({
        op: "addWeblink",
        podId: podId,
        data: {
          interactionType: 'weblink',
          usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
          interactionId: sessionStore.createUuid(),
          userId: session.user.userId,
          userName: podStore.userPseudonym,
          anchor: {
            nodeId: anchor.nodeId,
            relText: anchor.relText,
            rects: JSON.parse(JSON.stringify(anchor.rects)),
          },
          style: { color: color },
          label: label,
          tCreated: Math.floor(Date.now()/1000),
          tModified: Math.floor(Date.now()/1000),
          lastModUuid: sessionStore.createUuid(),
          url: url,
          coid: null,
        }
      } as Op_addWeblink)
    }
    handleClose()
  }

  function handleClose() {
    // close modal
    uiStore.closeInteractionModal()
    // delete selected anchor in store
    uiStore.setSelectedAnchor(null)
  }

  return (
    <Box>
      <Box  sx={{ display: "grid", margin: "5px 10px"}}>
        <TextField
          sx={{backgroundColor:"white"}}
          minRows={3}
          maxRows={3}
          multiline
          onChange={(e: ChangeEvent<HTMLInputElement>) => setLabel(e.target.value)}
          label={t("Description")}
          value={label}
          variant="outlined"
        />
      </Box>
      <Box  sx={{ display: "grid", margin: "15px 10px"}}>
        <TextField
          sx={{backgroundColor:"#dedefd"}}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setUrl(e.target.value)}
          label={t("Weblink")}
          value={url}
          variant="outlined"
          onKeyDown={(ev) => {
            if(ev.key === 'Enter') {
              handleSave()
              ev.preventDefault()
            }
          }}
        />
      </Box>
      <Box sx={{display: "flex", flexWrap: "wrap"}}>
        <Button onClick={handleSave} variant="contained" sx={{margin: "10px 5px 20px 5px"}}>
          {t('Save')}
        </Button>
        <Button onClick={handleClose} variant="outlined" sx={{margin: "10px 5px 20px 5px", backgroundColor: "white"}}>
          {t('Cancel')}
        </Button>
      </Box>
    </Box>
  )
}

export default InteractionModalWeblink