import React, { useLayoutEffect } from "react"
import { observer } from "mobx-react-lite"
import { PodClass } from "../../classes/Pod"

import { useStore } from '../../contexts/store'
import { Op_editUserInfo, Op_removeUserFromPod } from "../../../../types/Ops"

import { List, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import { IconButton } from '@mui/material'

import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import EditUserInfo from "./EditUserInfo"
import { useParams } from "../../helper/Helper"

import { ListChildComponentProps } from 'react-window';
import { UserInfo } from "../../../../types/User"
import UserInfoAvatar from "../Elements/UserInfoAvatar"


const PodUsers = ({initScrollPos}: {initScrollPos: (tab: string) => void}) => {
  const { podId } = useParams()
  const { podStore, opStore } = useStore()
  const [editUserInfo, setEditUserInfo] = React.useState<UserInfo|null>(null)

  useLayoutEffect(() => {
    initScrollPos("people")
  })

  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  const leavePod = (userId:number) => {
    if (pod.userInfos) {
      const op:Op_removeUserFromPod = {
        op: 'removeUserFromPod',
        podId: pod.podId,
        data: {
          usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
          userId,
          userName: podStore.userPseudonym ? podStore.userPseudonym : '',
        }
      }
      opStore.doOp(op)
    }
  }

  const changeUserInfo = (userInfo: UserInfo) => {
    if (pod.userInfos) {
      const op:Op_editUserInfo = {
        op: 'editUserInfo',
        podId: pod.podId,
        data: {
          usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
          userId: userInfo.userId,
          userName: podStore.userPseudonym || '',
          mods: {
            color: userInfo.color,
            userName: userInfo.userName,
          }
        }
      } as Op_editUserInfo
      opStore.doOp(op)
    }
  }

  function renderRow(props: ListChildComponentProps) {
    const { style, data } = props;

    return (
      <ListItem style={{...style, display: "grid", gridTemplateColumns: "max-content auto max-content max-content"}} key={data.userId} component="div">
        <ListItemAvatar>
          <UserInfoAvatar variant='avatar' userId={data.userId} podId={podId} />
        </ListItemAvatar>
        <ListItemText sx={{wordBreak: "break-all"}} primary={data.userName} />

        <IconButton sx={{ml: "20px"}} title={'Change your name and avatar color'} onClick={() => setEditUserInfo({
          userId: data.userId,
          userName: data.userName,
          color: data.color
          })}>
          { pod && pod.isAllowed('editUserInfo', data.userId) ? <EditIcon /> : null }
        </IconButton>

        <IconButton title={'Leave this pod'} onClick={() => leavePod(data.userId)}>
          { pod && pod.isAllowed('removeUserFromPod', data.userId) ? <LogoutIcon /> : null }
        </IconButton>

      </ListItem>
    );
  }

  const handleClose = (values:UserInfo|null) => {
    if (values) {
      const userName = values?.userName?.trim()
      const color = values?.color
      if (userName && color && editUserInfo && editUserInfo.userId) changeUserInfo(values)
    }
    setEditUserInfo(null)
  }

  return <>
    <List>
      { Object.keys(pod.userInfos).map((userId:string, i:number) => {
        const row:{index:number, style:any, data:UserInfo} = {
          index: i,
          style: {},
          data: pod.userInfos[parseInt(userId)]
        }
        return renderRow(row)
      })}
      </List>
      <EditUserInfo userInfo={editUserInfo} handleClose={handleClose} />
  </>
/*
  return <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
    <FixedSizeList
      height={400}
      width={360}
      itemSize={46}
      itemCount={Object.keys(pod.userInfos).length}
      overscanCount={5}
    >
      {renderRow}
    </FixedSizeList>
  </Box>
*/

}

export default observer(PodUsers)