import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { useStore } from "../../contexts/store"
import { Folder } from "../../../../types/Content"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"

import { Op_editFolder } from "../../../../types/Ops"
import React from "react"
import { PodClass } from "../../classes/Pod"

// message from thread
const EditFolderDialog = ({folder, open, handleClose}: {folder: Folder, open: boolean, handleClose: any}) => {
  const [ newName, setNewName ] = React.useState<string>('')
  const [ newWeight, setNewWeight ] = React.useState<number>(0)
  const { podStore, sessionStore, opStore } = useStore()
  const { t } = useTranslation()
  const pod:PodClass|null = podStore.pod
  if (!pod) return null


  const doChanges = () => {
    const op:Op_editFolder = {
      op: 'editFolder',
      podId: pod.podId,
      data: {
        folderId: folder.folderId,
        usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
        userId: sessionStore.session.user.userId,
        userName: podStore.userPseudonym || '',
        mods: {}
      }
    }

    if (newName && (newName !== folder.name)) op.data.mods.name = newName
    if (newWeight && (newWeight !== folder.weight)) op.data.mods.weight = newWeight
    opStore.doOp(op)
  }

  const nameValue = newName ? newName : folder.name
  
  const folderIds:string[] = Object.keys(pod.content.folders)
  folderIds.unshift('-')
  
  return <Dialog open={open}>
    <DialogTitle>Edit File</DialogTitle>
    <DialogContent>
      <TextField fullWidth={true} style={{margin:'8px 0'}} label={t('Name')} value={nameValue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { if (event.target.value.trim()) setNewName(event.target.value) }} /><br />
      <TextField fullWidth={true} style={{margin:'8px 0'}} label={t('Weight')} value={newWeight ? newWeight : folder.weight} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { if (parseInt(event.target.value)) setNewWeight(parseInt(event.target.value)) }} /><br />
    </DialogContent>
    <DialogActions style={{textAlign:'right'}}>
      <Button onClick={handleClose}>{t('Cancel')}</Button>
      <Button variant='contained' onClick={() => { doChanges(); handleClose() }}>{t('OK')}</Button>
    </DialogActions>
  </Dialog>

}

export default observer(EditFolderDialog)