import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useStore } from '../../contexts/store'
import { observer } from 'mobx-react-lite'
import { Rect, iAnnotation, iComment, iEmotion, iLink, iTag, iWeblink } from '../../../../types/Interaction'
import { useParams } from '../../stores/uiStore'

const Overlay = (
    {
      childrenDepth,
      linkEditId,
      pageId,
      pdfId,
      pageNumber,
      scale
    }: {
      childrenDepth: number,
      linkEditId: string | null,
      pageId: string,
      pdfId: string,
      pageNumber: number,
      scale: number
    }
) => {
  const { podStore, uiStore } = useStore()
  const { interactionId } = useParams()
  const [pageContainer, setPageContainer] = useState<any>(null)

  useEffect(() => {
    const pageRef = document.getElementById(pageId)
    if(pageContainer === null && pageRef) setPageContainer(pageRef)
  }, [])

  // test if page exists
  if(pageContainer === null || pageContainer.children[childrenDepth] === null) return null

  // Do not show interactions while a selection is in progress (otherwise they will end up in the rectangle list when crossing page boundaries)
  if ((uiStore.selectionStartRange) && (uiStore.selectionEndRange) && uiStore.selectionStartRange.pageNumber !== uiStore.selectionEndRange.pageNumber) return null

  // load annotations for actual page
  const annotations: iAnnotation[] | null = podStore.getAnnotations(pdfId, pageNumber)
  // load comments for actual page
  const comments: iComment[] | null = podStore.getComments(pdfId, pageNumber)
  // load links for actual page
  const links: iLink[] | null = podStore.getLinks(pdfId, pageNumber)
  // load tags for actual page
  const tags: iTag[] | null = podStore.getTags(pdfId, pageNumber)
  // load weblinks for actual page
  const weblinks: iWeblink[] | null = podStore.getWeblinks(pdfId, pageNumber)
  // load emotions for actual page
  const emotions: iEmotion[] | null = podStore.getEmotions(pdfId, pageNumber)

  // get page inside pdf container
  const page = pageContainer.children[childrenDepth]

  // save all associated rects for each interaction
  const overlayList: {rect: Rect, color: string, op: string, interactionId: string}[] = []

  // link edit view: show only the currently selected link overlay,
  // otherwise: show overlay of all interactions
  if(linkEditId) {
    const linkOverlay = podStore.getLinkOverlay(linkEditId, null)
    if(linkOverlay) {
      const rects = linkOverlay.rects
      if(Array.isArray(rects) && rects.length) {
        rects.forEach((rect: Rect) => {
          if(rect.p === pageNumber) overlayList.push({rect: rect, color: "#00ced16b", op: "linkEdit", interactionId: linkEditId})
        })
      }
    }
  } else {
    // read all associated rectangles for each annotation
    if(Array.isArray(annotations) && annotations.length) {
      annotations.forEach( annotation => {
        const color = annotation.style.color ? uiStore.getSemiTransparentColor(annotation.style.color) : uiStore.getSemiTransparentColor(uiStore.getInteractionColor("annotation"))
        const rects = annotation.anchor.rects
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: color, op: "annotation", interactionId: annotation.interactionId})
          })
        }
      })
    }

    // read all associated rectangles for each comment
    if(Array.isArray(comments) && comments.length) {
      comments.forEach( comment => {
        const color = comment.style.color ? comment.style.color : uiStore.getInteractionColor("comment")
        const rects = comment.anchor.rects
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: color, op: "comment", interactionId: comment.interactionId})
          })
        }
      })
    }

    // read all associated rectangles for each link
    if(Array.isArray(links) && links.length) {
      links.forEach( link => {
        const color = link.style?.color ? link.style.color : uiStore.getInteractionColor("link")
        const rects = link.anchor.rects
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: color, op: "link", interactionId: link.interactionId})
          })
        }
      })
    }

    // read all associated rectangles for each tag
    if(Array.isArray(tags) && tags.length) {
      tags.forEach( tag => {
        const color = tag.style?.color ? tag.style.color : uiStore.getInteractionColor("tagging")
        const rects = tag.anchor.rects
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: color, op: "tagging", interactionId: tag.interactionId})
          })
        }
      })
    }

    // read all associated rectangles for each tag
    if(Array.isArray(weblinks) && weblinks.length) {
      weblinks.forEach( weblink => {
        const color = weblink.style?.color ? weblink.style.color : uiStore.getInteractionColor("weblink")
        const rects = weblink.anchor.rects
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: color, op: "weblink", interactionId: weblink.interactionId})
          })
        }
      })
    }

            // read all associated rectangles for each tag
    if(Array.isArray(emotions) && emotions.length) {
      emotions.forEach( emotion => {
        const color = emotion.style?.color ? emotion.style.color : uiStore.getInteractionColor("emotion")
        const rects = emotion.anchor.rects
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: color, op: "emotion", interactionId: emotion.interactionId})
          })
        }
      })
    }
  }

  // prevent showing overlay if no interaction is set
  if (overlayList.length < 1) return null

  return (
    createPortal(
      <div id={`overlay-page-${pageNumber}`}>
        {overlayList.map((item: {rect: Rect, color: string, op: string, interactionId: string}, index: number) => {
          if(item.op === "annotation") {
            return (
              <div key={index} style={{
                backgroundColor: item.color,
                height: item.rect.h * scale,
                left: item.rect.x * scale,
                opacity: (interactionId && item.interactionId !== interactionId) ? "20%" : "",
                position: "absolute",
                top: item.rect.y * scale,
                width: item.rect.w * scale,
                zIndex: 1,
              }}></div>
            )
          }
          if(item.op === "comment") {
            return (
              <div key={index} style={{
                borderBottom: `2px solid ${item.color}`,
                height: item.rect.h * scale,
                left: item.rect.x * scale,
                opacity: (interactionId && item.interactionId !== interactionId) ? "20%" : "",
                position: "absolute",
                top: (item.rect.y * scale) + 2, // paddingBottom: 2px
                width: item.rect.w * scale,
                zIndex: 2,
              }}></div>
            )
          }
          if(item.op === "link") {
            return (
              <div key={index} style={{
                borderBottom: `2px solid ${item.color}`,
                height: item.rect.h * scale,
                left: item.rect.x * scale,
                opacity: (interactionId && item.interactionId !== interactionId) ? "20%" : "",
                position: "absolute",
                top: (item.rect.y * scale) + 2, // paddingBottom: 2px
                width: item.rect.w * scale,
                zIndex: 2,
              }}></div>
            )
          }
          if(item.op === "tagging") {
            return (
              <div key={index} style={{
                borderBottom: `2px solid ${item.color}`,
                height: item.rect.h * scale,
                left: item.rect.x * scale,
                opacity: (interactionId && item.interactionId !== interactionId) ? "20%" : "",
                position: "absolute",
                top: (item.rect.y * scale) + 2, // paddingBottom: 2px
                width: item.rect.w * scale,
                zIndex: 2,
              }}></div>
            )
          }
          if(item.op === "weblink" || item.op === "emotion") {
            return (
              <div key={index} style={{
                borderBottom: `2px solid ${item.color}`,
                height: item.rect.h * scale,
                left: item.rect.x * scale,
                opacity: (interactionId && item.interactionId !== interactionId) ? "20%" : "",
                position: "absolute",
                top: (item.rect.y * scale) + 1, // paddingBottom: 2px
                width: item.rect.w * scale,
                zIndex: 2,
              }}></div>
            )
          }
          if(item.op === "linkEdit") {
            return (
              <div key={index} style={{
                backgroundColor: item.color,
                height: item.rect.h * scale,
                left: item.rect.x * scale,
                position: "absolute",
                top: item.rect.y * scale,
                width: item.rect.w * scale,
                zIndex: 1,
              }}></div>
            )
          }
          return null
        })}
      </div>
      ,page
    )
  )
}

export default observer(Overlay)
