import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { useStore } from "../../contexts/store"
import { useParams } from "../../stores/uiStore"
import { Message as MessageType } from "../../../../types/Message"
import EditMenu from "./EditMenu"
import TextArea from "./TextArea"
import { Box } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'

import { Op_editMessage } from "../../../../types/Ops"
import UserInfoAvatar from "./UserInfoAvatar"
import { PodClass } from "../../classes/Pod"
import TimeFromNow from "./TimeFromNow"

// message from thread
const Message = ({content}: {content: MessageType}) => {
  const { t } = useTranslation()
  const { podId, pdfId, interactionId } = useParams()
  const { sessionStore, uiStore, opStore, podStore } = useStore()

  // check if message is edited
  const isEdited = uiStore.editedMessageId === content.messageId

  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  const userInfo = pod.userInfos[content.userId]
  if(userInfo === undefined) return null

  function submitEdit(edit: string) {
    // save edit when something has changed
    if(edit !== content.text) {
      const op:Op_editMessage = {
        op: "editMessage",
        podId: podId,
        data: {
          userId: sessionStore.session.user.userId,
          userName: podStore.userPseudonym || '',
          usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
          threadId: content.threadId,
          messageId: content.messageId,
          mods: {
            text: edit,
            tModified: Math.floor(Date.now()/1000)
          }
        }
      }
      opStore.doOp(op)
    }
    // close edit message
    uiStore.setEditedMessageId(null)
  }

  const EditMessage = () => (
    <TextArea
      handleClose={() => {uiStore.setEditedMessageId(null)}}
      placeholder={t("Edit to this comment ...")}
      showHints={true}
      submit={(edit) => submitEdit(edit)}
      value={content.text}
      disabled={!(pod.isAllowed('editMessage', content.messageId))}
    />
  )

  return (
    <Box sx={{display: "grid", gridTemplateColumns: "max-content auto", padding: "20px"}}>
      {/* avatar (left) */}
      <Box sx={{marginRight:'20px',justifySelf: "right"}}>
        <UserInfoAvatar sx={{height:36, width:36}} userId={userInfo.userId} variant='avatar' podId={podId} />
      </Box>
      {/* content (right) */}
      <Box sx={{display: "grid", gridTemplateRows: "max-content auto min-content"}}>
        {/* header */}
        <Box sx={{display: "grid", gridTemplateColumns: "max-content auto min-content", alignItems: "center", marginBottom: "3px"}}>
          <Box sx={{fontWeight: "600", color: "#4c4c57", wordBreak: "break-word"}}>
            {userInfo.userName}
          </Box>
          <Box sx={{marginLeft: "15px", color: "#808080a6", fontSize: "13px"}}>
            <TimeFromNow timestamp={content.tModified ? content.tModified*1000 : 0} />
          </Box>
            <EditMenu
              interactionId={interactionId}
              interactionType={(content.messageId === interactionId) ? "comment" : "message"}
              pdfId={pdfId}
              podId={podId}
              messageId={content.messageId}
              threadId={content.threadId}
            />
        </Box>
        {/* message */}
        {isEdited && <EditMessage />}
        {!isEdited &&
          <Box sx={{paddingRight: "30px", wordBreak: "break-word", whiteSpace: "pre-line"}}>
            {content.text}
            {content.tCreated !== content.tModified &&
              <Box sx={{
                alignItems: "center",
                color: "#b6b6b6",
                display: "grid",
                fontSize: "11px",
                fontStyle: "italic",
                gridTemplateColumns: "max-content max-content",
                justifyContent: "end",
                padding: "2px 10px 0 0"
              }}>
                <EditIcon sx={{fontSize: "15px"}} /> {t('Edited')}
              </Box>
            }
          </Box>
        }
      </Box>
    </Box>
  )
}


export default observer(Message)