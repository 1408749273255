import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'wouter'
import { useStore } from '../../contexts/store'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'wouter'
import { Box, ListItemIcon, ListItemText, Paper } from '@mui/material'
import { IconButton } from '@mui/material'
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReplayIcon from '@mui/icons-material/Replay';
import { env } from '../../api/api'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

import { Pod } from '../../../../types/Pod'
import PodImpro from '../Dev/PodImpro'              // @hagen: bitte bis auf weiteres drin lassen. I know it's ugly, aber solange ich Deinen branch immer wieder bei mir reinmerge, ist das doof sonst :)
import { Op_removeUserFromPod } from '../../../../types/Ops'
import { PodClass } from '../../classes/Pod'
import ConfirmDialog from '../Elements/ConfirmDialog'

const Home: React.FC = () => {
  const { t } = useTranslation()
  const { sessionStore, podStore, opStore, uiStore } = useStore()
  const { session } = sessionStore
  const pods = session.pods
  const [ anchorEl, setAnchorEl ] = React.useState<null | Element>(null)
  const [ activePodId, setActivePodId ] = React.useState<null|string>(null)
  const [ confirmPodReset, setConfirmPodReset ] = React.useState<null|string>(null)
  const navigate = useLocation()[1]

  const deviceWidth = uiStore.deviceWidth
  const containerPadding = (deviceWidth === "tiny") ? 16 : 40

  useEffect(() => {
    podStore.unsetPod()
  })

  const handleClick = (event: React.MouseEvent<Element>, podId:string) => {
    setAnchorEl(event.currentTarget)
    setActivePodId(podId)
    event.stopPropagation()
  }

  const handleClose = () => {
    setAnchorEl(null)
    setActivePodId(null)
  }

  const leavePod = (userId:number, podId:string) => {
    const p = sessionStore.session.pods.find(p => p.podId === podId)
    if (p) {
      const pod = new PodClass(p)
      const op:Op_removeUserFromPod = {
        op: 'removeUserFromPod',
        podId: podId,
        data: {
          usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
          userId,
          userName: pod.userInfos[userId].userName,
        }
      }
      opStore.doOp(op)
    }
  }

  const suggestReset = (e:React.MouseEvent, podId:string) => {
    const pod = sessionStore.session.pods.find((pod:Pod) => pod.podId === podId)
    if (pod && pod.outOfSync) {
      setConfirmPodReset(podId)
      e.preventDefault()
      e.stopPropagation()
    }
  }

  // TODO: create message if there are no pods available
  if(pods === null) return <div>no pods available</div>

  const sx = {
    height: "50px",
    display: "grid",
    gridTemplateColumns: "min-content auto min-content min-content",
    gridGap: "10px",
    alignContent: "top",
    p: 2,
    mt: 1,
    mb: 1,
    "&:hover": {
      backgroundColor: "whitesmoke"
    }
  }

  const podsList = pods.slice().sort((p1:Pod, p2: Pod) => { return ('' + p1.name).localeCompare(p2.name); }).map((pod: Pod) => {
    let status = null

    if (pod.outOfSync) status = <Box title={t('This pod seems to be out of sync. It is recommended that you reset it. Click for more.')}><SyncProblemIcon color='warning' /></Box>
    return  <li key={pod.podId}>
      <Link to={`pod/${pod.podId}/contents`} onClick={(e) => suggestReset(e, pod.podId)} style={{textDecoration: "none"}}>
        <Paper elevation={2} sx={sx}>
          <FolderSpecialIcon />
          <div style={{overflow:'hidden'}}>
          {pod.name}
          {pod.status === 'requested' ? <span>&nbsp;(access requested)</span> : null}
          </div>
          {status}
          <IconButton size='small' onClick={(e:React.MouseEvent) => handleClick(e, pod.podId) }>
            <MoreHorizIcon fontSize='small' />
          </IconButton>
        </Paper>
      </Link>
    </li>
  })

  return (
    <Box className="overflowWrapper" id="pod-container" sx={{
      display: "grid",
      gridTemplateRows: "max-content auto",
    }}>
      <Box sx={{backgroundColor: "white"}}>
        <Box sx={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: `${1280 + (2*containerPadding)}px`,
          paddingTop: `${(deviceWidth === 'medium' || deviceWidth === 'large') ? 40 : 0}px`,
          paddingLeft: `${containerPadding}px`,
          paddingRight: `${containerPadding}px`,
          marginBottom: "5px"
        }}>
        <Box sx={{ display: "grid", gridTemplateRows: "auto", alignContent: "start", p: 5 }}>
          <h2>{t('Welcome to SHRIMP')}</h2>
          <ul style={{listStyleType: "none", padding: 0}}>{podsList}</ul>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText onClick={(e) => { if (activePodId) leavePod(sessionStore.session.user.userId, activePodId); handleClose(); e.stopPropagation() }}>{t('Leave this pod')}</ListItemText>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <ReplayIcon />
            </ListItemIcon>
            <ListItemText onClick={(e) => { if (activePodId) podStore.resetPod(activePodId); handleClose(); e.stopPropagation() }}>{t('Reload this pod')}</ListItemText>
          </MenuItem>

        </Menu>
        <ConfirmDialog
          title={t('Pod Out of Sync')}
          info={t('The Pod seems to be out of sync. To make sure') as string}
          open={Boolean(confirmPodReset)}
          handleClose={() => { setConfirmPodReset(null); navigate(`pod/${confirmPodReset}/contents`) }}
          onOk={() => { if (confirmPodReset) podStore.resetPod(confirmPodReset) }}
        />
        {env.dev ? <PodImpro /> : null}
      </Box>
    </Box>
  </Box>
  )

}

export default observer(Home)