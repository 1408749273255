import { ChangeEvent } from 'react'
import TextFieldMui from '@mui/material/TextField'

const TextField = ({
    handleInputChange,
    handleClose,
    placeholder,
    submit,
    value,
    disabled
  }: {
    handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void,
    handleClose: () => void,
    placeholder: string,
    submit: () => void,
    value: string,
    disabled:boolean
  }) => {

  return (
    <TextFieldMui
      autoFocus
      id="comment-message-input-edit"
      fullWidth
      placeholder={placeholder}
      maxRows={8}
      multiline
      onChange={handleInputChange}
      disabled={disabled}
      onKeyDown={(ev) => {
        if (ev.key === 'Enter' && !ev.shiftKey) {
          submit()
          ev.preventDefault()
        }
        if (ev.key === 'Escape') {
          handleClose()
          ev.stopPropagation()
          ev.preventDefault()
        }
      }}
      value={value}
    />
  )
}

export default TextField