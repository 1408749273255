import { IconButton } from "@mui/material"
import { Interaction } from "../../../../../types/Interaction"
import { navigate } from "wouter/use-location"
import InteractionIcon from "../../Elements/InteractionIcon"

// shows interaction as marginalia icon
const MarginaliaIcon = (
  {
    item,
    pdfUrl,
    radius
  }: {
    item: Interaction,
    pdfUrl: string,
    radius: number
  }
) => {
  // open drawer, if interaction is clicked
  const handleClick = (interactionType: string, interactionId: string) => {
    navigate(`${pdfUrl}/${interactionType}/${interactionId}`, {replace: true})
  }

  return (
    <IconButton
      aria-label={item.interactionType}
      onClick={() => handleClick(item.interactionType, item.interactionId)}
      sx={{
        backgroundColor: item.style.color,
        borderRadius: item.interactionType === "annotation" ? "5px": "",
        color: "white",
        width: `${radius}px`,
        height: `${radius}px`,
        margin: "1px",
        "> svg":{fontSize: `${radius/2}px`},
        "&:hover": {color: item.style.color}
      }}
    >
      <InteractionIcon interactionType={item.interactionType} />
    </IconButton>
  )
}

export default MarginaliaIcon