import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { useStore } from "../../contexts/store"
import { PdfFile } from "../../../../types/Content"
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper } from "@mui/material"
import { TextSnippet, MoreHoriz, Visibility, VisibilityOff, Edit } from '@mui/icons-material'

import { Op_editPdfFile } from "../../../../types/Ops"
import { Link } from "wouter"

import EditPdfFileDialog from "./EditPdfFileDialog"
import { PodClass } from "../../classes/Pod"

// message from thread
const PdfFileLine = ({pdfFile}: {pdfFile: PdfFile}) => {
  const [ anchorEl, setAnchorEl ] = React.useState<null | Element>(null)
  const [ showDialog, setShowDialog ] = React.useState<boolean>(false)
  const { podStore, sessionStore, opStore } = useStore()
  const { t } = useTranslation()
  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  const handleClick = (event: React.MouseEvent<Element>) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const handleClose = (event:React.MouseEvent) => {
    setAnchorEl(null)
    event.stopPropagation()
  }

  const toggleVisibility = () => {
    const op:Op_editPdfFile = {
      op: 'editPdfFile',
      podId: pod.podId,
      data: {
        nodeId: pdfFile.nodeId,
        usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
        userId: sessionStore.session.user.userId,
        userName: podStore.userPseudonym || '',
        mods: {
          hidden: (pod.content.pdfFiles[pdfFile.nodeId].hidden) ? false : true
        }
      }
    }
    opStore.doOp(op)
  }

  const sx = {
    overflow:'hidden', 
    height: "50px", 
    display: "grid", 
    gridTemplateColumns: pod.isAllowed('editPdfFile', pdfFile.nodeId) ? "min-content auto min-content" : "min-content auto", 
    gridGap: "10px", 
    alignContent: "center", 
    p: 2, 
    mt: 1, 
    mb: 1, 
    "&:hover": { backgroundColor: "whitesmoke" } 
  }

  var visibility = {
      style: { opacity: 1 },
      text: t('Hide from regular pod users'),
      icon: <VisibilityOff />
  }

  if (pdfFile.hidden) visibility = {
    style: { opacity: .3 },
    text: t('Show to regular pod users'),
    icon: <Visibility />
  }

  
  return <>
    <Link to={`/pod/${pod.podId}/pdf/${pdfFile.nodeId}`} style={{textDecoration: "none"}}>
      <Paper style={visibility.style} elevation={2} sx={sx}>
        <TextSnippet />
        <div style={{whiteSpace:'nowrap', overflow: 'hidden'}}>
          { pdfFile.name }
        </div>
        { 
        !pod.isAllowed('editPdfFile', pdfFile.nodeId) ? null : 
        <IconButton size='small' onClick={(e:React.MouseEvent) => { handleClick(e)} }>
          <MoreHoriz fontSize='small' />
        </IconButton>
        }
      </Paper>
    </Link>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
    }}
    >
      <MenuItem  onClick={(e:React.MouseEvent) => { setShowDialog(true); handleClose(e); e.stopPropagation() }}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText>{t('Edit this file')}</ListItemText>
      </MenuItem>

      <MenuItem onClick={(e:React.MouseEvent) => { toggleVisibility(); handleClose(e); e.stopPropagation() }}>
        <ListItemIcon>
          {visibility.icon}
        </ListItemIcon>
        <ListItemText>{visibility.text}</ListItemText>
      </MenuItem>

    </Menu>
    <EditPdfFileDialog pdfFile={pdfFile} open={showDialog} handleClose={() => { setShowDialog(false)}} />
  </>
}

export default observer(PdfFileLine)