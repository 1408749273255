import i18next from 'i18next'
import { makeObservable, action, observable } from "mobx"

import alertStore, {alert} from "./alertStore"
import { InteractionType, interactionAnchor } from '../../../types/Interaction'
import { OpCode } from "../../../types/OpCodes"

import { useLocation } from 'wouter'
import { DeviceWidths, PodScrollPos } from '../../../types/Miscs'

export type SelectionRange = { node:Node, offset:number, t0:number, pageNumber:number, clientX: number, clientY: number }

export const useParams = () => {
  const [ location ] = useLocation()
  let parms:RegExpMatchArray | null = null
  let urlParams:{[key:string]:string} = {}

  parms = location.match(/^\/pod\/([^/]+)\/?(pdf\/([^/]+))?\/?(([a-zA-Z]+)\/([^/]+))?/)
  if (parms && parms[1]) urlParams.podId  = parms[1]
  if (parms && parms[3]) urlParams.nodeId = urlParams.pdfId = parms[3]      // best practice: use nodeId, but we will provide pdfId for legacy reasons
  if (parms && parms[5]) urlParams.interactionType = parms[5]
  if (parms && parms[6]) urlParams.interactionId = parms[6]

  return urlParams
}

export interface uiStoreModel {
    annotationColors: string[],
    isOffline: boolean,
    selectedAnchor: interactionAnchor | null,
    showVerboseLogging: {[feature:string]: boolean},
    interactionModal: {isOpen: boolean, interactionId: string | null},
    openInteractionModal: (interactionId?: string) => void,
    closeInteractionModal: () => void,
    getSelectedInteraction: () => OpCode,
    getSemiTransparentColor: (color: string) => string,
    getAnnotationColor: () => string,
    setSelectedAnchor: (anchor: interactionAnchor | null) => void,
    setSelectedInteraction: (interactionType: OpCode) => void,
    setAnnotationColor: (color: string) => void,
    setIsOffline(isOffline: boolean): void,
    getInteractionColor: (op: OpCode | InteractionType) => string
    deviceWidth: DeviceWidths
    pageMargin: number | undefined
    setDeviceWidth: (width: number) => void
    width: number
    cookieConsent: boolean
    setCookieConsent: (consent: boolean) => void

    selectionStartRange: SelectionRange | null
    selectionEndRange: SelectionRange | null
    setSelectionStartRange: (range: SelectionRange|null) => void
    setSelectionEndRange: (range: SelectionRange|null) => void
    isPinchZooming: boolean
    setIsPinchZooming: (isPinchZooming: boolean) => void
    marginaliaIconRadius: number

    urlParams: {[key:string]: string}
    setUrlParams: (key:string, value:string) => void

    folderIsOpen: {[podId:string]: {[folderId: string]: boolean}}
    setFolderIsOpen: (podId:string, folderId:string, status:boolean) => void

    editedMessageId: string | null
    setEditedMessageId: (messageId: string | null) => void

    forceUpdate: number
    forceViewerUpdate: () => void

    copyMode: boolean
    setCopyMode: (value:boolean) => void

    getThumbContainerWidth: () => number

    getPodScrollPos: (podId: string, tab: string) => number | null
    setPodScrollPos: (podId: string, tab: string, pos: number) => void
    getActiveConversation: (podId: string) => string | null
    setActiveConversation: (podId: string, activeConversation: string | null) => void

    timer: number
    incrementTimer: () => void

    getActivePodTab: (podId: string) => string
    setActivePodTab: (podId: string, activeTab: string) => void
  }

class uiStore {
  // default ui settings
  isOffline = false
  annotationColors = [
    "#DC267F",
    "#FE6100",
    "#FFB000"
  ]
  selectedAnnotationColor = this.annotationColors[0]
  selectedAnchor: interactionAnchor | null = null
  interactionModal: {isOpen: boolean, interactionId: string | null} = {isOpen: false, interactionId: null}
  selectedInteractionType: OpCode = "addAnnotation"
  showVerboseLogging = {
    sync: false,
    loadPod: false,
    opProcessing: false,
  }
  overlayColorAlpha = 0.5
  urlParams: {[key:string]: string} = {}
  width = 0
  deviceWidth: DeviceWidths = undefined
  pageMargin: number | undefined = undefined
  cookieConsent = false
  selectionStartRange: SelectionRange | null = null
  selectionEndRange: SelectionRange | null = null
  isPinchZooming: boolean = false
  marginaliaIconRadius = 20

  folderIsOpen: {[podId:string]: {[folderId: string]: boolean}} = {}
  editedMessageId: string | null = null
  forceUpdate: number = 0

  copyMode:boolean = false

  podScrollPos: PodScrollPos[] = []
  activeConversations: {[podId:string]: string | null} = {}

  timer: number = 0
  activePodTab: {[podId:string]: string} = {}

  constructor() {
    makeObservable(this, {
      annotationColors: observable,
      isOffline: observable,
      getSelectedInteraction: observable,
      getSemiTransparentColor: action,
      getAnnotationColor: observable,
      selectedAnchor: observable,
      selectedAnnotationColor: observable,
      selectedInteractionType: observable,
      showVerboseLogging: observable,
      setSelectedAnchor: observable,
      setSelectedInteraction: action,
      setAnnotationColor: action,
      setIsOffline: action,
      interactionModal: observable,
      openInteractionModal: action,
      closeInteractionModal: action,
      deviceWidth: observable,
      setDeviceWidth: action,
      pageMargin: observable,
      width: observable,

      cookieConsent: observable,
      setCookieConsent: action,

      selectionStartRange: observable,
      selectionEndRange: observable,
      setSelectionStartRange: action,
      setSelectionEndRange: action,

      isPinchZooming: observable,
      setIsPinchZooming: action,
      marginaliaIconRadius: observable,

      urlParams: observable,
      setUrlParams: action,

      folderIsOpen: observable,
      setFolderIsOpen: action,

      editedMessageId: observable,
      setEditedMessageId: action,

      forceUpdate: observable,
      forceViewerUpdate: action,

      copyMode: observable,
      setCopyMode: action,

      getThumbContainerWidth: observable,

      getPodScrollPos: action,
      setPodScrollPos: action,
      activeConversations: observable,
      getActiveConversation: observable,
      setActiveConversation: action,

      timer: observable,
      incrementTimer: action,
      activePodTab: observable,
      getActivePodTab: observable,
      setActivePodTab: action
    })
  }

  getThumbContainerWidth() {
    if(this.deviceWidth === "large") return 200
    if(this.deviceWidth === "medium") return 100
    else return 60
  }

  setUrlParams(key:string, value: string = '') {
    if (value) this.urlParams[key] = value; else delete this.urlParams[key]
  }

  getSelectedInteraction() {
    return this.selectedInteractionType
  }

  getInteractionColor(op: OpCode | InteractionType) {
    if(op === "annotation" || op === "addAnnotation") return this.selectedAnnotationColor
    if(op === "emotion" || op === "addEmotion") return "#ffd800"
    if(op === "comment" || op === "addComment") return "#ffd800"
    if(op === "link" || op === "addLink") return "#0b84fa"
    if(op === "weblink" || op === "addWeblink") return "#0b84fa"
    if(op === "tagging" || op === "addTagging") return "#7400bf"
    console.warn('unexpected operation in getInteractionColor', op)
    return "gray"
  }

  getSemiTransparentColor(color : string) {
    // use only if color from annotations palette to ensure compatibility with imported colors
    if(this.annotationColors.includes(color)) {
      const semiTransparentColor = `${color}${Math.floor(this.overlayColorAlpha * 255).toString(16).padStart(2, '0')}`
      return semiTransparentColor
    }
    return color
  }

  getAnnotationColor() {
    return this.selectedAnnotationColor
  }

  setSelectedAnchor(anchor: interactionAnchor | null) {
    this.selectedAnchor = anchor
  }

  setSelectedInteraction(interactionType: OpCode) {
    this.selectedInteractionType = interactionType
  }

  setAnnotationColor(color: string) {
    this.selectedAnnotationColor = color
  }

  setIsOffline(isOffline: boolean) {
      // switch from online to offline mode
      if(!this.isOffline && isOffline) {
          alertStore.push(alert(i18next.t('changes will be saved locally until the next time you go online again'), 'info', i18next.t('You are in Offline-Mode') ?? '', 'WifiOffIcon'))
      }
      // switch from offline to online mode
      if(this.isOffline && !isOffline) {
          alertStore.push(alert(i18next.t('your local changes are now synchronized with the server'), 'success', i18next.t('You are online again') ?? '', 'WifiOnIcon'))
      }
      this.isOffline = isOffline
  }

  openInteractionModal(interactionId?: string) {
    if(interactionId) {
      this.interactionModal = {isOpen: true, interactionId: interactionId}
    }
    else {
      this.interactionModal = {isOpen: true, interactionId: null}
    }
  }

  closeInteractionModal() {
    this.interactionModal = {isOpen: false, interactionId: null}
  }

  setDeviceWidth(width: number) {
    if(width <= 750 && this.deviceWidth !== "tiny") {
      this.deviceWidth = "tiny"
      this.pageMargin = 30
    }
    if(width > 750 && width <= 950 && this.deviceWidth !== "small") {
      this.deviceWidth = "small"
      this.pageMargin = 70
    }
    if(width > 950 && width <= 1600 && this.deviceWidth !== "medium") {
      this.deviceWidth = "medium"
      this.pageMargin = 120
    }
    if(width > 1600 && this.deviceWidth !== "large") {
      this.deviceWidth = "large"
      this.pageMargin = 150
    }
    this.width = width
  }

  setCookieConsent(consent:boolean) {
    this.cookieConsent = consent
  }

  setSelectionStartRange(selectionRange:SelectionRange|null) {
    this.selectionStartRange = selectionRange
  }

  setSelectionEndRange(selectionRange:SelectionRange|null) {
    this.selectionEndRange = selectionRange
  }

  setIsPinchZooming(isPinchZooming:boolean) {
    this.isPinchZooming = isPinchZooming
  }

  setFolderIsOpen(podId:string, folderId:string, status: boolean) {
    if (typeof this.folderIsOpen[podId] === 'undefined') this.folderIsOpen[podId] = {}
    this.folderIsOpen[podId][folderId] = status
  }

  setEditedMessageId(messageId: string | null) {
    this.editedMessageId = messageId
  }

  forceViewerUpdate() {
    this.forceUpdate++
  }

  setCopyMode(value:boolean) {
    this.copyMode=value
  }

  getPodScrollPos(podId: string, tab: string) {
    const podScrollPos = this.podScrollPos.find(item => item.id === podId)
    if(podScrollPos) {
      if(tab === "contents") return podScrollPos.contents
      if(tab === "activity") return podScrollPos.activity
      if(tab === "notes") return podScrollPos.notes
      if(tab === "people") return podScrollPos.people
      if(tab === "settings") return podScrollPos.settings
    }
    return null
  }

  setPodScrollPos(podId: string, tab: string, pos: number) {
    const podScrollPos = this.podScrollPos.find(item => item.id === podId)
    if(podScrollPos) {
      if(tab === "contents") podScrollPos.contents = pos
      if(tab === "activity") podScrollPos.activity = pos
      if(tab === "notes") podScrollPos.notes = pos
      if(tab === "people") podScrollPos.people = pos
      if(tab === "settings") podScrollPos.settings = pos
    } else {
      // init scrollPos
      const scrollPos: PodScrollPos = {
        id: podId,
        contents: (tab === "contents") ? pos : 0,
        activity: (tab === "activity") ? pos : 0,
        notes: (tab === "notes") ? pos : 0,
        people: (tab === "people") ? pos : 0,
        settings: (tab === "settings") ? pos : 0,
      }
      this.podScrollPos.push(scrollPos)
    }
  }

  getActiveConversation(podId: string) {
    const activeConversation = this.activeConversations[podId]
    if(activeConversation) return activeConversation
    return null
  }

  setActiveConversation(podId: string, activeConversation: string | null) {
    this.activeConversations[podId] = activeConversation
  }

  incrementTimer() {
    this.timer++
  }

  getActivePodTab(podId: string) {
    const activePodTab = this.activePodTab[podId]
    if(activePodTab) return activePodTab
    return "contents"
  }

  setActivePodTab(podId: string, activeTab: string) {
    this.activePodTab[podId] = activeTab
  }

}


const exportUiStore = new uiStore()
export default exportUiStore
