import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "../../../stores/uiStore"
import { useStore } from "../../../contexts/store"
import { iComment, interactionAnchor } from "../../../../../types/Interaction"
import { Box } from "@mui/material"
import TextArea from "../../Elements/TextArea"
import { Op_addComment, Op_editComment } from "../../../../../types/Ops"
import { PodClass } from "../../../classes/Pod"

const InteractionModalComment  = ({
    anchor,
    color,
    interaction
  }:
  {
    anchor: interactionAnchor | null,
    color: string | null,
    interaction: iComment | undefined
  }) => {
  const { t } = useTranslation()
  const [ label, setLabel ] = useState<string>("")
  const { podId, pdfId } = useParams()
  const { sessionStore, podStore, opStore, uiStore } = useStore()
  const { session } = sessionStore

  useEffect(() => {
    // if comment is edited: load existing comment data
    if(interaction) {
      const comment = podStore.getInteraction(pdfId, interaction.interactionId)
      if(comment) {
        setLabel(comment.label)
      }
    }
  }, [podStore, interaction, pdfId])

  if(anchor === null) return null
  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  const submitEdit = (input: string) => {
    // distinguish whether comment is edited or newly created
    if(interaction) {
      // save only if something changes in the input
      if(label!== input) {
        const op:Op_editComment = {
          op: "editComment",
          podId: podId,
          data: {
            usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
            userId: sessionStore.session.user.userId,
            userName: podStore.userPseudonym || '',
            interactionId: interaction.interactionId,
            mods: {
              label: input,
              tModified: Math.floor(Date.now()/1000)
            }
          }
        }
        opStore.doOp(op)
      }
    } else {
      const op:Op_addComment = {
        op: "addComment",
        podId: podId,
        data: {
          interactionId: sessionStore.createUuid(),
          interactionType: 'comment',
          usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
          userId: session.user.userId,
          userName: podStore.userPseudonym,
          anchor: {
            nodeId: anchor.nodeId,
            relText: anchor.relText,
            rects: JSON.parse(JSON.stringify(anchor.rects)),
          },
          style: { color: color || undefined },
          label: input,
          tCreated: Math.floor(Date.now()/1000),
          tModified: Math.floor(Date.now()/1000),
          coid:null,
        }
      }
      opStore.doOp(op)
    }
    handleClose()
  }

  function handleClose() {
    // close modal
    uiStore.closeInteractionModal()
    // delete selected anchor in store
    uiStore.setSelectedAnchor(null)
  }

  return (
    <Box>
      <TextArea
        handleClose={handleClose}
        placeholder={interaction ? t("Edit this comment ...") : t("Create comment")}
        showHints={interaction ? true : false}
        submit={submitEdit}
        value={label}
        disabled={interaction ? !(pod.isAllowed('editComment', interaction.interactionId)) : !(pod.isAllowed('addComment') && pod.isAllowed('addThread'))}
      />
    </Box>
  )
}

export default InteractionModalComment