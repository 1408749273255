import { makeObservable, action, observable, computed } from "mobx"

import opStore from './opStore'
import uiStore from "./uiStore"
import sessionStore from "./sessionStore"
import alertStore, {alert} from "./alertStore"

import api from '../api/api'

import { Pod, PodLoadState, Usergroup } from '../../../types/Pod'
import { PodClass, PodI } from '../classes/Pod'
import { Interaction, InteractionType, Rect, iAnnotation, iComment, iEmotion, iLink, iTag, iWeblink, interactionAnchor } from '../../../types/Interaction'
import { Tag, fullLink } from "../../../types/Content"
import { Op } from "../../../types/Ops"
import { Thread } from "../../../types/Message"
import { UserInfo } from "../../../types/User"
import { ConversationItem } from "../../../types/Miscs"
import { activityOps } from "../helper/activityOps"

export interface PodStoreModel {
  pod: PodClass|null
  podIsLoading: boolean
  activeLinkEditId: string | null
  userPseudonym: string | null
  userInfo: UserInfo | null
  podActivity: Op[]

  getAnnotations: (fileId: string, page: number | null) => iAnnotation[] | null
  getNotes: (fileId: string, page: number | null) => iAnnotation[] | null
  getComments: (fileId: string, page: number | null) => iComment[] | null
  getThread: (interactionId: string) => Thread | null
  getConversations: (userId : number) => null | ConversationItem[]
  getLinks: (fileId: string, page: number | null) => iLink[] | null
  getInteraction: (fileId: string, interactionId: string) => Interaction | null
  getLink: (fileId: string | undefined | null, interactionId: string | undefined | null) => fullLink | null
  getTags: (fileId: string, page: number | null) => iTag[] | null
  getWeblinks: (fileId: string, page: number | null) => iWeblink[] | null
  getEmotions: (fileId: string, page: number | null) => iEmotion[] | null
  getTagProp: (tagId: string) => Tag | null
  getTagPool: () => Tag[]
  getUsergroupByRole: (role: string) => Usergroup

  setActiveLinkEditId: (linkEditId: string | null) => void
  loadPod: (podId: string) => Promise<boolean>
  unsetPod: () => void
  resetPod: (podId: string) => Promise<boolean>

  getLinkLabel: (linkId: string, linkLabel: string | null) => string
  setLinkLabel: (linkId: string, text: string) => void
  deleteLinkLabel: (linkId: string) => void
  getLinkFile: (linkId: string, fileId: string | null) => string
  setLinkFile: (linkId: string, fileId: string) => void
  deleteLinkFile: (linkId: string) => void
  getLinkOverlay: (linkInteractionId: string, anchor: interactionAnchor | null) => interactionAnchor
  setLinkOverlay: (linkInteractionId: string, anchor: interactionAnchor) => void
  deleteLinkOverlay: (linkId: string) => void
  getInteractionByCoordinates: (pdfId: string, x: number, y: number, page: number) => {type: InteractionType, clickedRect: Rect, interaction: Interaction} | null
  getFilename: (nodeId: string) => string | null
  setPodActivity: (ops: Op[]) => void
  getPodActivity: (podId: string) => Op[]
  addToPodActivity: (podId: string, op: Op) => void
  getUserInfo: (userId: number | undefined | null, podId: string | undefined | null) => UserInfo | null

  setOutOfSync: (status:boolean) => void
}

type PodCondition = {
  status: PodLoadState,
  info: string
}

class podStore {
  podIsLoading: boolean = false
  activePodId: string | null = null
  activePdfId: string | null = null
  activeLinkEditId: string | null = null
  getOpsintervalId: number | null = null
  linkLabel: {[id: string]: string} = {}
  linkFile: {[id: string]: string} = {}
  linkOverlay: {[linkInteractionId: string]: interactionAnchor} = {}
  urlParams: {[key:string]: string} = {}

  // the currently loaded, active pod.
  pod: PodClass | null = null
  podCondition: {[podId: string]: PodCondition} = {}
  podActivity: Op[] = []

  constructor() {
    makeObservable(this, {
      activePodId: observable,
      activePdfId: observable,
      activeLinkEditId: observable,
      pod: observable,
      podCondition: observable,
      setPod: action,
      setActiveLinkEditId: action,
      resetPod: action,
      unsetPod: action,
      setPodStatus: action,
      getAnnotations: observable,
      getNotes: observable,
      getComments: observable,
      getThread: observable,
      getConversations: observable,
      getLinks: observable,
      getTags: observable,
      getWeblinks: observable,
      getEmotions: observable,
      getInteraction: observable,
      getTagProp: observable,
      getTagPool: observable,
      linkLabel: observable,
      getLinkLabel: observable,
      setLinkLabel: action,
      deleteLinkLabel: action,
      linkFile: observable,
      getLinkFile: observable,
      setLinkFile: action,
      deleteLinkFile: action,
      linkOverlay: observable,
      getLinkOverlay: observable,
      setLinkOverlay: action,
      deleteLinkOverlay: action,
      getInteractionByCoordinates: action,
      userPseudonym: computed,
      userInfo: computed,
      podActivity: observable,
      getPodActivity: observable,
      setPodActivity: action,
      addToPodActivity: action,
      getFilename: action,
      getUserInfo: action,
      setOutOfSync: action,
    })
  }

  get userPseudonym() {
    const userInfo = this.userInfo
    if (userInfo) return userInfo.userName
    return null
  }

  get userInfo() {
    if ((this.pod) && (sessionStore.session.user.userId)) return this.pod.userInfos[sessionStore.session.user.userId]
    return null
  }

  async resetPod(podId: string) {
    delete this.podCondition[podId]
    if (podId === this.pod?.podId) this.unsetPod()
    await api.loadPod(podId, true)
    return true
  }

  /** trigger loading pod with podId */
  async loadPod(podId: string, force: boolean = false) {
    const t0 = Date.now()

    let pod: PodClass

    // Do not load pods that are already loaded
    if ((!force) && (this.pod?.podId === podId) && (this.pod.status === 'loaded')) {
      return true
    }

    if (this.pod?.status === 'loading') {
      console.warn(`Warning: loadPod() was called while a pod was loading. Cannot load pod ${podId} while ${this.pod.podId} is loading.`)
      return false
    }

    // console.log(`Loading pod ${podId}`, this.pod)

    if (true) {
      pod = new PodClass(null, true)
      pod.podId = podId
      pod.status = 'loading'
      this.setPod(pod)
    }

    // Initialize with empty 'init' version of the pod (or get the full Pod if the serviceWorker has it)
    const loadedPod = await api.loadPod(podId)

    if (loadedPod) {

      if (loadedPod.status === 'unknown') {
        pod.setStatus('unknown')
        this.setPod(pod)
        const pendingOps = await api.getPendingOps(podId)
        if (pendingOps) {
          if (uiStore.showVerboseLogging.loadPod) console.log(`Applying ${pendingOps.length} pending OPs`)
          pendingOps.forEach((op:Op) => {
            opStore.execute(pod, op)
          })
        }
        return true
      }

      if (loadedPod.status === 'broken') {
        pod.status = 'broken'
        this.setPod(pod)
        return true
      }

      pod = loadedPod

      if ((pod.lastSyncOid >= pod.initMaxCoid) && (pod.status === 'loaded')) {
        // query wurde vom SW mit einem vollständigen Pod beantwortet. Apply pending Ops
        if (uiStore.showVerboseLogging.loadPod) console.log(`Finished loading in ${Date.now()-t0}ms.`)

        const pendingOps = await api.getPendingOps(podId)
        if (uiStore.showVerboseLogging.loadPod) console.log(`Applying ${pendingOps?.length} pending OPs`)
        if (pendingOps) pendingOps.forEach((op:Op) => {
          opStore.execute(pod, op)
        })

        this.setPod(pod)
        return true
      }
    }
    else {
      console.error(`Could not load pod: nothing returned`)
      alertStore.push(alert(('Could not load pod: Are you connected to the internet?'), 'error'))
      this.setPodStatus(pod, 'broken' as PodLoadState)
      return false
    }

    if (pod.status !== 'initialized') {
      console.warn(`Error condition: This should not happen! Pod status === ${pod.status}`)
      this.setPodStatus(pod, 'broken' as PodLoadState, `Error condition: This should not happen! Pod status === ${pod.status}`)
      //this.unsetPod()
      return false
    }

    if (pod.lastSyncOid as number === pod.initMaxCoid as number) {
      if (pod.initMaxCoid as Number === 0) {
        console.log('Pod appears to be empty (and is thus loaded)')
        this.setPodStatus(pod, 'loaded' as PodLoadState)
        this.setPod(pod)
        return true
      }
      else {
        console.warn(`Error condition: This should not happen! (lastSyncOid === initMaxCoid)`)
        this.setPodStatus(pod, 'broken' as PodLoadState, `Error condition: This should not happen! (lastSyncOid === initMaxCoid)`)
        //this.unsetPod()
        return false  
      }
    }

    if (uiStore.showVerboseLogging.loadPod) console.log(`Pod was initialized but is incomplete: ${pod.lastSyncOid} !== ${pod.initMaxCoid} ||  ${pod.status} !== 'loaded' --> continue with chunked loading` )

    // Pod ist nur ein initPod: perform load
    this.setPodStatus(pod, 'loading' as PodLoadState)
    pod.setLoadStatus(0)
    let completedOpsCounter = 0

    this.setPod(pod)

    try {

      do {

        if (uiStore.showVerboseLogging.loadPod) console.log('loading chunk ' + pod.lastSyncOid)
        const chunk = await api.loadPodChunk(podId, pod.lastSyncOid, pod.initMaxCoid)

        if (chunk) {
          const { ops, totalOps } = chunk

          ops.forEach((op:any) => {
            opStore.execute(pod, op)
            pod.lastSyncOid = op.data.coid
            if (op.oid === pod.initMaxCoid) {
              this.setPodStatus(pod, 'loaded' as PodLoadState)
            }
          })

          completedOpsCounter += ops.length
          pod.setLoadStatus(totalOps ? Math.floor(100 * completedOpsCounter / totalOps) : 0)

          if (this.pod?.podId === pod.podId) this.setPod(pod)
        }

      } while(pod.status !== 'loaded' as PodLoadState)

      // Get unsynced OPs from serviceWorker and apply
      // (If they were created based on an older version of the pod, they will still get applied
      // based on this new version in the backend, so we should mimick this behavior here)
      const pendingOps = await api.getPendingOps(podId)
      if (pendingOps) {
        if (uiStore.showVerboseLogging.loadPod) console.log(`Applying ${pendingOps.length} pending OPs`)
        pendingOps.forEach((op:Op) => {
          // pod.applyOp(op) // 
          opStore.execute(pod, op)
        })
      }
    } catch(e) {
      console.error(`Error condition in fetch: `, e)
      this.setPodStatus(pod, 'broken' as PodLoadState)
      this.unsetPod()
      return false
    }

    api.getPodActivity(podId, pod.loadtimeMaxOid+1, true)

    if (uiStore.showVerboseLogging.loadPod) console.log(`Finished loading in ${Date.now()-t0}ms`)
    return true
  }

  setPodStatus(pod:PodI, status: PodLoadState, info: string = '') {
    if ((pod.status === 'loading') && (status === 'loaded')) pod.setLastSyncOid(pod.loadtimeMaxOid)
    pod.setStatus(status)
    this.podCondition[pod.podId] = {
      status: status,
      info,
    }
  }

  unsetPod() {
    this.pod = null
  }

  setPod(pod: PodI, info: string = '') {
    if (pod && pod.podId) {
      this.pod = pod
      if (pod.status) this.podCondition[pod.podId] = {
        status: pod.status,
        info,
      }
    }
  }

  getUsergroupByRole(role:string) {
    if (this.pod) return this.pod.getUsergroupByRole(role)
    throw(new Error('eee'))
  }

  getAnnotations(fileId: string, page: number | null = null) {
    const fileAnnotations = this.pod?.getAnnotations(fileId)
    if(!fileAnnotations) {
      console.error(`getAnnotations() could not find file ${fileId}`)
      return null
    }

    if (fileAnnotations && fileAnnotations.length) {
      if (page === null) return fileAnnotations
      return fileAnnotations.filter((annotation: iAnnotation) => {
        return annotation.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getNotes(fileId: string, page: number | null = null) {
    const fileAnnotations = this.pod?.getAnnotations(fileId)
    if(!fileAnnotations) {
      console.error(`getAnnotations() could not find file ${fileId}`)
      return null
    }

    if (fileAnnotations && fileAnnotations.length) {
      if (page === null) {
        return fileAnnotations.filter((annotation: iAnnotation) => {
          if(annotation.label) return true
          return false
        })
      }

      return fileAnnotations.filter((annotation: iAnnotation) => {
        return annotation.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page && annotation.label) return true; else return false
        }).length
      })
    }
    return []
  }

  getComments(fileId: string, page: number | null = null) {
    const fileComments = this.pod?.getComments(fileId)
    if(!fileComments) {
      console.error(`getComments() could not find file ${fileId}`)
      return null
    }

    // add fallback color
    const commentColor = uiStore.getInteractionColor("comment")
    fileComments.forEach(comment => {
      if(!comment.style.color) comment.style.color = commentColor
    })

    if (fileComments && fileComments.length) {
      if (page === null) return fileComments
      return fileComments.filter((comment: iComment) => {
        return comment.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getThread(interactionId: string) {
    const threads = this.pod?.content?.threads
    if(threads) {
      for(let id in threads) {
        const thread = threads[id]
        if(thread.interactionId === interactionId) return thread
      }
    }
    return null
  }

  getConversations(userId: number) {
    if(!this.pod || !userId) return null
    // load threads in which the user is involved
    const threads = this.pod.content?.threads
    const conversations: ConversationItem[] = []
    const conversationIds: string[] = []
    if(threads) {
      for(let id in threads) {
        const thread = threads[id]
        const baseInteraction = this.pod.getInteractionFromThreadId(thread.threadId)
        // if there is no baseInteraction thread was probably deleted
        if(baseInteraction) {
          if(baseInteraction.userId === userId) {
            const conversationItem = this.createConversationItem(thread, baseInteraction)
            if(conversationItem && !conversationIds.includes(conversationItem.interactionId)) {
              conversations.push(conversationItem)
              conversationIds.push(conversationItem.interactionId)
            }
          }
          else {
            for(let message of thread.messages) {
              if(message.userId === userId) {
                const conversationItem = this.createConversationItem(thread, baseInteraction)
                if(conversationItem && !conversationIds.includes(conversationItem.interactionId)) {
                  conversations.push(conversationItem)
                  conversationIds.push(conversationItem.interactionId)
                }
                break
              }
            }
          }
        }
      }
    }
    return conversations
  }

  createConversationItem(thread: Thread, baseInteraction: Interaction) {
    if(!this.pod) return null
    // create item for list of chat conversations
    const threadId = thread.threadId
    const messages = thread.messages
    const interactionId = thread.interactionId
    const userId = baseInteraction.userId
    const userName = baseInteraction.userName ? baseInteraction.userName : ""
    const label = baseInteraction.label
    const nodeId = baseInteraction.anchor.nodeId
    let tLastMessage = null
    const replies = messages.length
    const involvedUsers: number[] = []
    // get involved users
    if(messages.length) {
      // go from the last to the first message
      for (let index = (messages.length-1); index >= 0; index--) {
        const message = messages[index]
        if(!involvedUsers.includes(message.userId)) {
          involvedUsers.unshift(message.userId)
        }
        // take tCreate from last message
        if(tLastMessage === null) tLastMessage = message.tCreated
      }
      // consider user from base interaction
      if(!involvedUsers.includes(baseInteraction.userId)) {
        involvedUsers.unshift(baseInteraction.userId)
      }
    } else {
      return null
    }
    // build conversation item
    return ({
      involvedUsers: involvedUsers,
      interactionId: interactionId,
      label: label,
      nodeId: nodeId,
      replies: replies,
      threadId: threadId,
      tLastMessage: tLastMessage ? tLastMessage : baseInteraction.tCreated,
      userId: userId,
      userName: userName
    })
  }

  getInteraction(fileId: string, interactionId: string) {
    const content = this.pod?.content?.pdfFiles[fileId]
    if(content) {
      const annotation = content.annotations[interactionId]
      if(annotation) return annotation
      const comment = content.comments[interactionId]
      if(comment) return comment
      const link = content.links[interactionId]
      if(link) return link
      const tag = content.taggings[interactionId]
      if(tag) return tag
      const weblink = content.weblinks[interactionId]
      if(weblink) return weblink
      const emotion = content.emotions[interactionId]
      if(emotion) return emotion
      console.warn("getInteraction: could not find any interaction matching the id", interactionId)
    }
    return null
  }

  getLinks(fileId: string, page: number | null = null) {
    const fileLinks = this.pod?.getLinks(fileId)
    if(!fileLinks) {
      console.error(`getLinks() could not find file ${fileId}`)
      return null
    }

    // add fallback color
    const linkColor = uiStore.getInteractionColor("link")
    fileLinks.forEach(link => {
      if(!link.style.color) link.style.color = linkColor
    })

    if (fileLinks && fileLinks.length) {
      if (page === null) return fileLinks
      return fileLinks.filter((link: iLink) => {
        return link.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getLink(fileId: string | undefined | null, interactionId: string | undefined | null) {
    if(fileId && interactionId ) {
      const file = this.pod?.content?.pdfFiles[fileId]
      if(file) {
        const interactionLink = file.links[interactionId]
        // get link object with source and destination
        if(interactionLink) {
          const link = this.pod?.content.links[interactionLink.linkId]
          // console.log(`Found link ${interactionLink.linkId}`, JSON.stringify(link))
          if(link) return {
            ...link,
            src: this.pod?.getInteraction(link?.src),
            dst: this.pod?.getInteraction(link?.dst),
          } as fullLink
        }
      }
    }
    // console.warn(`Could not find link-interaction ${interactionId} in file ${fileId}`)
    return null
  }

  getWeblinks(fileId: string, page: number | null = null) {
    const fileWeblinks = this.pod?.getWeblinks(fileId)
    if(!fileWeblinks) {
      console.error(`getComments() could not find file ${fileId}`)
      return null
    }

    // add fallback color
    const weblinkColor = uiStore.getInteractionColor("weblink")
    fileWeblinks.forEach(weblink => {
      if(!weblink.style.color) weblink.style.color = weblinkColor
    })

    if (fileWeblinks && fileWeblinks.length) {
      if (page === null) return fileWeblinks
      return fileWeblinks.filter((link: iWeblink) => {
        return link.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getEmotions(fileId: string, page: number | null = null) {
    const fileEmotions = this.pod?.getEmotions(fileId)
    if(!fileEmotions) {
      console.error(`getEmotions() could not find file ${fileId}`)
      return null
    }

    // add fallback color
    const emotiontColor = uiStore.getInteractionColor("emotion")
    fileEmotions.forEach(emotion => {
      if(!emotion.style.color) emotion.style.color = emotiontColor
    })

    if (fileEmotions && fileEmotions.length) {
      if (page === null) return fileEmotions
      return fileEmotions.filter((emotion: iEmotion) => {
        return emotion.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getTags(fileId: string, page: number | null = null) {
    const fileTags = this.pod?.getTags(fileId)
    if(!fileTags) {
      console.error(`getTags() could not find file ${fileId}`)
      return null
    }

    // add fallback color
    const tagColor = uiStore.getInteractionColor("tagging")
    fileTags.forEach(tag => {
      if(!tag.style.color) tag.style.color = tagColor
    })

    if (fileTags && fileTags.length) {
      if (page === null) return fileTags
      return fileTags.filter((interaction: Interaction) => {
        return interaction.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getTagProp(tagId: string) {
    const tagProp = this.pod?.content.tags[tagId]
    if(tagProp) return tagProp
    return null
  }

  getTagPool() {
    const tags = this.pod?.content.tags
    const tagPool = []
    if(tags) {
      for(let id in tags) {
        const tag = tags[id]
        if(tag.name) tagPool.push(tag)
      }
    }
    return tagPool
  }

  getInteractionByCoordinates(pdfId: string, x: number, y: number, page: number) {
    const list: {type: InteractionType, clickedRect: Rect, interaction: Interaction}[] = []

    // create a list of interactions that have a rectangle inside the click position
    const annotations: iAnnotation[] | null = this.getAnnotations(pdfId, page)
    if(annotations) {
      for(const annotation of annotations) {
        for(const rect of annotation.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) && rect.p === page ) {
            list.push({type: "annotation", "clickedRect": rect, "interaction": annotation})
          }
        }
      }
    }
    const comments: iComment[] | null = this.getComments(pdfId, page)
    if(comments) {
      for(const comment of comments) {
        for(const rect of comment.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) && rect.p === page  ) {
            list.push({type: "comment", "clickedRect": rect, "interaction": comment})
          }
        }
      }
    }
    const links: iLink[] | null = this.getLinks(pdfId, page)
    if(links) {
      for(const link of links) {
        for(const rect of link.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) && rect.p === page  ) {
            list.push({type: "link", "clickedRect": rect, "interaction": link})
          }
        }
      }
    }
    const tags: iTag[] | null = this.getTags(pdfId, page)
    if(tags) {
      for(const tagging of tags) {
        for(const rect of tagging.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) && rect.p === page  ) {
            list.push({type: "tagging", "clickedRect": rect, "interaction": tagging})
          }
        }
      }
    }
    const weblinks: iWeblink[] | null = this.getWeblinks(pdfId, page)
    if(weblinks) {
      for(const weblink of weblinks) {
        for(const rect of weblink.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) && rect.p === page  ) {
            list.push({type: "weblink", "clickedRect": rect, "interaction": weblink})
          }
        }
      }
    }
    const emtions: iEmotion[] | null = this.getEmotions(pdfId, page)
    if(emtions) {
      for(const emotion of emtions) {
        for(const rect of emotion.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) && rect.p === page  ) {
            list.push({type: "emotion", "clickedRect": rect, "interaction": emotion})
          }
        }
      }
    }

    // if an interaction has a rectangle at the click position, return it
    if(list.length === 1) return list[0]
    // if multiple interactions have a rectangle at the click position, choose the rectangle with the smallest area
    if(list.length > 1) {
      let smallestArea: number | null = null
      let smallesAreaIndex: number = 0
      list.forEach((item, index) => {
        const rect = item.clickedRect
        const area = rect.w*rect.h
        if(smallestArea === null) {
          smallestArea = area
          smallesAreaIndex = index
        }
        if(smallestArea && area < smallestArea) {
          smallestArea = area
          smallesAreaIndex = index
        }
      })
      return list[smallesAreaIndex]
    }
    // there is no interaction at the click position
    return null
  }


  setActiveLinkEditId(linkEditId: string | null) {
    this.activeLinkEditId = linkEditId
  }

  getLinkLabel(linkId: string, linkLabel: string | null) {
    // if label does not exist yet, initialize it
    if(this.linkLabel[linkId] === undefined) {
      const label = linkLabel ? linkLabel : ""
      this.linkLabel[linkId] = label
      return label
    }
    return this.linkLabel[linkId]
  }

  setLinkLabel(linkId: string, text: string) {
    this.linkLabel[linkId] = text
  }

  deleteLinkLabel(linkId: string) {
    if(this.linkLabel[linkId] || this.linkLabel[linkId] === "") delete this.linkLabel[linkId]
  }

  getLinkFile(linkId: string, fileId: string | null) {
    // if label does not exist yet, initialize it
    if(this.linkFile[linkId] === undefined && fileId) {
      this.linkFile[linkId] = fileId
      return fileId
    }
    return this.linkFile[linkId]
  }

  setLinkFile(linkId: string, fileId: string) {
    this.linkFile[linkId] = fileId
  }

  deleteLinkFile(linkId: string) {
    if(this.linkFile[linkId]) delete this.linkFile[linkId]
  }

  getLinkOverlay(linkInteractionId: string, anchor: interactionAnchor | null) {
    // if label does not exist yet, initialize it
    if(this.linkOverlay[linkInteractionId] === undefined && anchor) {
      this.linkOverlay[linkInteractionId] = anchor
      return anchor
    }
    return this.linkOverlay[linkInteractionId]
  }

  setLinkOverlay(linkInteractionId: string, anchor: interactionAnchor) {
    this.linkOverlay[linkInteractionId] = anchor
  }

  deleteLinkOverlay(linkId: string) {
    if(this.linkOverlay[linkId]) delete this.linkOverlay[linkId]
  }

  getFilename(nodeId: string) {
    const pdfFiles = this.pod?.getPdfFiles()
    if(pdfFiles) {
      for(const file of pdfFiles) {
        if(file.nodeId === nodeId) return file.name
      }
    }
    console.warn(`getFilename: no filename with id ${nodeId} found`)
    return null
  }

  getPodActivity(podId:string) {
    if (this.podActivity.length && this.podActivity[0].podId === podId) return this.podActivity
    return []
  }

  setPodActivity(ops: Op[]) {
    this.podActivity = ops
  }

  addToPodActivity(podId:string, op: Op) {
    if ((this.podActivity.length === 0) || this.podActivity[0].podId !== op.podId) return

    // only backend-saved ops can be part of the podActivity log
    if (!op.oid) return

    // only certain ops make it to the activity log
    if (activityOps.indexOf(op.op) === -1) return

    // only new ops will be added to the activity log
    if (this.podActivity.findIndex((o) => o.oid === op.oid) === -1) {
      this.podActivity.unshift(op)
      this.podActivity.sort((a, b) => ((b.oid || 0) - (a.oid || 0)))
      this.podActivity.length = Math.min(this.podActivity.length, 100);
    }
  }

  getUserInfo(userId: number | undefined | null, podId: string | undefined | null) {
    let userInfo: UserInfo | null = null
    if(userId && this.pod && this.pod.userInfos) {
      const user = this.pod.userInfos[userId]
      if(user && user.userName) {
        userInfo = {
          userId: userId,
          userName: user.userName,
          color: user.color
        }
      }
    }
    // currently not in a pod, but clear pod state
    if(userId && this.pod === null && podId) {
      sessionStore.session.pods.forEach((sessionPod: Pod) => {
        if(sessionPod.podId === podId) {
          userInfo = sessionPod.userInfos[userId]
        }
      })
    }
    // currently not in pod, ambiguous state of pod
    if(userId && this.pod === null && !podId) {
      const userName = sessionStore.session.user.idpProvidedUserName
      let color: string[] = []
      sessionStore.session.pods.forEach((sessionPod: Pod) => {
        const sessionUserColor = sessionPod.userInfos[userId].color
        if(sessionUserColor) color.push(sessionUserColor)
      })
      userInfo = {
        userId: userId,
        userName: userName,
        color: "grey"
      }
    }
    return userInfo
  }

  setOutOfSync(status:boolean) {
    if (this.pod) this.pod.outOfSync = status
  }

}


const exportPodStore = new podStore()
export default exportPodStore
