import React from 'react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../contexts/store'

import { useTranslation } from 'react-i18next'


import { AppBar, Box, Toolbar } from '@mui/material';

import HomeIcon from '@mui/icons-material/Home'
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import { blueGrey } from '@mui/material/colors';

import HeaderMenuButton from './Header/HeaderMenuButton';
import DevSyncStatus from './Dev/DevSyncStatus';
import SyncStatus from './Header/SyncStatus';
import AccountButton from './Header/AccountButton';
import { useParams } from '../stores/uiStore';
import { env } from '../api/api';


const Header: React.FC = () => {
  const { podId, pdfId } = useParams()
  const { uiStore, podStore } = useStore()
  const { t } = useTranslation()

  const homeButton = <HeaderMenuButton key="home" to="/" title={t('View all your Pods')} icon={<HomeIcon sx={{fontSize:"1.7rem", color: (podId ? "#ffffff50" : "#fff" )}} />} description="home" />
  const podButton  = <HeaderMenuButton key="pod" to={`/pod/${podId}/${uiStore.getActivePodTab(podId)}`} title={podStore.pod?.name || ''} icon={<FolderSpecialIcon sx={{fontSize:"1.6rem", color: (pdfId ? "#ffffff50" : "#fff" )}} />} description="pod" />
  const docButton  = <HeaderMenuButton key="doc" to={`/pod/${podId}/pdf/${pdfId}`} title={podStore.pod?.content.pdfFiles[pdfId]?.name || ''} icon={<TextSnippetIcon sx={{fontSize:"1.6rem", cursor: "default"}} />} description="file" />

  var homePodDocCrumb = [homeButton]
  if (podId) homePodDocCrumb.push(podButton)
  if (pdfId) homePodDocCrumb.push(docButton)

  if (uiStore.deviceWidth === 'tiny') {
    const secondToLast = homePodDocCrumb.slice(-2).shift() as JSX.Element
    if (secondToLast) homePodDocCrumb = [secondToLast]
  }

  return <>
    <Box sx={{ flexGrow: 1 }} id="app-header">
      <AppBar position="static" sx={{ bgcolor: blueGrey[900], boxShadow: "none" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, justifiyContent: "flex-start", marginLeft: "-8px" }}>
            {homePodDocCrumb}
          </Box>
            {env.dev ? <DevSyncStatus /> : null }
          <HeaderMenuButton title='Notifications' icon={<NotificationsIcon sx={{fontSize:"1.7rem"}} />} description="notification" />
          <SyncStatus />
          <AccountButton />
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

export default observer(Header)