import React from "react"
import { styled } from '@mui/system'
import { observer } from "mobx-react-lite"
import { Box, ToggleButton } from "@mui/material"
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ColorSelector from "./Toolbar/ColorSelector"
import InteractionSelector from "./Toolbar/InteractionSelector"
import ZoomSlider from "./Toolbar/ZoomSlider"
import { useStore } from "../../contexts/store"
import { useTranslation } from 'react-i18next'


const Toolbar = ({
    pageListRef,
    storeId
  }: {
    pageListRef: any,
    storeId: string
  }) => {

  const { uiStore } = useStore()
  const { t } = useTranslation()

  return (
    <ToolbarContainerStyle>
      {/* <ScaleSelector
        pageListRef={pageListRef}
        storeId={storeId}
      /> */}
      <ColorSelector />
      <InteractionSelector />
      <ZoomSlider storeId={storeId} />
      <Box sx={{ mt:2, width: "55px", p: "3px" }} title={t('Click to select and copy text from the PDF')}>
        <ToggleButton value={uiStore.copyMode} onClick={() => { uiStore.setCopyMode(!uiStore.copyMode) }} aria-label="copy" style={{backgroundColor: (uiStore.copyMode ? "red":"whitesmoke"), color: (uiStore.copyMode ? "white":"black")}}>
          <ContentCopyIcon />
        </ToggleButton>
      </Box>
    </ToolbarContainerStyle>
  )
}

export default observer(Toolbar)


// style components -------------------------------------------------

const ToolbarContainerStyle = styled('div')({
  alignContent: "center",
  backgroundColor: "#6c6caf",
  display: "grid",
  justifyContent: "center",
  overflowY: "scroll"
})