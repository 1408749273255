import React from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'wouter'
import { useStore } from '../../contexts/store'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import UserInfoAvatar from '../Elements/UserInfoAvatar'

const Account: React.FC = () => {
  const { t } = useTranslation()
  const { sessionStore } = useStore()
  const user = sessionStore.session.user

  return <Box sx={{ p: 5 }}>
      <h1>{t('Account Information')}</h1>
      <table>
        <tbody>
          <tr>
            <td>{t('Your official name (idpProvidedUserName)')}: </td>
            <td colSpan={2}>{user.idpProvidedUserName}</td>
          </tr>
          <tr>
            <td colSpan={3}><br />{t('Pseudonyms')}:</td>
          </tr>
          { sessionStore.session.pods.map(pod => {
            return <tr key={pod.podId}>
              <td>
                <Link to={`/pod/${pod.podId}`}>{pod.name}</Link>
              </td>
              <td width="32">
                <UserInfoAvatar variant='avatar' userId={user.userId} podId={pod.podId} />
              </td>
              <td>
                {(pod.userInfos && pod.userInfos[user.userId]) ? pod.userInfos[user.userId].userName : <em>not loaded</em>}
              </td>
            </tr>
          }) }
        </tbody>
      </table>
    </Box>
}

export default observer(Account)