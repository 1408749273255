import { useTranslation } from 'react-i18next'
import { ChangeEvent, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import TextField from './TextField'

const TextArea = (
  props: {
    handleClose: () => void
    placeholder: string,
    showHints: boolean,
    submit: (input: string) => void,
    value: string,
    disabled: boolean,
  }) => {

  const { handleClose, placeholder, showHints, submit, value, disabled } = props
  const { t } = useTranslation()
  const [input, setInput] = useState("")

  useEffect(() => {
    // if a value was given, pass it to the input field
    if(value) {
      setInput(value)
    }
  }, [value])

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setInput(e.target.value)
  }

  function doSubmit() {
    if(input !== "") {
      submit(input)
      setInput("")
    }
  }

  return (
    <Box sx={{display: "grid", gridTemplateColumns: "auto", gridTemplateRows: "min-content min-content", padding: "0 20px 20px 20px"}}>
      <TextField
        handleInputChange={handleInputChange}
        handleClose={handleClose}
        placeholder={placeholder}
        submit={doSubmit}
        value={input}
        disabled={disabled}
      />
      <Box sx={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: "10px",
        }}
      >
        <Button sx={{m: "5px 10px 5px 0"}} onClick={doSubmit} variant="contained">
          {t('Save')}
        </Button>
        <Button sx={{m: "5px 0"}} onClick={() => handleClose()} variant="outlined">
          {t('Cancel')}
        </Button>
        {showHints &&
          <Box sx={{alignSelf: "center", pl: "15px", fontSize: "11px", color: "grey", fontStyle: "oblique"}}>
            <b>{t('Shift')}</b> + <b>{t('Enter')}</b> {t('for linebreaks')},<br /><b>Esc</b>  {t('to cancel')}
          </Box>
        }
      </Box>
    </Box>
  )
}

export default TextArea
