import { useLayoutEffect } from "react"
import { observer } from "mobx-react-lite"
import { PodClass } from "../../classes/Pod"
import { Box, Paper } from "@mui/material"
import { iAnnotation } from "../../../../types/Interaction"
import { Link } from "wouter"

const Notes = ({pod, initScrollPos}: {pod: PodClass, initScrollPos: (tab: string) => void}) => {

  useLayoutEffect(() => {
    initScrollPos("notes")
  })

  const notes: JSX.Element[] = []
  const pdfFiles = []
  for (let key in pod.content.pdfFiles) {
      if (pod.content.pdfFiles.hasOwnProperty(key)) pdfFiles.push(key)
  }

  const Annotation = ({annotation, fileId}: {annotation: iAnnotation, fileId: string}) => (
    <Link to={`/pod/${pod.podId}/pdf/${fileId}/annotation/${annotation.interactionId}`} className="remove-link-decoration">
      <Paper elevation={3} sx={{display: "grid", m: "20px", "&:hover": {backgroundColor: "whitesmoke"} }}>
        <Box sx={{borderLeft: `7px ${annotation.style.color} solid`, m: "20px", p: "10px"}}>
          {annotation.anchor.relText}
        </Box>
        <Box sx={{p: "5px 20px 20px 20px", mb: "10px"}}>
          {annotation.label}
        </Box>
      </Paper>
    </Link>
  )

  for (const fileId of pdfFiles) {
    const annotations = pod.getAnnotations(fileId)
    annotations?.forEach(annotation => {
      if(annotation.label !== "") {
        notes.push(
          <li key={annotation.interactionId} style={{listStyle: "none"}}>
            <Annotation
              annotation={annotation}
              fileId={fileId}
            />
          </li>
        )
      }
    })
  }

  return (
    <Box>
      {notes}
    </Box>
  )
}

export default observer(Notes)