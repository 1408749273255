import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { Op } from "../../../../../types/Ops"
import { useStore } from "../../../contexts/store"
import { Link } from "wouter"

import UserInfoAvatar from "../../Elements/UserInfoAvatar"
import { iComment, iEmotion, iLink, iTag, iWeblink } from "../../../../../types/Interaction"
import { Message } from "../../../../../types/Message"
import TimeFromNow from "../../Elements/TimeFromNow"
import { Box, Link as MuiLink } from "@mui/material"

// message from thread
const Operation = ({op, userChanged}: {op: Op, userChanged?: boolean}) => {
  const { t } = useTranslation()
  const { podStore } = useStore()
  const pod = podStore.pod

  if(!op) {
    console.warn("missing op in Operation")
    return null
  }

  if (!pod) {
    console.warn("no pod loaded")
    return null
  }
  const podId = pod.podId
  if (op.podId !== podId) {
    console.warn(`invalid podId ${op.podId} in operation when pod loaded is ${podId}`)
    return null
  }
  // get user object
  const userInfo = podStore.getUserInfo(op.data?.userId, podId)

  // visible content from interactions
  const AddComment = (comment: iComment) => (comment.label)
  const AddMessage = (message: Message) => (message.text)
  const AddLink = (link: iLink) => (link.label)
  const AddWeblink = (weblink: iWeblink) => (weblink.label)
  const AddEmotion = (emotion: iEmotion) => (emotion.label)
  const AddTagging = (tag: iTag) => {
    const tagProp = podStore.getTagProp(tag.tagId)
    return "#"+tagProp?.name
  }

  let content = null
  let description = ""
  let interactionUrl = `/pod/${podId}`
  switch(op.op) {
    case 'addComment':
      content = AddComment(op.data)
      description = t('added a comment')
      interactionUrl += `/pdf/${op.data.anchor.nodeId}/comment/${op.data.interactionId}`
      break
    case 'addMessage':
      content = AddMessage(op.data)
      description = t('replied to a comment')
      // search interaction from thread
      const interaction = pod.getInteractionFromThreadId(op.data.threadId)
      if(interaction) interactionUrl += `/pdf/${interaction.anchor.nodeId}/comment/${interaction.interactionId}`
      break
    case 'addLink':
      content = AddLink(op.data)
      description = t('added a link')
      interactionUrl += `/pdf/${op.data.anchor.nodeId}/link/${op.data.interactionId}`
      break
    case 'addTagging':
      content = AddTagging(op.data)
      description = t('added a tagging')
      interactionUrl += `/pdf/${op.data.anchor.nodeId}/tagging/${op.data.interactionId}`
      break
    case 'addWeblink':
      content = AddWeblink(op.data)
      description = t('added a weblink')
      interactionUrl += `/pdf/${op.data.anchor.nodeId}/weblink/${op.data.interactionId}`
      break
    case 'addEmotion':
      content = AddEmotion(op.data)
      description = t('added an emotion')
      interactionUrl += `/pdf/${op.data.anchor.nodeId}/emotion/${op.data.interactionId}`
      break
    case 'addUserToPod':
      description = t('joined the Pod')
      break
    case 'removeUserFromPod':
      description = t('has left the Pod')
      break
    case 'addPdfFile':
      content = op.data.name
      description = t('added a pdf to the Pod')
      interactionUrl += `/pdf/${op.data.nodeId}`
      break
    default:
      console.warn("unknown op:", op.op)
      return null
  }

  const username = userInfo ? userInfo.userName : t("unknown")
  return (
    <Box sx={{
        borderTop: userChanged ? "1px solid #e2e2e2" : "",
        display: "grid",
        gridTemplateColumns: "auto min-content",
        padding: "5px 0"
      }}>
      <Box sx={{padding: "8px 20px", fontSize: "18px"}}>
        {/* interaction description, Link = wouter, MuiLink = style passed props from wouter */}
        <Link href={interactionUrl}>
          <MuiLink underline="hover">
            <b>{username}</b> {description}
          </MuiLink>
        </Link>
        {/* content from interaction */}
        <Box sx={{color: "#555555"}}>
          {content}
        </Box>
        {/* interaction creation time */}
        <Box sx={{marginTop: "7px", color: "#808080", fontSize: "14px", fontStyle: "italic"}}>
          <TimeFromNow timestamp={op.tCreated ? op.tCreated*1000 : 0} />
        </Box>
      </Box>
      {/* show avatar symbol if user changes */}
      <Box sx={{m: "5px"}}>
        {userChanged && <UserInfoAvatar variant="avatar" userId={userInfo?.userId} podId={pod.podId}  />}
      </Box>
    </Box>
  )
}


export default observer(Operation)
