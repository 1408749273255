import { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../contexts/store'
import { useTranslation } from 'react-i18next'

import InteractionModalComment from './InteractionModalComment'
import InteractionModalTag from './InteractionModalTag'

import Draggable from 'react-draggable'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import Paper, { PaperProps } from '@mui/material/Paper'
import CloseIcon from '@mui/icons-material/Close'
import { OpCode } from '../../../../../types/OpCodes'
import { Interaction, InteractionType, iComment, iEmotion, iTag, iWeblink, interactionAnchor } from '../../../../../types/Interaction'
import { useParams } from '../../../stores/uiStore'
import InteractionIcon from '../../Elements/InteractionIcon'
import InteractionModalWeblink from './interactionModalWeblink'
import InteractionModalEmotion from './InteractionModalEmotion'

function PaperComponent(props: PaperProps) {
  const nodeRef = useRef(null)
  return (
    <Draggable
      cancel={'[class*="MuiDialogContent-root"]'}
      handle="#dragger"
      nodeRef={nodeRef}
    >
      <Paper {...props} ref={nodeRef} />
    </Draggable>
  )
}

const InteractionModal = () => {
  const { t } = useTranslation()
  const { sessionStore, uiStore, podStore } = useStore()
  const { podId, pdfId } = useParams()
  const { session } = sessionStore
  // if isOpen is true, show modal
  const isOpen = uiStore.interactionModal.isOpen
  // if interactionId is available, interaction is edited
  const interactionId = uiStore.interactionModal.interactionId

  let anchor: interactionAnchor | null = null
  let interactionOp: OpCode | null = null
  let color: string | null = null
  let interaction: Interaction | null

  // check if pdf and pod is loaded
  if(pdfId === null || podId === null) return null

  const interactionType = (op: string | null) => {
    if(op === "addAnnotation" || op === "editAnnotation") return "annotation"
    if(op === "addComment" || op === "editComment") return "comment"
    if(op === "addLink" || op === "editLink") return "link"
    if(op === "addTagging" || op === "editTagging") return "tagging"
    if(op === "addWeblink" || op === "editWeblink") return "weblink"
    if(op === "addEmotion" || op === "editEmotion") return "emotion"
    return null
  }

  // use case: create new interaction
  if(interactionId === null) {
    // get rects and its text, selected in pdf viewer
    anchor = uiStore.selectedAnchor
    // get interaction type and overlay color selected in the sidebar
    interactionOp = uiStore.getSelectedInteraction()
    color = uiStore.getInteractionColor(interactionOp)
  }
  // use case: edit interaction
  else {
    interaction = podStore.getInteraction(pdfId, interactionId)
    if(interaction) {
      anchor = interaction.anchor
      color = interaction.style.color ? interaction.style.color : null
      if(interaction.interactionType === "annotation" as InteractionType) interactionOp = "editAnnotation"
      if(interaction.interactionType === "comment" as InteractionType) interactionOp = "editComment"
      if(interaction.interactionType === "link" as InteractionType) interactionOp = "editLink"
      if(interaction.interactionType === "tagging" as InteractionType) interactionOp = "editTagging"
      if(interaction.interactionType === "weblink" as InteractionType) interactionOp = "editWeblink"
      if(interaction.interactionType === "emotion" as InteractionType) interactionOp = "editEmotion"
    }
  }

  if(anchor === null) return null

  const handleClose = () => {
    // close modal
    uiStore.closeInteractionModal()
    // delete selected anchor in store
    uiStore.setSelectedAnchor(null)
  }

  const Header = () => {
    return (
      <Box sx={{
        display: "grid",
        gridGap: "15px",
        gridTemplateColumns: "auto min-content",
        alignItems: "center"
      }}>
        <Box style={{ cursor: 'move' }} id="dragger"sx={{
        display: "grid",
        gridGap: "15px",
        gridTemplateColumns: "min-content auto",
        alignItems: "center"
      }}>
          <InteractionIcon interactionType={interactionType(interactionOp)} />
          {interactionOp === "addAnnotation" && t('Add Annotation')}
          {interactionOp === "addComment" && t('Add Comment')}
          {interactionOp === "addLink" && t('Add Link')}
          {interactionOp === "addTagging" && t('Add Tag')}
          {interactionOp === "addWeblink" && t('Add Weblink')}
          {interactionOp === "addEmotion" && t('Add Emotion')}
          {interactionOp === "editAnnotation" && t('Edit Annotation')}
          {interactionOp === "editComment" && t('Edit Comment')}
          {interactionOp === "editLink" && t('Edit Link')}
          {interactionOp === "editTagging" && t('Edit Tag')}
          {interactionOp === "editWeblink" && t('Edit Weblink')}
          {interactionOp === "editEmotion" && t('Edit Emotion')}
        </Box>
        <IconButton aria-label="settings" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    )
  }

  const InteractionModalContent = () => {
    switch (interactionOp) {
      case "addAnnotation":
      case "editAnnotation":
        return <></>
      case "addComment":
      case "editComment":
        return <InteractionModalComment anchor={anchor} color={color} interaction={interaction as iComment} />
      case "addLink":
      case "editLink":
        return <></>
      case "addTagging":
      case "editTagging":
        return <InteractionModalTag anchor={anchor} color={color} interaction={interaction as iTag} podId={podId} session={session}/>
      case "addWeblink":
      case "editWeblink":
        return <InteractionModalWeblink anchor={anchor} color={color} interaction={interaction as iWeblink} />
      case "addEmotion":
      case "editEmotion":
        return <InteractionModalEmotion anchor={anchor} color={color} interaction={interaction as iEmotion} />
      default:
        console.warn(`${interactionOp} does not match in Editinteraction`)
        return null
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle sx={{backgroundColor: "whitesmoke"}}>
        <Header />
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "whitesmoke"}}>
          {/* selected text associated with the interaction */}
          <Box sx={{display: "grid", gridTemplateColumns: "min-content auto", fontStyle: "italic", margin: "10px 5px 5px 5px"}}>
            <Box sx={{backgroundColor: color, width: "6px", m: "5px"}} />
            <Box sx={{padding: "10px 30px 10px 10px"}}>
              {anchor.relText}
            </Box>
          </Box>
      </DialogContent>
      <DialogActions sx={{display: "grid", gridTemplateColumns: "1fr", justifyContent: "space-evenly", backgroundColor: "whitesmoke", padding: "0 24px"}}>
        <InteractionModalContent />
      </DialogActions>
    </Dialog>
  );
}

export default observer(InteractionModal)
