import { useTranslation } from "react-i18next"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import React from "react"

// message from thread
const Prompt = ({title, info, open, handleClose, onOk }: {title: string, info:string|JSX.Element, open: boolean, handleClose: any, onOk?: any}) => {
  const [ text, setText ] = React.useState<string>('')
  const { t } = useTranslation()
  
  return <Dialog open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {info}
      <TextField fullWidth={true} style={{margin:'8px 0'}} label={title} value={text} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { if (event.target.value.trim()) setText(event.target.value) }} /><br />
    </DialogContent>
    <DialogActions style={{textAlign:'right'}}>
      <Button onClick={handleClose}>{t('Cancel')}</Button>
      <Button variant='contained' onClick={() => { if (onOk) onOk(text); handleClose() }}>{t('OK')}</Button>
    </DialogActions>
  </Dialog>

}

export default Prompt