import React from "react"
import { Box, FormControl, MenuItem, Select } from "@mui/material"
import { useStore } from "../../../contexts/store"
import { observer } from "mobx-react-lite"

const ScaleSelector: React.FC = () => {
  const { uiStore } = useStore()
  const colorSelection = uiStore.getAnnotationColor()
  const selectedInteraction = uiStore.getSelectedInteraction()
  const colorList = uiStore.annotationColors

  // show color selection only if annotation is selected as interaction type
  const hideColorSelection = (selectedInteraction !== "addAnnotation") || (uiStore.copyMode) ? true : false

  const handleScaleChange = (colorSelection: string) => {
    // update component state
    uiStore.setAnnotationColor(colorSelection)
  }

  const colorOptions = colorList.map(color =>
    <MenuItem key={color} value={color} onClick={()=> handleScaleChange(color)}>
      <Box sx={{backgroundColor: color, width: "100%", height: "2em", display: "grid" }}></Box>
    </MenuItem>
  )

  return (
    <Box sx={{ visibility: hideColorSelection ? "hidden" : "visible" }}>
      <FormControl fullWidth sx={{ backgroundColor: "whitesmoke" }}>
        <Select
          value={colorSelection}
        >
          {colorOptions}
        </Select>
      </FormControl>
    </Box>
  )
}

export default observer(ScaleSelector)
