import { useTranslation } from "react-i18next"
import { useStore } from "../../contexts/store"
import { InteractionType, Interaction } from "../../../../types/Interaction"
import EditMenu from "./EditMenu"
import InteractionIcon from "./InteractionIcon"
import { useParams } from "../../stores/uiStore"
import { Box, Divider } from "@mui/material"

const Blockquote = ({
  interaction
}: {
  interaction: Interaction
}) => {
  const { t } = useTranslation()
  const { podId, pdfId } = useParams()
  const { uiStore, sessionStore } = useStore()

  if(interaction === null) return null
  const pageNumber = interaction.anchor.rects.length ? interaction.anchor.rects[0].p : 1
  const color = interaction.style.color ? interaction.style.color : uiStore.getInteractionColor(interaction.interactionType as InteractionType)

  return (
    <Box sx={{
      backgroundColor:  "#e8e8e8",
      border: "2px solid #6c6cd3",
      borderRadius: "5px",
      display: "grid",
      margin: "10px",
      minHeight: "80px",
      padding: "10px"
    }}>
      {/* header */}
      <Box sx={{
        alignItems: "center",
        display: "grid",
        gridGap: "10px",
        gridTemplateColumns: "min-content auto min-content",
        padding: "2px"
      }}>
        <InteractionIcon interactionType={interaction.interactionType} />
        <Box sx={{fontWeight: "bold", alignSelf: "center", fontSize: "13px"}}> {t('Page')} {pageNumber}</Box>
          <EditMenu
            interactionId={interaction.interactionId}
            interactionType={interaction.interactionType}
            pdfId={pdfId}
            podId={podId}
          />
      </Box>
      <Divider />
      {/* selected text associated with the interaction */}
      <Box sx={{display: "grid", gridTemplateColumns: "min-content auto", fontStyle: "italic", margin: "10px 5px 5px 5px"}}>
        <Box sx={{backgroundColor: color, width: "6px", m: "5px"}} />
        <Box sx={{padding: "10px 20px 10px 10px", wordBreak: "break-word"}}>
          {interaction.anchor.relText.length <= 100 ? interaction.anchor.relText: (interaction.anchor.relText.substring(0, 100) + "...")}
        </Box>
      </Box>
    </Box>
  )
}

export default Blockquote