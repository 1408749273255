import { createRef } from "react"
import { useStore } from "../../../contexts/store"
import { useTranslation } from "react-i18next"
import { baseUrl } from "../../../api/api"
import { Box, Button, IconButton } from "@mui/material"
import LinkIcon from '@mui/icons-material/Link'
import CloseIcon from '@mui/icons-material/Close'
import ScaleSelector from "../Toolbar/ScaleSelector"
import { observer } from "mobx-react-lite"
import LinkTextarea from "./EditLinkTextarea"
import FileSelector from "./EditLinkFileSelector"
import EditLinkRelText from "./EditLinkRelText"
import EditLinkViewer from "./EditLinkViewer"
import { useParams } from "../../../stores/uiStore"
import { Op_deleteLink, Op_editLink } from "../../../../../types/Ops"
import exportSessionStore from "../../../stores/sessionStore"
import { navigate } from "wouter/use-location"

const EditLink = () => {
  const { t } = useTranslation()
  const { opStore, podStore, uiStore } = useStore()
  const { podId, pdfId, interactionType, interactionId } = useParams()

  if (interactionType !== 'editLink') return <div>Not called with proper URL</div>
  const linkEditId = interactionId

  const pod = podStore.pod
  if (!pod) return null

  const srcListRef: any = createRef()
  const dstListRef: any = createRef()
  const files = pod.getPdfFiles()

  const link = podStore.getLink(pdfId, linkEditId)

  if(link === null || podId === null || pdfId === null) return null

  const linkColor = link?.src.style.color ? link?.src.style.color : uiStore.getInteractionColor("link")
  const interactionUrl = `/pod/${podId}/pdf/${pdfId}/link/${linkEditId}`
  const pdfUrl = `/pod/${podId}/pdf/${pdfId}`

  const srcPdfId = link.src.anchor.nodeId
  const srcLinkAnchor = link.src.anchor
  const srcInteractionId = link.src.interactionId

  const dstPdfId = link.dst.anchor.nodeId
  const dstLinkAnchor = link.dst.anchor
  const dstInteractionId = link.dst.interactionId

  function handleSave() {
    const srcLabel = podStore.getLinkLabel(srcInteractionId, null)
    const srcAnchor = podStore.getLinkOverlay(srcInteractionId, null)
    const dstLabel = podStore.getLinkLabel(dstInteractionId, null)
    const dstAnchor = podStore.getLinkOverlay(dstInteractionId, null)
    // prevent saving links without rects
    if(srcAnchor.rects.length === 0 || dstAnchor.rects.length === 0) {
      alert(t('To save the link, both a link destination and a starting point must be specified'))
    } else {
      // save source link edits
      if(podId && srcInteractionId) {
        const op:Op_editLink = {
          op: "editLink",
          podId: podId,
          data: {
            interactionId: srcInteractionId,
            usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
            userId: exportSessionStore.session.user.userId,
            userName: podStore.userPseudonym || '',
            mods: {
              anchor: JSON.parse(JSON.stringify(srcAnchor)),
              label: srcLabel
            },
          }
        }
        opStore.doOp(op)
      }
      // save destination link edits
      if(podId && dstInteractionId) {
        const op:Op_editLink = {
          op: "editLink",
          podId: podId,
          data: {
            interactionId: dstInteractionId,
            usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
            userId: exportSessionStore.session.user.userId,
            userName: podStore.userPseudonym || '',
            mods: {
              anchor: JSON.parse(JSON.stringify(dstAnchor)),
              label: dstLabel,
            }
          }
        }
        opStore.doOp(op)
      }
      // consider change of nodeId (pdf document) after edit link
      if(srcInteractionId === linkEditId) {
        const srcUrl = `/pod/${podId}/pdf/${srcAnchor.nodeId}/link/${linkEditId}`
        navigate(srcUrl)
      }
      else if(dstInteractionId === linkEditId && dstPdfId !== dstAnchor.nodeId) {
        const dstUrl = `/pod/${podId}/pdf/${dstAnchor.nodeId}/link/${linkEditId}`
        navigate(dstUrl)
      }
      else handleClose(interactionUrl)
    }
  }

  function handleCancel() {
    if(srcLinkAnchor.rects.length === 0 || dstLinkAnchor.rects.length === 0) {
      const warningText = t('The link is not yet complete and will be deleted when you leave, are you sure?')
      if (window.confirm(warningText)) {
        const op:Op_deleteLink = {
          'op': "deleteLink",
          podId,
          data: {
            usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
            interactionId: interactionId,
            userId: exportSessionStore.session.user.userId,
            userName: podStore.userPseudonym || '',
          }
        }
        opStore.doOp(op)
        handleClose(pdfUrl)
      }
    } else {
      handleClose(interactionUrl)
    }
  }

  function handleClose(url : string) {
    // delete temporary edits
    deleteEdits()
    // redirect to link interaction or pdf
    navigate(url)
  }

  function deleteEdits() {
    // reset edits for source link
    podStore.deleteLinkLabel(srcInteractionId)
    podStore.deleteLinkOverlay(srcInteractionId)
    podStore.deleteLinkFile(srcInteractionId)
    // reset edits for destination link
    podStore.deleteLinkLabel(dstInteractionId)
    podStore.deleteLinkOverlay(dstInteractionId)
    podStore.deleteLinkFile(dstInteractionId)
  }

  return (
    <Box sx={{display: "grid", gridTemplateRows: "min-content auto min-content"}}>
      <Box sx={{
        borderBottom: "1px solid lightgrey",
        display: "grid",
        gridTemplateColumns: "1fr 1fr"
      }}>
        {/* header left  */}
        <Box sx={{display: "grid", gridTemplateColumns: "min-content auto min-content", alignItems: "center", borderLeft: `15px solid ${linkColor}`, p: "5px 5px"}}>
          <LinkIcon />
          <FileSelector
            files={files}
            link={link.src}
          />
          <Box sx={{display: "grid", gridTemplateColumns: "min-content", justifyContent: "center"}}>
            <ScaleSelector pageListRef={srcListRef} storeId={srcInteractionId} />
          </Box>
        </Box>
        {/* header right  */}
        <Box sx={{display: "grid", gridTemplateColumns: "min-content auto min-content", alignItems: "center", p: "0 5px", borderLeft: "1px solid lightgrey"}}>
          <Box sx={{display: "grid", gridTemplateColumns: "min-content", justifyContent: "center"}}>
            <ScaleSelector pageListRef={dstListRef} storeId={dstInteractionId} />
          </Box>
          <FileSelector
            files={files}
            link={link.dst}
          />
          <IconButton aria-label="cancel" sx={{marginRight: "10px"}} onClick={handleCancel} >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
        {/* pdf viewer left */}
        <EditLinkViewer
          baseUrl={baseUrl}
          interactionId={srcInteractionId}
          pageListRef={srcListRef}
          pdfId={srcPdfId}
          podId={podId}
        />
        {/* pdf viewer right */}
        <EditLinkViewer
          baseUrl={baseUrl}
          interactionId={dstInteractionId}
          pageListRef={dstListRef}
          pdfId={dstPdfId}
          podId={podId}
        />
      </Box>
      {/* footer */}
      <Box sx={{display: "grid", gridTemplateColumns: "1fr 1fr", backgroundColor:"ghostwhite"}}>
        {/* Content footer left */}
        <Box sx={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
          <EditLinkRelText
            anchor={srcLinkAnchor}
            borderColor={linkColor}
            labelText={t('Source Link')}
            linkInteractionId={srcInteractionId}
          />
          <LinkTextarea
            link={link.src}
          />
        </Box>
        {/* Content footer right */}
        <Box sx={{display: "grid", gridTemplateColumns: "1fr 1fr", borderLeft: "1px solid #afafaf"}}>
          <EditLinkRelText
            anchor={dstLinkAnchor}
            borderColor={linkColor}
            labelText={t('Destination Link')}
            linkInteractionId={dstInteractionId}
          />
          <Box sx={{display: "grid", gridTemplateRows: "auto min-content"}}>
            <LinkTextarea
              link={link.dst}
            />
            {/* footer buttons */}
            <Box sx={{justifySelf: "end", p: "0 15px 15px 0", gridGap: "10px", display: "grid", gridTemplateColumns: "min-content min-content"}}>
              <Button onClick={handleCancel} size="large" sx={{backgroundColor: "white"}} variant="outlined">{t('Cancel')}</Button>
              <Button onClick={handleSave} size="large" variant="contained">{t('Save')}</Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default observer(EditLink)