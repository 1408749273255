import { t } from "i18next"
import { observer } from "mobx-react-lite"
import { navigate } from "wouter/use-location"
import { useStore } from "../../../contexts/store"
import Thread from "../../Elements/Thread"
import { ConversationItem } from "../../../../../types/Miscs"

import { Box, Button, IconButton } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import ConversationList from "./ConversationList"

const ActiveConversation = ({conversationList, podId}: {conversationList: ConversationItem[], podId: string}) => {
  const { uiStore } = useStore()
  const activeConversationId = uiStore.getActiveConversation(podId)
  const activeConversation = activeConversationId ? conversationList.find(item => item.interactionId === activeConversationId) : null
  const interactionId = activeConversation  ? activeConversation.interactionId : null
  const nodeId = activeConversation ? activeConversation.nodeId : null

  const changeActiveConversationId = (interactionId: string | null) => {
    uiStore.setActiveConversation(podId, interactionId)
  }

  const navigateToInteraction = (nodeId: string | null, interactionType: string, interactionId: string | null) => {
    if(nodeId && interactionType && interactionId) {
      const url = `/pod/${podId}/pdf/${nodeId}/${interactionType}/${interactionId}`
      navigate(url)
    }
  }

  const ActiveConversationItem = () => (
    <Box className="overflowWrapper" sx={{alignContent: "start"}}>
      {/* Thread Header */}
      <Box sx={{
        alignItems: "center",
        backgroundColor: "#ffd800db",
        borderBottom: "1px solid rgba(63, 67, 80, 0.16)",
        color: "#3b3b3b",
        display: "grid",
        fontSize: "18px",
        gridTemplateColumns: "max-content auto max-content",
        height: uiStore.deviceWidth === "tiny" ? "45px" : "56px",
        padding: "0 10px"
      }}>
        <IconButton aria-label="back" onClick={() => changeActiveConversationId(null)}>
          <ArrowBackIcon sx={{color: "#3b3b3b"}} />
        </IconButton>
        <Box sx={{margin: "0 5px", fontWeight: "450"}}>
          {(uiStore.deviceWidth === "small") &&
            t('Conversation')
          }
        </Box>
        <Button sx={{backgroundColor: "white", fontSize: uiStore.deviceWidth === "tiny" ? "10px" : "", "&:hover": {backgroundColor: "aliceblue"} }} onClick={() => navigateToInteraction(nodeId, "comment", interactionId)}>
          {t('Jump to the text passage')}
        </Button>
      </Box>
      {/* Thread Content */}
      <Box sx={{overflowY: "auto"}}>
        <Thread interactionId={interactionId} nodeId={nodeId} />
      </Box>
    </Box>
  )

  const Switch = () => {
    // on small screen:
    // if active conversation show it
    // else show list of conversations
    if(uiStore.deviceWidth === "tiny" || uiStore.deviceWidth === "small") {
      if(activeConversation) {
        return (
          <ActiveConversationItem />
        )
      } else {
        return (
          <ConversationList conversationList={conversationList} podId={podId} />
        )
      }
    }
    // on large screen:
    // if active conversation show it
    // else show placeholder
    else if(activeConversation) {
      return (
        <ActiveConversationItem />
      )
    }
    return null
  }


  return (
    <Switch />
  )
}

export default observer(ActiveConversation)