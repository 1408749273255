import { useEffect } from "react"
import { useLocation } from "wouter"
import { useStore } from "../../contexts/store"
import { useParams } from "../../stores/uiStore"
import { Rect as RectModel, interactionAnchor } from '../../../../types/Interaction'
import { Op_addAnnotation, Op_addLink } from "../../../../types/Ops"
import copy from 'copy-to-clipboard'
import alertStore, { alert } from "../../stores/alertStore"
import { useTranslation } from "react-i18next"

const PageClickEvents = ({
    currentScale,
    linkEditId,
    pageNumber,
    pdfId,
    storeId
  }: {
    currentScale: number,
    linkEditId: string | null,
    pageNumber: number,
    pdfId: string,
    storeId: string
  }) => {
  const [_ , navigate] = useLocation()
  const { pdfMetaStore, podStore, uiStore, opStore,sessionStore } = useStore()
  const { session } = sessionStore
  const { podId } = useParams()
  const { t } = useTranslation()


  const pdfUrl = `/pod/${podId}/pdf/${pdfId}`
  let clickRange:any
  let pointerDevice:string

  const getPageFromY = (y:number) => {
    const heights = pdfMetaStore.getPageHeights(storeId)
    var pageStart = 0
    var page = null
    var relPos = 0
    if (heights) heights.find((height, i) => {
      if ((pageStart < y) && (pageStart+height > y)) {
        page=i+1
        relPos = y - pageStart - 14
        return true
      }
      pageStart += height
      return false
    })
    return [page, relPos]
  }

  useEffect(() => {

    const handleClick = (e:any) => {
      // how long was the duration of the click
      const clickDuration = uiStore.selectionStartRange ? (Date.now() - uiStore.selectionStartRange.t0) : 0

      if (podId && pdfId) {
        if ((uiStore.selectionStartRange) && (uiStore.selectionEndRange) && (clickDuration>300)) {
          if (uiStore.copyMode) {
            copyText()
          }
          else {
            addInteraction()
          }
        }
        // in linkEdit mode no selections of interactions are allowed
        else if(!linkEditId) {
          if ((clickDuration>0) && (clickDuration<200)) selectInteraction(e.clientX, e.clientY)
        }
      }

      uiStore.setSelectionStartRange(null)
      uiStore.setSelectionEndRange(null)
      setRange(null, null)
    }

    const selectInteraction = (clickX: number, clickY: number) => {
      // determine selected pdf page, calculate coordinates within the pdf page
      const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[1]?.getBoundingClientRect()

      if(pageNumber !== null && pageElement) {
        const currentScale = pdfMetaStore.getScale(storeId)
        // x position in relation to page element
        let x = clickX - pageElement.x
        // normalized x position
        x = parseToFixed(x / currentScale)
        // y position in relation to page element
        let y = clickY - pageElement.y
        // normalized y position
        y = parseToFixed(y / currentScale)

        const selectedInteraction = podStore.getInteractionByCoordinates(pdfId, x, y, pageNumber+1)
        if(selectedInteraction) {
          const selectedInteractionId = selectedInteraction.interaction.interactionId
          // TODO: check if already on desired destination subroute
          // TODO: scroll interactionPanel to selected interaction
          const interactionType = selectedInteraction.type
          if(interactionType === "annotation") navigate(`${pdfUrl}/annotation/${selectedInteractionId}`, {replace: true})
          if(interactionType === "comment") navigate(`${pdfUrl}/comment/${selectedInteractionId}`, {replace: true})
          if(interactionType === "link") navigate(`${pdfUrl}/link/${selectedInteractionId}`, {replace: true})
          if(interactionType === "tagging") navigate(`${pdfUrl}/tag/${selectedInteractionId}`, {replace: true})
          if(interactionType === "weblink") navigate(`${pdfUrl}/weblink/${selectedInteractionId}`, {replace: true})
          if(interactionType === "emotion") navigate(`${pdfUrl}/emotion/${selectedInteractionId}`, {replace: true})
        }
        else {
          // deselect interaction
          // TODO: check if interaction is selected at all
          navigate(`${pdfUrl}`, {replace: true})
        }
      }
    }

    const copyText = () => {
      if ((uiStore.selectionStartRange) && (uiStore.selectionEndRange)) setRange(uiStore.selectionStartRange, uiStore.selectionEndRange); else console.log('no uistore range')
      let sel = null
      if (window) sel = window.getSelection()
      if (sel) copy(sel.toString())
      uiStore.setCopyMode(false)
      alertStore.push(alert(t('The selected text was copied to the clipboard'), 'success'))
    }

    const addInteraction = () => {
      if ((uiStore.selectionStartRange) && (uiStore.selectionEndRange)) setRange(uiStore.selectionStartRange, uiStore.selectionEndRange); else console.log('no uistore range')
      // get selection from mouse movement
      let sel = null
      if (window) sel = window.getSelection()
      // get position of pdf viewer component
      const pdfViewer = document.getElementById(`${storeId}-pdf-viewer`)?.getBoundingClientRect()
      // bottom and top page position of the viewed pdf section
      const pagePos = pdfMetaStore.getPagePos(storeId)

      const scrollpos = (pagePos?.length === 3) ? pagePos[2] : null

      if (sel && sel.rangeCount && pdfViewer && pagePos && scrollpos!==null) {
        // text inside selection
        const text = sel.toString()
        // get rects of selection
        const range   = sel.getRangeAt(0)
        const rectsArray   = range.getClientRects()
        // for each rectangle within a selection
        if(rectsArray.length) {
          const rects = []
          for(let i=0; i<rectsArray.length; i++) {
            const rect = rectsArray[i]
            //console.log(`consider rect ${i}`, rect)
            // determine selected pdf page, calculate coordinates within the pdf page
            const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[1]?.getBoundingClientRect()
            if(pageElement && pageElement.x && pageElement.y && pageElement.height && pageElement.width) {
              // sort out incorrect selections that include the entire pdf page and beyond.
              if(rect.width < pageElement.width && rect.height < pageElement.height && rect.y > 0) {
                const x = rect.x - pageElement.x
                const y = rect.y - pageElement.y
                const [p, relY] = getPageFromY(rect.y + scrollpos - pdfViewer.top)

                // normalize with the scaling of the pdf
                // parse overlay coordinates to three decimal places
                // start with page number 1
                if (p && relY) {
                  const position: RectModel = {
                    x: parseToFixed(x / currentScale),
                    y: parseToFixed(relY / currentScale),
                    h: parseToFixed(rect.height / currentScale),
                    w: parseToFixed(rect.width / currentScale),
                    p: p
                  }
                  // do not consider zero heights or widths or NaN pageNumber
                  if(position.h > 1 && position.w > 1 && !Number.isNaN(position.p)) {
                    rects.push(position)
                  }
                }
              }
            }
          }

          // remove text selection from mouse
          sel.removeAllRanges()

          // get interaction type selected in the sidebar
          const interactionType = uiStore.getSelectedInteraction()
          // get overlay color selected in the sidebar
          const color = uiStore.getInteractionColor(interactionType)
          // optimizes the number of rectangles
          const optimizedRects = optimizeRects(rects)

          if(optimizedRects.length) {
            const anchor: interactionAnchor = {
              nodeId: pdfId,
              relText: text,
              rects: optimizedRects,
            }
            // if linkEdit: process selection in EditLink View
            if(linkEditId) {
              podStore.setLinkOverlay(storeId, anchor)
            }
            // if add annotation: save selected rects as operation
            else if(interactionType === "addAnnotation") {
              opStore.doOp({
                op: 'addAnnotation',
                podId: podId,
                data: {
                  usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
                  interactionType: 'annotation',
                  interactionId: sessionStore.createUuid(),
                  userId: session.user.userId,
                  userName: podStore.userPseudonym,
                  anchor: anchor,
                  style: { color: color },
                  label: "",
                  tCreated: Math.floor(Date.now()/1000),
                  tModified: Math.floor(Date.now()/1000),
                  lastModUuid: sessionStore.createUuid(),
                  coid: null,
                }
              } as Op_addAnnotation)
            }
            // if add link: create a link object with selected text and rects
            else if(interactionType === "addLink") {
              makeLink(anchor)
            }
            // else process selection in modal
            else {
              uiStore.setSelectedAnchor(anchor)
              uiStore.openInteractionModal()
            }
          }
        }

      }
    }

    const makeLink = (anchor: interactionAnchor) => {
      if (podId) {
        const srcInteractionId = sessionStore.createUuid()
        const dstInteractionId = sessionStore.createUuid()
        const linkId = sessionStore.createUuid()
        const nodeId = pdfId

        const addLinking1:Op_addLink = {
          op: 'addLink',
          podId: podId,
          data: {
              coid: null,
              interactionId: srcInteractionId,
              interactionType: 'link',
              userId: session.user.userId,
              userName: podStore.userPseudonym,
              usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
              linkId: linkId,
              linkType: 'related',
              which: 'src',
              other: dstInteractionId,
              anchor: anchor,
              style: {},
              label: '',
              tCreated: Math.floor(Date.now()/1000),
              tModified: Math.floor(Date.now()/1000),
          }
        }
        const addLinking2: Op_addLink = {
          op: 'addLink',
          podId: podId,
          data: {
              coid: null,
              interactionId: dstInteractionId,
              interactionType: 'link',
              userId: session.user.userId,
              userName: podStore.userPseudonym,
              usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
              linkId: linkId,
              linkType: 'related',
              which: 'dst',
              other: srcInteractionId,
              anchor: {
                nodeId: nodeId,
                relText: '',
                rects: [],
              },
              style: {},
              label: '',
              tCreated: Math.floor(Date.now()/1000),
              tModified: Math.floor(Date.now()/1000),
          }
        }

        opStore.doOp(addLinking1)
        opStore.doOp(addLinking2)

        // navigate to the link
        const editLinkPath = `/pod/${podId}/pdf/${nodeId}/editLink/${srcInteractionId}`
        navigate(editLinkPath, {replace:true})
      }
    }

    // calculate start and end of click to distinguish between short selection or selection of text
    const handleMouseDown = (e:MouseEvent) => {
      const selectionStartRange = getCaretRangeAtXY(e.clientX, e.clientY)
      if (selectionStartRange) {
        uiStore.setSelectionStartRange(selectionStartRange)
        e.preventDefault()
      }
    }

    const handleTouchStart = (e:TouchEvent) => {
      if (e.touches.length === 1) {
        const selectionStartRange = getCaretRangeAtXY(e.touches[0].clientX, e.touches[0].clientY)
        if (selectionStartRange) {

          if (
            (pointerDevice === 'pen') || (
              (clickRange && selectionStartRange) && 
              (Math.abs(clickRange.clientX - selectionStartRange.clientX)<8) &&
              (Math.abs(clickRange.clientY - selectionStartRange.clientY)<8) &&
              ((selectionStartRange.t0 - clickRange.t0)<200)
            )
          ) {
            uiStore.setSelectionStartRange(selectionStartRange)
            e.preventDefault()
          }
          else {
            clickRange = selectionStartRange
          }
        }
      }
    }

    const handleMouseMove = (e:MouseEvent) => {
      if ((uiStore.selectionStartRange) && (e.buttons===1)) {
        const selection:Selection|null = getSelection()
        const selectionEndRange = getCaretRangeAtXY(e.clientX, e.clientY)
        if (selectionEndRange) {
          uiStore.setSelectionEndRange(selectionEndRange)
        }
        if (uiStore.selectionEndRange) setRange(uiStore.selectionStartRange, uiStore.selectionEndRange)
        e.preventDefault()
      }
    }

    const handleTouchMove = (e:TouchEvent) => {
      if ((uiStore.selectionStartRange) && (e.touches.length === 1)) {
        const selection:Selection|null = getSelection()
        const selectionEndRange = getCaretRangeAtXY(e.touches[0].clientX, e.touches[0].clientY)
        if (selectionEndRange) {
          uiStore.setSelectionEndRange(selectionEndRange)
        }
        if (uiStore.selectionEndRange) setRange(uiStore.selectionStartRange, uiStore.selectionEndRange)
        e.preventDefault()
      }
    }

    const handleTouchEnd = (e:TouchEvent) => {
      handleClick({ clientX: e.changedTouches[0].clientX, clientY: e.changedTouches[0].clientY })
      if (e.cancelable) e.preventDefault()
    }

    const handlePointer = (e:any) => {
      pointerDevice = e.pointerType
    }

    // register event listener on mount
    const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[1] as HTMLElement
    if (pageElement) {
      pageElement.addEventListener('mousedown', handleMouseDown)
      pageElement.addEventListener('mousemove', handleMouseMove)
      pageElement.addEventListener('mouseup', handleClick)
      pageElement.addEventListener('touchstart', handleTouchStart)
      pageElement.addEventListener('touchmove', handleTouchMove)
      pageElement.addEventListener('touchend', handleTouchEnd)  
      if (window.PointerEvent) pageElement.addEventListener('pointerdown', handlePointer)
    }



    return () => {
      // unregister event listener on unmount
      const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[1] as HTMLElement
      if (pageElement) {
        pageElement.removeEventListener('mousedown', handleMouseDown)
        pageElement.removeEventListener('mousemove', handleMouseMove)
        pageElement.removeEventListener('mouseup', handleClick)
        pageElement.removeEventListener('touchstart', handleTouchStart)
        pageElement.removeEventListener('touchmove', handleTouchMove)
        pageElement.removeEventListener('touchend', handleTouchEnd)
        if (window.PointerEvent) pageElement.removeEventListener('pointerdown', handlePointer)
      }
    }
  }, [storeId])

  const getCaretRangeAtXY = (x:number, y:number) => {
    var range: {
      node: Node,
      offset: number,
      t0: number,
      pageNumber: number,
      clientX: number,
      clientY: number,
    } | null = null

    if (typeof document.createRange !== "undefined") {

      // @ts-ignore
      if (document.caretPositionFromPoint) {
        // @ts-ignore
        const r:any = document.caretPositionFromPoint(x, y);
        range = {
          node: r.offsetNode,
          offset: r.offset,
          t0: Date.now(),
          pageNumber: r.offsetNode.parentElement?.closest('.react-pdf__Page')?.getAttribute('data-page-number'),
          clientX: x,
          clientY: y,
        }
      } else if (document.caretRangeFromPoint) {
        const r: any = document.caretRangeFromPoint(x, y);
        range = {
          node: r.startContainer,
          offset: r.startOffset,
          t0: Date.now(),
          pageNumber: r.startContainer.parentElement?.closest('.react-pdf__Page')?.getAttribute('data-page-number'),
          clientX: x,
          clientY: y,
        }
      }
    }
    if ((!range) || (range.node.nodeType !== 3) || (range.node.nodeName !== '#text') || (range.node.parentNode?.nodeName !== 'SPAN')) return null
    return range;
  }

  const setRange = (startRange: {node: Node, offset: number} | null, endRange: {node: Node, offset: number} | null) => {
      const selection:Selection|null = getSelection()

      if (startRange && endRange) {
        const domOrder = startRange.node.compareDocumentPosition(endRange.node)
        if (selection) {
          selection.removeAllRanges()
          var newRange = new Range()

          if (window.getSelection) {
            if (window.getSelection()?.empty) {  // Chrome
              window.getSelection()?.empty();
            } else if (window.getSelection()?.removeAllRanges) {  // Firefox
              window.getSelection()?.removeAllRanges();
            }
          }

          if ((domOrder === 4) || ((domOrder === 0) && (startRange.offset < endRange.offset))) {
            newRange.setStart(startRange.node, startRange.offset)
            newRange.setEnd(endRange.node, endRange.offset)
          }
          else if ((domOrder === 2) || ((domOrder === 0) && (startRange.offset > endRange.offset))) {
            newRange.setStart(endRange.node, endRange.offset)
            newRange.setEnd(startRange.node, startRange.offset)
          }

          if (newRange) {
            selection.addRange(newRange)
            return true
          }
        }
      }
      else {
        if (selection) selection.removeAllRanges()
      }

      return null
  }

  return null
}


export default PageClickEvents


function parseToFixed(number: number) {
  return parseFloat(number.toFixed(3))
}


// adopted from shrimp-pods
function optimizeRects(rect: any, verbose = false) {

  const nrect = rect.map((r: any) => ({
    x1: r.x,
    y1: r.y,
    x2: r.x + r.w,
    y2: r.y + r.h,
    p: r.p
  }))

  if (verbose) console.log(nrect)

  const intersect = (rect1: any, rect2: any) => {
    return Math.max(0, Math.min(rect1.x2, rect2.x2) - Math.max(rect1.x1, rect2.x1)) * Math.max(0, Math.min(rect1.y2, rect2.y2) - Math.max(rect1.y1, rect2.y1))
  }

  const union = (rect1: any, rect2: any) => {
    return ((rect1.x2 - rect1.x1) * (rect1.y2 - rect1.y1)) + ((rect2.x2 - rect2.x1) * (rect2.y2 - rect2.y1)) - intersect(rect1, rect2)
  }

  const calc = (i0: any, i1: any) => {
    const nrect1 = nrect[i0]
    const nrect2 = nrect[i1]
    const si = intersect(nrect1, nrect2 )
    const su = union(nrect1, nrect2)
    if (verbose) console.log(si, su, 100 * si/su )
    return 100 * si/su
  }

  for(var i0=0; i0<nrect.length; i0++) {
    if (nrect[i0] === null) continue
    for(var i1=i0+1; i1<nrect.length; i1++) {
      if (nrect[i1] === null) continue
      const ratio = calc(i0, i1)
      if (verbose) console.log(`Compare ${i0} to ${i1}: `, ratio)
      if (ratio > 70) {
        if (verbose) console.log('merge')
        nrect[i0] = {
          x1: Math.min(nrect[i0].x1, nrect[i1].x1),
          y1: Math.min(nrect[i0].y1, nrect[i1].y1),
          x2: Math.max(nrect[i0].x2, nrect[i1].x2),
          y2: Math.max(nrect[i0].y2, nrect[i1].y2),
          p: nrect[i0].p
        }
        nrect[i1] = null
      }
    }
  }

  return nrect.filter((n: any) => n !== null).map((r: any) => ({
    x: r.x1,
    y: r.y1,
    w: r.x2 - r.x1,
    h: r.y2 - r.y1,
    p: r.p
  }))
}
