// eslint-disable-next-line
import { Pod, PodLoadState, Usergroup } from '../../../types/Pod'
import { PdfFile, Folder, PdfPage, Tag, Link } from '../../../types/Content'
import { iAnnotation, iComment, iLink, iWeblink, iTag, iEmotion, Interaction } from '../../../types/Interaction'
import { Thread, Message } from '../../../types/Message'
import { UserInfo } from "../../../types/User"
import { makeObservable, observable, action } from "mobx"
import uiStore from '../stores/uiStore'
import sessionStore from '../stores/sessionStore'

import murmurhash from 'murmurhash'
import { Op } from '../../../types/Ops'
import { OpCode } from '../../../types/OpCodes'

const interactionMerge = (interaction:Interaction, data:Interaction) => {
  Object.keys(data).forEach((prop:string) => {
    //@ts-ignore
    if (interaction[prop] !== data[prop]) interaction[prop] = data[prop]
  })
}

export interface PodI extends Pod {
  addPdfFile: (pdfFile: PdfFile) => void
  setStatus: (status: PodLoadState) => void
  setLastSyncOid: (oid: number) => void
  setLoadStatus: (status: number) => void
  applyOp: (op: Op) => void

  addPdfPage: (op: any) => void
  addFolder: (op: any) => void
  addTag: (data: Tag) => void
  addAnnotation: (data: iAnnotation) => void
  addComment: (data: iComment) => void
  addLink: (data: iLink) => void
  addWeblink: (data: iWeblink) => void
  addTagging: (data: iTag) => void
  addEmotion: (data: iEmotion) => void

  addThread: (op: any) => void
  addMessage: (op: any) => void

  getPdfFiles: () => PdfFile[]
  getFolders: () => Folder[]
  getAnnotation: (interactionId: string) => iAnnotation | null
  getAnnotations: (nodeId: string) => iAnnotation[] | null
  getComments: (nodeId: string) => iComment[] | null
  getLinks: (nodeId: string) => iLink[] | null
  getLinkOther: (link: iLink) => iLink | false
  getWeblinks: (nodeId: string) => iWeblink[] | null
  getTags: (nodeId: string) => iTag[] | null
  getEmotions: (nodeId: string) => iEmotion[] | null
  getUsergroupByRole: (role: string) => Usergroup
  getInteractionFromThreadId: (threadId: string) => iAnnotation | iComment | iLink | iWeblink | iTag | iEmotion | null

  getInteraction: (interactionId: string) => iAnnotation | iComment | iLink | iWeblink | iTag | iEmotion | null
  getMessage: (messageId: string, threadId:string|null) => Message | null
  getThreadFromMessage: (messageId: string) => Thread | null

  fingerprint: (hashed:boolean) => string
  findInteraction: (interactionId:string, contentType:'pdfFiles'|'', interactionType:'annotations'|'comments'|'links'|'weblinks'|'taggings'|'emotions'|'') => { contentType: string; nodeId: string; interactionType: string; } | false
  isAllowed: (op: OpCode, objectId: string|number|null) => boolean
  isVisible: (type:'pdfFile'|'thread'|'message'|'annotation'|'comment'|'link'|'tagging'|'weblink'|'emotion'|'interaction', id: string) => boolean
  nodeIsHidden: (nodeId: string) => boolean
}

export class PodClass implements PodI {
  public podId: string = ''
  public name: string = ''
  public description: string = ''
  public usergroups: {[usergroupId: string]: Usergroup} = {}
  public permissions: {[op: string]: boolean}  = {}
  public content: {
    pdfFiles: {[nodeId: string]: PdfFile},
    folders: {[folderId: string]: Folder},
    tags: {[tagId: string]: Tag},
    links: {[linkId: string]: Link}
    threads: {[threadId: string]: Thread},
  } = {
    pdfFiles: {},
    folders: {},
    tags: {},
    links: {},
    threads: {},
  }
  public userInfos: {[userId: number]: UserInfo} = {}
  public status: PodLoadState | null = null
  public loadStatus?: number  = 0
  public initMaxCoid: number = 0
  public loadtimeMaxOid: number = 0
  public lastSyncOid: number = 0
  public tCreated: number = 0
  public tModified: number = 0

  public outOfSync?: boolean|undefined = false
  public backendFingerprint?: string
  public serviceWorkerFingerprint?: string

  constructor(pod: Pod|null, addMobx: boolean = false) {
    if (pod !== null) {
      Object.assign(this, pod);
    }
    else {
      const emptyPod:Pod = {
        podId: '',
        name:'',
        description:'',
        usergroups:{},
        permissions: {},
        userInfos: {},
        content:{
          folders: {},
          pdfFiles:{},
          tags:{},
          links:{},
          threads: {},
        },
        status:'broken',
        initMaxCoid:0,
        loadtimeMaxOid:0,
        lastSyncOid:0,
        tCreated:0,
        tModified:0,
        outOfSync: false,
      }
      Object.assign(this, emptyPod);
    }
    if (addMobx) makeObservable(this, {
      loadStatus: observable,
      status: observable,
      content: observable,
      permissions: observable,
      usergroups: observable,
      userInfos: observable,
      outOfSync: observable,

      getPdfFiles: observable,
      getFolders: observable,
      getAnnotations: observable,
      getComments: observable,
      getLinks: observable,
      getLinkOther: observable,
      getWeblinks: observable,
      getTags: observable,
      getEmotions: observable,

      // addPdfFile: action, // we technically don't need to decorate this as an action as long as it is called only from inside doOp (in which case it should be marked 'private')
      applyOp: action,
      setLastSyncOid: action,
      setStatus: action,
      setLoadStatus: action,
    })
  }

  isVisible(type:'pdfFile'|'thread'|'message'|'annotation'|'comment'|'link'|'tagging'|'weblink'|'emotion'|'interaction', id:string) {

    const usergroupsForThisUser = this.usergroups

    switch(type) {
      case 'pdfFile':
        const file = this.content.pdfFiles[id]
        if (file) {
            if (file.userId===sessionStore.session.user.userId) return true
            if (typeof usergroupsForThisUser[file.usergroupId] !== 'undefined') return true
        }
        break;

      case 'thread':
        const thread = this.content.threads[id]
        if (thread) {
          //const interaction = this.
          if (thread.userId === sessionStore.session.user.userId) return true
          if (typeof usergroupsForThisUser[thread.usergroupId] !== 'undefined') return true
        }
      break

      case 'message':
        const msgThread = this.getThreadFromMessage(id)
        if (msgThread) {
          if (msgThread.userId === sessionStore.session.user.userId) return true
          if (typeof usergroupsForThisUser[msgThread.usergroupId] !== 'undefined') return true
        }
        break;

      case 'annotation':
      case 'comment':
      case 'link':
      case 'tagging':
      case 'weblink':
      case 'emotion':
      case 'interaction':
        const interaction = this.getInteraction(id)
        if (interaction) {
          if (interaction.userId === sessionStore.session.user.userId) return true
          if (typeof usergroupsForThisUser[interaction.usergroupId] !== 'undefined') return true
        }
        break;

      default:
        return false
    }

    return false
  }

  isAllowed(op: OpCode, objectOrParentId: string|number|null = null) {

    // if no object / parent is defined, return the general permissions the user has in this pod
    if (objectOrParentId === null) {
      return this.permissions[op]
    }

    // if an object / parent is defined, determin if the user owns this object (wich gives her all rights), or if
    // the object is visible to her and she generally has the required permission for this operation in this pod
    switch(op) {
      case 'deletePod': {
        const pod:PodI = sessionStore.session.pods.find((p:PodI) => p.podId === objectOrParentId)
        if (pod && pod.creator?.userId === sessionStore.session.user.userId) return true
        }
        return false

        case 'addAnnotation':
        case 'addComment':
        case 'addLink':
        case 'addTagging':
        case 'addWeblink':
        case 'addEmotion':
        case 'editPdfFile': {
          const file = this.content.pdfFiles[objectOrParentId]
          if (file) {
            if (file.userId === sessionStore.session.user.userId) return true
            if ((this.isVisible('pdfFile', file.nodeId)) && (this.isAllowed(op))) return true
          }
        }
        return false

      case 'editAnnotation':
      case 'editComment':
      case 'editEmotion':
      case 'editLink':
      case 'editTagging':
      case 'editWeblink':
      case 'deleteAnnotation':
      case 'deleteComment':
      case 'deleteEmotion':
      case 'deleteLink':
      case 'deleteTagging':
      case 'deleteWeblink':
        // check group of interaction `objectId` to see if the user can edit/delete it
        const interaction = this.getInteraction(objectOrParentId as string)
        if (interaction) {
          if (interaction.userId === sessionStore.session.user.userId) return true
          if ((this.isVisible('interaction', interaction.interactionId)) && (this.isAllowed(op))) return true
        }
        return false

      case 'addMessage':
        // check group of the thread `objectOrParentId` to see if the user can contribute to this thread
        const thread = this.content.threads[objectOrParentId]
        if (thread) {
          if (thread.userId === sessionStore.session.user.userId) return true
          if ((this.isVisible('thread', thread.threadId as string)) && (this.isAllowed(op))) return true
        }
        return false

      case 'editMessage':
      case 'deleteMessage':
        const message = this.getMessage(objectOrParentId as string)
        if (message) {
          if (message.userId === sessionStore.session.user.userId) return true
          if ((this.isVisible('message', message.messageId)) && (this.isAllowed(op))) return true
        }
        return false

      case 'editUserInfo':
        if (objectOrParentId === sessionStore.session.user.userId) return true
        return this.permissions[op]

      case 'removeUserFromPod':
        if (objectOrParentId === sessionStore.session.user.userId) return true
        return this.permissions[op]

    }
    return false
  }

  nodeIsHidden(nodeId:string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return true
    if (file.hidden) return true
    if (file.folderId) {
      const folder = this.content.folders[file.folderId]
      if (!folder) return true
      if (folder.hidden) return true
    }
    return false
  }



  applyOp(op: Op) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`ApplyOp(#${op.oid}: ${op.op})`)
    if (op.oid) this.setLastSyncOid(op.oid)

    switch(op.op) {

      case 'noop':
        break

      case 'addPdfFile':
        const addPdfFileData: PdfFile = {
          nodeId: op.data.nodeId,
          name: op.data.name,
          description: op.data.description,
          status: op.data.status,
          weight: op.data.weight,
          folderId: op.data.folderId,
          hidden: op.data.hidden,
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          hash: op.data.hash,
          size: op.data.size,
          nofPages: op.data.nofPages,
          pages: [],
          annotations: {},
          comments: {},
          emotions: {},
          links: {},
          weblinks: {},
          taggings: {},
          coid: op.data.coid || null,                   // should we take this from op.oid? -> No, better to have the coid as part of the data-object than to rely on an implicit property higher up?
          tCreated: op.data.tCreated || null,
          tModified: op.data.tModified || null,
        }
        this.addPdfFile(addPdfFileData)
        break

      case 'editPdfFile':
        if (this.content.pdfFiles[op.data.nodeId]) {
          if (typeof op.data.mods.description !== 'undefined') this.content.pdfFiles[op.data.nodeId].description = op.data.mods.description
          if (typeof op.data.mods.folderId !== 'undefined') this.content.pdfFiles[op.data.nodeId].folderId = op.data.mods.folderId
          if (typeof op.data.mods.name !== 'undefined') this.content.pdfFiles[op.data.nodeId].name = op.data.mods.name
          if (typeof op.data.mods.weight !== 'undefined') this.content.pdfFiles[op.data.nodeId].weight = op.data.mods.weight
          if (typeof op.data.mods.hidden !== 'undefined') this.content.pdfFiles[op.data.nodeId].hidden = op.data.mods.hidden
          if (typeof op.data.mods.status !== 'undefined') this.content.pdfFiles[op.data.nodeId].status = op.data.mods.status
          if (typeof op.data.mods.tModified !== 'undefined') this.content.pdfFiles[op.data.nodeId].tModified = op.data.mods.tModified
        }
        break;

      case 'editFolder':
        if (this.content.folders[op.data.folderId]) {
          if (typeof op.data.mods.description !== 'undefined') this.content.folders[op.data.folderId].description = op.data.mods.description
          if (typeof op.data.mods.name !== 'undefined') this.content.folders[op.data.folderId].name = op.data.mods.name
          if (typeof op.data.mods.weight !== 'undefined') this.content.folders[op.data.folderId].weight = op.data.mods.weight
          if (typeof op.data.mods.hidden !== 'undefined') this.content.folders[op.data.folderId].hidden = op.data.mods.hidden
          if (typeof op.data.mods.tModified !== 'undefined') this.content.folders[op.data.folderId].tModified = op.data.mods.tModified
        }
        break

      case 'addPdfPage':
        const addPdfPageData: PdfPage  = {
          nodeId: op.data.nodeId,
          no: op.data.no,
          width: op.data.width,
          height: op.data.height,
          rotation: op.data.rotation,
          mTop: op.data.mTop,
          mRight: op.data.mRight,
          mBottom: op.data.mBottom,
          mLeft: op.data.mLeft,
          fulltext: op.data.fulltext,
          coid: op.data.coid || null,
        }
        this.addPdfPage(addPdfPageData)
        break

      case 'addFolder':
        const addFolderData: Folder = {
          folderId: op.data.folderId,
          name: op.data.name,
          weight: op.data.weight,
          coid: op.data.coid || null,
          tCreated: op.data.tCreated || null,
          tModified: op.data.tModified || null,
        }
        this.addFolder(addFolderData)
        break

      case 'addTag':
        const addTagData: Tag = {
          tagId: op.data.tagId,
          name: op.data.name,
          description: op.data.description,
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          coid: op.data.coid || null,
          tCreated: op.data.tCreated || null,
          tModified: op.data.tModified || null,
        }
        this.addTag(addTagData)
        break

      case 'addAnnotation':
        const addAnnotationData: iAnnotation = {
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          interactionId: op.data.interactionId,
          interactionType: 'annotation',
          style: op.data.style,
          label: op.data.label,
          anchor: {
            nodeId: op.data.anchor.nodeId,
            rects: op.data.anchor.rects.map((r:any) => ({
              x: Number(r.x),
              y: Number(r.y),
              w: Number(r.w),
              h: Number(r.h),
              p: Number(r.p)
            })),
            relText: op.data.anchor.relText,
          },
          coid: op.data.coid || null,
          tCreated: op.data.tCreated,
          tModified: op.data.tModified,
        }
        this.addAnnotation(addAnnotationData)
        break

      case 'addComment':
        const addCommentData: iComment = {
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          interactionId: op.data.interactionId,
          interactionType: 'comment',
          style: op.data.style,
          label: op.data.label,
          anchor: {
            nodeId: op.data.anchor.nodeId,
            rects: op.data.anchor.rects.map((r:any) => ({
              x: Number(r.x),
              y: Number(r.y),
              w: Number(r.w),
              h: Number(r.h),
              p: Number(r.p)
            })),
            relText: op.data.anchor.relText,
          },
          coid: op.data.coid || null,
          tCreated: op.data.tCreated ,
          tModified: op.data.tModified ,
        }
        this.addComment(addCommentData)
        break

      case 'addLink':
        const addLinkData:iLink = {
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          linkId: op.data.linkId,
          linkType: op.data.linkType,
          which: op.data.which,
          interactionId: op.data.interactionId,
          interactionType: 'link',
          style: op.data.style,
          label: op.data.label,
          anchor: {
            nodeId: op.data.anchor.nodeId,
            rects: op.data.anchor.rects.map((r:any) => ({
              x: Number(r.x),
              y: Number(r.y),
              w: Number(r.w),
              h: Number(r.h),
              p: Number(r.p)
            })),
            relText: op.data.anchor.relText,
          },
          coid: op.data.coid || null,
          tCreated: op.data.tCreated,
          tModified: op.data.tModified,
        }

        this.addLink(addLinkData)
        break

      case 'addWeblink':
        const weblinkData: iWeblink = {
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          interactionId: op.data.interactionId,
          interactionType: 'weblink',
          style: op.data.style,
          label: op.data.label,
          url: op.data.url,
          anchor: {
            nodeId: op.data.anchor.nodeId,
            rects: op.data.anchor.rects.map((r:any) => ({
              x: Number(r.x),
              y: Number(r.y),
              w: Number(r.w),
              h: Number(r.h),
              p: Number(r.p)
            })),
            relText: op.data.anchor.relText,
          },
          coid: op.data.coid || null,
          tCreated: op.data.tCreated,
          tModified: op.data.tModified,
        }
        this.addWeblink(weblinkData)
        break

      case 'addTagging':
        const addTaggingData: iTag = {
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          interactionId: op.data.interactionId,
          interactionType: 'tagging',
          style: op.data.style,
          label: op.data.label,
          tagId: op.data.tagId,
          anchor: {
            nodeId: op.data.anchor.nodeId,
            rects: op.data.anchor.rects.map((r:any) => ({
              x: Number(r.x),
              y: Number(r.y),
              w: Number(r.w),
              h: Number(r.h),
              p: Number(r.p)
            })),
            relText: op.data.anchor.relText,
          },
          coid: op.data.coid || null,
          tCreated: op.data.tCreated ,
          tModified: op.data.tModified,
        }
        this.addTagging(addTaggingData)
        break

      case 'addEmotion':
        const addEmotionData: iEmotion = {
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          interactionId: op.data.interactionId,
          interactionType: 'emotion',
          style: op.data.style,
          emotionId: op.data.emotionId,
          label: op.data.label,
          anchor: {
            nodeId: op.data.anchor.nodeId,
            rects: op.data.anchor.rects.map((r:any) => ({
              x: Number(r.x),
              y: Number(r.y),
              w: Number(r.w),
              h: Number(r.h),
              p: Number(r.p)
            })),
            relText: op.data.anchor.relText,
          },
          coid: op.data.coid || null,
          tCreated: op.data.tCreated,
          tModified: op.data.tModified,
        }
        this.addEmotion(addEmotionData)
        break

      case 'editAnnotation': {
        const iLoc = this.findInteraction(op.data.interactionId, 'pdfFiles', 'annotations')
        if (iLoc) {
          const file = this.content['pdfFiles'][iLoc.nodeId]
          if (typeof op.data.mods.coid !== 'undefined') file.annotations[op.data.interactionId].coid = op.data.mods.coid
          if (typeof op.data.mods.tCreated !== 'undefined') file.annotations[op.data.interactionId].tCreated = op.data.mods.tCreated
          if (typeof op.data.mods.tModified !== 'undefined') file.annotations[op.data.interactionId].tModified = op.data.mods.tModified
          if (typeof op.data.mods.label !== 'undefined') file.annotations[op.data.interactionId].label = op.data.mods.label
        }
        } break

      case 'editComment': {
        const iLoc = this.findInteraction(op.data.interactionId, 'pdfFiles', 'comments')
        if (iLoc) {
          const file = this.content['pdfFiles'][iLoc.nodeId]
          if (typeof op.data.mods.coid !== 'undefined') file.comments[op.data.interactionId].coid = op.data.mods.coid
          if (typeof op.data.mods.tCreated !== 'undefined') file.comments[op.data.interactionId].tCreated = op.data.mods.tCreated
          if (typeof op.data.mods.tModified !== 'undefined') file.comments[op.data.interactionId].tModified = op.data.mods.tModified
          if (typeof op.data.mods.label !== 'undefined') file.comments[op.data.interactionId].label = op.data.mods.label
        }
        } break

      case 'editLink': {
        const iLoc = this.findInteraction(op.data.interactionId, 'pdfFiles', 'links')
        if (iLoc) {
            const file = this.content['pdfFiles'][iLoc.nodeId]
            if (typeof op.data.mods.coid !== 'undefined') file.links[op.data.interactionId].coid = op.data.mods.coid
            if (typeof op.data.mods.tCreated !== 'undefined') file.links[op.data.interactionId].tCreated = op.data.mods.tCreated
            if (typeof op.data.mods.tModified !== 'undefined') file.links[op.data.interactionId].tModified = op.data.mods.tModified
            if (typeof op.data.mods.label !== 'undefined') file.links[op.data.interactionId].label = op.data.mods.label
            if (typeof op.data.mods.anchor !== 'undefined') {
              const oldNodeId = iLoc.nodeId
              const newNodeId = op.data.mods.anchor.nodeId
              file.links[op.data.interactionId].anchor = JSON.parse(JSON.stringify(op.data.mods.anchor))
              if (oldNodeId !== newNodeId) {
                this.content['pdfFiles'][op.data.mods.anchor.nodeId].links[op.data.interactionId] = {
                  ...this.content['pdfFiles'][iLoc.nodeId].links[op.data.interactionId],
                }
                delete this.content['pdfFiles'][iLoc.nodeId].links[op.data.interactionId]
              }
            }
        }
        else {
          console.warn(`Did not find link ${op.data.interactionId}`)
        }
        } break

      case 'editTagging': {
        const iLoc = this.findInteraction(op.data.interactionId, 'pdfFiles', 'taggings')
        if (iLoc) {
          const file = this.content['pdfFiles'][iLoc.nodeId]
          if (uiStore.showVerboseLogging.opProcessing) console.log(`Editing ${iLoc.nodeId}/taggings/${op.data.interactionId}`)
          if (typeof op.data.mods.tagId !== 'undefined') file.taggings[op.data.interactionId].tagId = op.data.mods.tagId
          if (typeof op.data.mods.label !== 'undefined') file.taggings[op.data.interactionId].label = op.data.mods.label
          if (typeof op.data.mods.tModified !== 'undefined') file.taggings[op.data.interactionId].tModified = op.data.mods.tModified
        }

      } break

      case 'editEmotion': {
        const iLoc = this.findInteraction(op.data.interactionId, 'pdfFiles', 'emotions')
        if (iLoc) {
          const file = this.content['pdfFiles'][iLoc.nodeId]
          if (uiStore.showVerboseLogging.opProcessing) console.log(`Editing ${iLoc.nodeId}/emotions/${op.data.interactionId}`)
          if (typeof op.data.mods.emotionId !== 'undefined') file.emotions[op.data.interactionId].emotionId = op.data.mods.emotionId
          if (typeof op.data.mods.label !== 'undefined') file.emotions[op.data.interactionId].label = op.data.mods.label
          if (typeof op.data.mods.tModified !== 'undefined') file.emotions[op.data.interactionId].tModified = op.data.mods.tModified
        }
      } break

      case 'editWeblink': {
        const iLoc = this.findInteraction(op.data.interactionId, 'pdfFiles', 'weblinks')
        if (iLoc) {
          const file = this.content['pdfFiles'][iLoc.nodeId]
          if (uiStore.showVerboseLogging.opProcessing) console.log(`Editing ${iLoc.nodeId}/weblink/${op.data.interactionId}`)
          if (typeof op.data.mods.url !== 'undefined') file.weblinks[op.data.interactionId].url = op.data.mods.url
          if (typeof op.data.mods.label !== 'undefined') file.weblinks[op.data.interactionId].label = op.data.mods.label
          if (typeof op.data.mods.tModified !== 'undefined') file.weblinks[op.data.interactionId].tModified = op.data.mods.tModified
        }
      } break

      case 'deleteAnnotation': {
        const iLoc = this.findInteraction(op.data.interactionId, 'pdfFiles', 'annotations')
        if (iLoc) {
          if (uiStore.showVerboseLogging.opProcessing) console.log(`Deleting ${iLoc.nodeId}/annotations/${op.data.interactionId}`)
          delete this.content.pdfFiles[iLoc.nodeId].annotations[op.data.interactionId]
        }
        } break

      case 'deleteComment':{
        const iLoc = this.findInteraction(op.data.interactionId, 'pdfFiles', 'comments')
        if (iLoc) {
          if (uiStore.showVerboseLogging.opProcessing) console.log(`Deleting ${iLoc.nodeId}/comments/${op.data.interactionId}`)
          delete this.content.pdfFiles[iLoc.nodeId].comments[op.data.interactionId]
        }
        } break

      case 'deleteLink':{
        const iLoc = this.findInteraction(op.data.interactionId, 'pdfFiles', 'links')
        if (iLoc) {
          const linking = this.content.pdfFiles[iLoc.nodeId].links[op.data.interactionId]
          const link  = this.content.links[linking.linkId]
          const srcInteraction = this.findInteraction(link.src, 'pdfFiles', 'links')
          const dstInteraction = this.findInteraction(link.dst, 'pdfFiles', 'links')
          if (uiStore.showVerboseLogging.opProcessing) console.log(`Deleting ${srcInteraction.nodeId}/links/${link.src}, ${dstInteraction.nodeId}/links/${link.dst}, and the link ${linking.linkId} itself`)
          if (srcInteraction) delete this.content.pdfFiles[srcInteraction.nodeId].links[link.src]
          if (dstInteraction) delete this.content.pdfFiles[dstInteraction.nodeId].links[link.dst]
          delete this.content.links[linking.linkId]
        }
        } break

      case 'deleteWeblink':{
        const iLoc = this.findInteraction(op.data.interactionId, 'pdfFiles', 'weblinks')
        if (iLoc) {
          if (uiStore.showVerboseLogging.opProcessing) console.log(`Deleting ${iLoc.nodeId}/weblinks/${op.data.interactionId}`)
          delete this.content.pdfFiles[iLoc.nodeId].weblinks[op.data.interactionId]
        }
        } break

      case 'deleteTagging': {
        const iLoc = this.findInteraction(op.data.interactionId, 'pdfFiles', 'taggings')
        if (iLoc) {
          if (uiStore.showVerboseLogging.opProcessing) console.log(`Deleting ${iLoc.nodeId}/taggings/${op.data.interactionId}`)
          delete this.content.pdfFiles[iLoc.nodeId].taggings[op.data.interactionId]
        }
      } break

      case 'deleteEmotion': {
        const iLoc = this.findInteraction(op.data.interactionId, 'pdfFiles', 'emotions')
        if (iLoc) {
          if (uiStore.showVerboseLogging.opProcessing) console.log(`Deleting ${iLoc.nodeId}/emotions/${op.data.interactionId}`)
          delete this.content.pdfFiles[iLoc.nodeId].emotions[op.data.interactionId]
        }
      } break

      case 'addThread': {
        if (uiStore.showVerboseLogging.opProcessing) console.log('add thread ', op.data )
        const addThreadData: Thread = {
          threadId: op.data.threadId,
          interactionId: op.data.interactionId,
          usergroupId: op.data.usergroupId,
          coid: op.data.coid || null,
          userId: op.data.userId,
          userName: op.data.userName,
          threadName: op.data.threadName || '',
          messages: [],
          tCreated: op.data.tCreated,
          tModified: op.data.tModified,
        }
        this.addThread(addThreadData)
      } break

      case 'addMessage': {
        if (uiStore.showVerboseLogging.opProcessing) console.log('add message ', op.data )
        const addMessageData: Message = {
          messageId: op.data.messageId,
          threadId: op.data.threadId,
          refMessageId: op.data.refMessageId,
          coid: op.data.coid || null,
          userId: op.data.userId,
          userName: op.data.userName,
          text: op.data.text,
          reactionCount: op.data.reactionCount,
          tCreated: op.data.tCreated,
          tModified: op.data.tModified,
        }
        this.addMessage(addMessageData)
      } break

      case 'deleteMessage': {
        const thread = this.content.threads[op.data.threadId]
        if (thread) {
          const messageIndex = thread.messages.findIndex((msg:Message) => { return msg.messageId === op.data.messageId })
          if (messageIndex >= 0) {
            thread.messages.splice(messageIndex, 1)
          }
        }
      } break

      case 'editMessage': {
        const thread = this.content.threads[op.data.threadId]
        if (thread) {
          const messageIndex = thread.messages.findIndex((msg:Message) => { return msg.messageId === op.data.messageId })
          if (messageIndex >= 0) {
            if (typeof op.data.mods.text !== 'undefined') thread.messages[messageIndex].text = op.data.mods.text
            if (typeof op.data.mods.tModified !== 'undefined') thread.messages[messageIndex].tModified = op.data.mods.tModified; else thread.messages[messageIndex].tModified = op.tCreated || null
          }
        }
      } break

      case 'removeUserFromPod':
        if (this.userInfos[op.data.userId]) delete(this.userInfos[op.data.userId])
        const podGroupForRemoval = this.getUsergroupByRole('Pod')
        if (podGroupForRemoval) this.usergroups[podGroupForRemoval.usergroupId].members = podGroupForRemoval.members.filter(userId => userId !== op.data.userId)
        break

      case 'addUserToPod':
        if (!this.userInfos[op.data.userId]) {
          const newUser:UserInfo = {
            userId: op.data.userId,
            userName: op.data.userName,
            color: op.data.color
          }
          this.userInfos[op.data.userId] = newUser
        }
        const podGroupForAddition = this.getUsergroupByRole('Pod')
        if ((podGroupForAddition) && (podGroupForAddition.members.indexOf(op.data.userId) === -1)) {
          this.usergroups[podGroupForAddition.usergroupId].members = [...this.usergroups[podGroupForAddition.usergroupId].members, op.data.userId].sort((a:number, b:number) => a - b)
        }
        break

      case 'editUserInfo':
        if (this.userInfos[op.data.userId]) {
          if (op.data.mods.userName) this.userInfos[op.data.userId].userName = op.data.mods.userName
          if (op.data.mods.color) this.userInfos[op.data.userId].color = op.data.mods.color
        }
        break

      default:
        console.error(`Unknown op ${op.op} in PodClass.applyOp()`, op)
    }
  }

  addPdfFile(data: PdfFile) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addPdfFile got called as Class method`)
    this.content.pdfFiles[data.nodeId] = data
  }

  addPdfPage(data: PdfPage) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addPdfFile got called as Class method`)
    const file = data.nodeId ? this.content.pdfFiles[data.nodeId] : false
    if (file && data.no) {
      file.pages[data.no] = data
    }
  }

  addFolder(data: Folder) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addFolder got called as Class method`)
    this.content.folders[data.folderId] = data
  }

  addTag(data: Tag) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addTag got called as Class method`)
    this.content.tags[data.tagId] = data
  }

  addAnnotation(data: iAnnotation) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addAnnotation got called as Class method`, data)
    const file = this.content.pdfFiles[data.anchor.nodeId]

    if (file) {
      if (!file.annotations) file.annotations = {}
      if (typeof file.annotations[data.interactionId] === 'undefined') file.annotations[data.interactionId] = data; else interactionMerge(file.annotations[data.interactionId], data)
    }
    else {
      console.warn(`Did not add annotation ${data.interactionId} because file ${data.anchor.nodeId} was missing: ${file}`)
    }
  }

  addComment(data: iComment) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addComment got called as Class method`, data)
    const file = this.content.pdfFiles[data.anchor.nodeId]

    if (file) {
      if (!file.comments) file.comments = {}
      if (typeof file.comments[data.interactionId] === 'undefined') file.comments[data.interactionId] = data; else interactionMerge(file.comments[data.interactionId], data)
    }
    else {
      console.warn(`Did not add comment ${data.interactionId} because file ${data.anchor.nodeId} was missing: ${file}`)
    }
  }

  addLink(data: iLink) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addComment got called as Class method`, data)
    const file = this.content.pdfFiles[data.anchor.nodeId]

    if (file) {
      if (!file.links) file.links = {}
      if (typeof file.links[data.interactionId] === 'undefined') file.links[data.interactionId] = data; else interactionMerge(file.links[data.interactionId], data)

      // Set the meta object
      const nodeIds = Object.keys(this.content.pdfFiles)
      const linkInteraction = {
        src: { nodeId: '', interactionId: '' },
        dst: { nodeId: '', interactionId: '' },
      }
      nodeIds.forEach((nodeId:string) => {
        const linkIds = Object.keys(this.content.pdfFiles[nodeId].links)
        linkIds.forEach((interactionId: string) => {
          if (this.content.pdfFiles[nodeId].links[interactionId].linkId === data.linkId) linkInteraction[this.content.pdfFiles[nodeId].links[interactionId].which] = { nodeId, interactionId }
        })
      })

      if (linkInteraction.src.nodeId && linkInteraction.dst.nodeId) {
        this.content.links[data.linkId] = {
          linkType: data.linkType,
          src: linkInteraction.src.interactionId,
          dst: linkInteraction.dst.interactionId,
        }
      }
    }
    else {
      console.warn(`Did not add linking ${data.interactionId} because file ${data.anchor.nodeId} was missing: ${file}`)
    }
  }

  addWeblink(data: iWeblink) {
    const file = this.content.pdfFiles[data.anchor.nodeId]

    if (file) {
      if (!file.weblinks) file.weblinks = {}
      if (typeof file.weblinks[data.interactionId] === 'undefined') file.weblinks[data.interactionId] = data; else interactionMerge(file.weblinks[data.interactionId], data)
    }
    else {
      console.warn(`Did not add weblink ${data.interactionId} because file ${data.anchor.nodeId} was missing: ${file}`)
    }
  }

  addTagging(data: iTag) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addTagging got called as Class method`, data)
    const file = this.content.pdfFiles[data.anchor.nodeId]

    if (file) {
      if (!file.taggings) file.taggings = {}
      if (typeof file.taggings[data.interactionId] === 'undefined') file.taggings[data.interactionId] = data; else interactionMerge(file.taggings[data.interactionId], data)
    }
    else {
      console.warn(`Did not add tagging ${data.interactionId} because file ${data.anchor.nodeId} was missing: ${file}`)
    }
  }

  addEmotion(data: iEmotion) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addEmotion got called as Class method`, data)
    const file = this.content.pdfFiles[data.anchor.nodeId]

    if (file) {
      if (!file.emotions) file.emotions = {}
      if (typeof file.emotions[data.interactionId] === 'undefined') file.emotions[data.interactionId] = data; else interactionMerge(file.emotions[data.interactionId], data)
    }
    else {
      console.warn(`Did not add emotion ${data.interactionId}/${data.emotionId} because file ${data.anchor.nodeId} was missing: ${file}`)
    }
  }

  addThread(data: Thread) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addThread got called as Class method`, data)
    if (typeof this.content.threads[data.threadId] === 'undefined') this.content.threads[data.threadId] = data
  }

  addMessage(data: Message) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addMessage got called as Class method`, data)
    if (this.content.threads[data.threadId]) {
      const messageIndex = this.content.threads[data.threadId].messages.findIndex((msg:Message) => { return msg.messageId === data.messageId })
      if (messageIndex === -1) {
        //console.log(`Adding message to thread:`, data)
        this.content.threads[data.threadId].messages.push(data as Message)
      }
      else {
        this.content.threads[data.threadId].messages[messageIndex] = data as Message
      }
      this.content.threads[data.threadId].messages.sort((a: Message, b: Message) => {
        if (a.coid && b.coid) return a.coid - b.coid
        if (a.coid && !b.coid) return -1
        if (!a.coid && b.coid) return 1
        if (a.tCreated && b.tCreated) return a.tCreated - b.tCreated
        return 0
      })
    }
  }

  getPdfFiles() {
    return Object.keys(this.content.pdfFiles).map(nodeId => this.content.pdfFiles[nodeId])
  }

  getFolders() {
    return Object.keys(this.content.folders).map(folderId => this.content.folders[folderId])
  }

  getAnnotation(interactionId: string): iAnnotation | null {
    const nodeIds = Object.keys(this.content.pdfFiles)
    nodeIds.forEach(nodeId => {
      const s = this.getAnnotations(nodeId)
      if (s) return s[0] as iAnnotation;
    })
    return null
  }

  getInteraction(interactionId:string, contentType:'pdfFiles'|'' = '', interactionType:'annotations'|'comments'|'links'|'weblinks'|'taggings'|'emotions'|'' = ''): iAnnotation | iComment | iLink | iWeblink | iTag | iEmotion | null {
    const iLoc = this.findInteraction(interactionId, contentType, interactionType)
    // console.log(`getInteraction(${interactionId})`, iLoc)
    if (iLoc) {
      switch(iLoc.interactionType) {
        case 'annotations': return this.content.pdfFiles[iLoc.nodeId].annotations[interactionId]
        case 'comments': return this.content.pdfFiles[iLoc.nodeId].comments[interactionId]
        case 'links': return this.content.pdfFiles[iLoc.nodeId].links[interactionId]
        case 'weblinks': return this.content.pdfFiles[iLoc.nodeId].weblinks[interactionId]
        case 'emotions': return this.content.pdfFiles[iLoc.nodeId].emotions[interactionId]
        case 'taggings': return this.content.pdfFiles[iLoc.nodeId].taggings[interactionId]
      }
    }
    return null
  }

  getMessage(messageId: string, threadId:string|null = null): Message | null {
    const threads = threadId ? {threadId: this.content.threads[threadId]} : this.content.threads
    var result = null
    Object.keys(threads).forEach((threadId) => {
      threads[threadId].messages.forEach((msg:Message) => {
        if (msg.messageId === messageId) result = msg
      })
    })
    return result
  }

  getThreadFromMessage(messageId: string): Thread | null {
    var result = null
    Object.keys(this.content.threads).forEach((threadId) => {
      this.content.threads[threadId].messages.forEach((msg:Message) => {
        if (msg.messageId === messageId) result = this.content.threads[threadId]
      })
    })
    return result
  }

  getAnnotations(nodeId: string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return null

    const res: iAnnotation[] = []
    if (file && file.annotations) Object.keys(file.annotations).forEach(annotationId => {
      if (file.annotations && file.annotations[annotationId]) res.push(file.annotations[annotationId])
    })
    return res
  }

  getComments(nodeId: string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return null

    const res: iComment[] = []
    if (file && file.comments) Object.keys(file.comments).forEach(commentId => {
      if (file.comments && file.comments[commentId]) res.push(file.comments[commentId])
    })
    return res
  }

  getLinks(nodeId: string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return null

    const res: iLink[] = []
    if (file && file.links) Object.keys(file.links).forEach(linkId => {
      if (file.links && file.links[linkId]) res.push(file.links[linkId])
    })
    return res
  }

  getLinkOther(link: iLink) {
    const otherName = link.which === 'src' ? 'dst' : 'src'
    const other = this.content.links[link.linkId][otherName]
    const iLoc = this.findInteraction(other, 'pdfFiles', 'links')
    if (iLoc) return this.content.pdfFiles[iLoc.nodeId].links[other]
    return false
  }

  getWeblinks(nodeId: string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return null
    if (file && file.weblinks) {
      const res: iWeblink[] = Object.keys(file.weblinks).map(linkId => file.weblinks[linkId])
      return res
    }

    return null
  }

  getTags(nodeId: string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return null

    // list of tags of a pdf
    const res: iTag[] = []
    if (file && file.taggings) Object.keys(file.taggings).forEach(tagId => {
      if (file.taggings && file.taggings[tagId]) res.push(file.taggings[tagId])
    })
    return res
  }

  getEmotions(nodeId: string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return null

    const res: iEmotion[] = []
    if (file && file.emotions) Object.keys(file.emotions).forEach(emotionId => {
      if (file.emotions && file.emotions[emotionId]) res.push(file.emotions[emotionId])
    })
    return res
  }

  setStatus(status: PodLoadState) {
    this.status = status
  }

  setLoadStatus(status:number) {
    this.loadStatus = status
  }

  setLastSyncOid(oid:number) {
    if (oid) this.lastSyncOid = oid
  }

  getUsergroupByRole(role:string) {
    if (role==='Private') return {
      usergroupId: '',
      name: 'Private',
      role: 'Private',
      members: [],
      permissions: [],
    } as Usergroup
    const usergroupId = Object.keys(this.usergroups).find(usergroupId => this.usergroups[usergroupId].role === role)
    if (usergroupId) return this.usergroups[usergroupId] as Usergroup; else throw(new Error(`Could not resolve usergroup in Pod.getUsergroupByRole(${role}): ${JSON.stringify(this.usergroups)}`))
  }

  getInteractionFromThreadId(threadId: string) {
    const thread = this.content.threads[threadId]
    if (!thread) return null

    const interactionId = thread.interactionId
    if(interactionId) {
      const interaction = this.getInteraction(interactionId)
      if (interaction) return interaction
    }

    return null
  }

  fingerprint(hashed:boolean = true, userNeutral:boolean = false) {

    const hashAnchor = (anchor:any) => {
      return sessionStore.convertBase64.fromInt(murmurhash(`${anchor.nodeId} ${JSON.stringify(anchor.rects.map)} ${anchor.relText}`))
    }

    try {
      const folderNids   = Object.keys(this.content.folders).sort()
      const pdfFileNids  = Object.keys(this.content.pdfFiles).sort()
      const tagIds       = Object.keys(this.content.tags).sort()
      const linkIds      = Object.keys(this.content.links).sort()
      const usergroupIds = Object.keys(this.usergroups).sort()
      const threadIds    = Object.keys(this.content.threads).sort()

      var pdfFiles: string[] = []

      const folderData    = folderNids.map((folderId:string) => { const folder = this.content.folders[folderId]; return `folder:${folder.folderId}:${folder.name}:(${folder.coid}-${folder.tCreated}-${folder.tModified})` })
      const linkData      = linkIds.map((linkId: string) => { const link = this.content.links[linkId]; return `link:${linkId}:${link.linkType}->${link.src}/${link.dst}` })
      const tagData       = tagIds.map((tagId:string) => { const tag = this.content.tags[tagId]; return `tag:${tagId}: ${tag.name} / ${tag.description}: :(${tag.coid}-${tag.tCreated}-${tag.tModified})` })
      const usergroupData = usergroupIds.map((usergroupId:string) => { const usergroup = this.usergroups[usergroupId]; return `${usergroup.usergroupId}: ${usergroup.role}: ${usergroup.name}: [${usergroup.members.join(', ')}]` })
      const threadData    = threadIds.map((threadId:string) => {
        const thread = this.content.threads[threadId]
        const messages = thread.messages.map((msg:Message) => { return "\n  M:" + `${msg.messageId}: ${sessionStore.convertBase64.fromInt(murmurhash(msg.text ? msg.text: ""))}: (${msg.refMessageId}:${msg.userId}:${msg.coid}/${msg.tCreated}/${msg.tModified}` }).join("")
        return `Thread ${thread.threadId} (${thread.interactionId}/${thread.usergroupId}/${thread.threadName}):${messages}`
      })

      pdfFileNids.forEach((nodeId:string) => {
        const file = this.content.pdfFiles[nodeId]
        var info = `pdf:${file.nodeId}:${file.name}:(${file.coid}-${file.tCreated}-${file.tModified})`

        const annotationIds = Object.keys(file.annotations).sort()
        const annotations = annotationIds.map((interactionId: string) => { const interaction = file.annotations[interactionId]; return `${interaction.interactionId}:${sessionStore.convertBase64.fromInt(murmurhash(interaction.label ? interaction.label : ""))}|${hashAnchor(interaction.anchor)}|${interaction.coid}/${interaction.tCreated}/${interaction.tModified}` })
        const commentIds = Object.keys(file.comments).sort()
        const comments = commentIds.map((interactionId: string) => { const interaction = file.comments[interactionId]; return `${interaction.interactionId}:${sessionStore.convertBase64.fromInt(murmurhash(interaction.label ? interaction.label : ""))}|${hashAnchor(interaction.anchor)}|${interaction.coid}/${interaction.tCreated}/${interaction.tModified}` })
        const linkIds = Object.keys(file.links).sort()
        const links = linkIds.map((interactionId: string) => { const interaction = file.links[interactionId]; return `${interaction.interactionId}/${interaction.linkId}:${sessionStore.convertBase64.fromInt(murmurhash(interaction.label ? interaction.label : ""))}|${hashAnchor(interaction.anchor)}|${interaction.coid}/${interaction.tCreated}/${interaction.tModified}` })
        const weblinkIds = Object.keys(file.weblinks).sort()
        const weblinks = weblinkIds.map((interactionId: string) => { const interaction = file.weblinks[interactionId]; return `${interaction.interactionId}/${sessionStore.convertBase64.fromInt(murmurhash(interaction.url ? interaction.url: ""))}:${sessionStore.convertBase64.fromInt(murmurhash(interaction.label ? interaction.label : ""))}|${hashAnchor(interaction.anchor)}|${interaction.coid}/${interaction.tCreated}/${interaction.tModified}` })
        const emotionsIds = Object.keys(file.emotions).sort()
        const emotions = emotionsIds.map((interactionId: string) => { const interaction = file.emotions[interactionId]; return `${interaction.interactionId}/${interaction.emotionId}:${sessionStore.convertBase64.fromInt(murmurhash(interaction.label))}|${hashAnchor(interaction.anchor)}|${interaction.coid}/${interaction.tCreated}/${interaction.tModified}` })

        if (annotations.length) info += (userNeutral ? "\n  omitted for neutrality" : "\n  " + `A:${annotations.join("\n  A:")}`)
        if (comments.length) info += "\n  " + `C:${comments.join("\n  C:")}`
        if (links.length) info += "\n  " + `L:${links.join("\n  L:")}`
        if (weblinks.length) info += "\n  " + `W:${weblinks.join("\n  L:")}`
        if (emotions.length) info += "\n  " + `L:${emotions.join("\n  L:")}`

        pdfFiles.push(info)
      })

      const fingerprint = `Pod: ${this.podId}`
                        + "\n" + (userNeutral ? 'omitted for neutrality' : usergroupData.join("\n"))
                        + "\n" + tagData.join("\n")
                        + "\n" + folderData.join("\n")
                        + "\n" + linkData.join("\n")
                        + "\n" + pdfFiles.join("\n")
                        + "\n" + (userNeutral ? 'omitted for neutrality' : threadData.join("\n"))

      if (hashed) return sessionStore.convertBase64.fromInt(murmurhash(fingerprint))
      return fingerprint
    }
    catch(e) {
      console.error(e)
      return ''
    }
  }

  /**
   * Finds an interaction in the current pod by looking in all content types and all interaction types for the correct interactionId. Additional filters for content type and annotation type may be applied
   * Returns the contentType, the nodeId, and the interactionType of the interaction, or false
   */
  findInteraction(interactionId:string, contentType:'pdfFiles'|'' = '', interactionType:'annotations'|'comments'|'links'|'weblinks'|'taggings'|'emotions'|'' = '') {

    const search = (interactionId: string, contentType: string) => {
      var searchNode:any = false
      switch(contentType) {
        case 'pdfFiles': searchNode = this.content.pdfFiles; break
      }
      if (!searchNode) return false
      const nodeIds = Object.keys(searchNode)
      for(const nodeId of nodeIds) {
        const pdfFile = searchNode[nodeId]
        if (pdfFile.annotations[interactionId]) return { contentType, nodeId, interactionType: 'annotations' }
        if (pdfFile.comments[interactionId]) return { contentType, nodeId, interactionType: 'comments' }
        if (pdfFile.links[interactionId]) return { contentType, nodeId, interactionType: 'links' }
        if (pdfFile.weblinks[interactionId]) return { contentType, nodeId, interactionType: 'weblinks' }
        if (pdfFile.taggings[interactionId]) return { contentType, nodeId, interactionType: 'taggings' }
        if (pdfFile.emotions[interactionId]) return { contentType, nodeId, interactionType: 'emotions' }
      }
      return false
    }

    const contentTypes = ['pdfFiles']

    for(var i=0; i<contentTypes.length; i++) {
      if ((contentType === '') || (contentType === contentTypes[i])) {
        var res: any = false
        res = search(interactionId, contentTypes[i])
        if ((interactionType === '') || ((res.interactionType === interactionType))) return res
      }
    }
    return false
  }

}