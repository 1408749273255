import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from "../../../stores/uiStore"
import { useStore } from "../../../contexts/store"
import { InteractionType, iEmotion, iTag, iWeblink } from '../../../../../types/Interaction'
import Blockquote from '../../Elements/Blockquote'

import {Box, Drawer, IconButton} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowForwardIos'
import Thread from '../../Elements/Thread'
import Note from '../../Elements/Note'
import FollowLink from '../../Elements/FollowLink'
import Tagging from '../../Elements/Tagging'
import Weblink from '../../Elements/Weblink'
import Emotion from '../../Elements/Emotion'
import { useSwipeable } from 'react-swipeable'
import { navigate } from 'wouter/use-location'
import { t } from 'i18next'
import dayjs from 'dayjs'

const InteractionDrawer = ({storeId, pageListRef}: {storeId: string, pageListRef: any}) => {
  let isOpen = false
  const { uiStore, podStore } = useStore()
  const { podId, pdfId, interactionType, interactionId } = useParams()
  const pdfUrl = `/pod/${podId}/pdf/${pdfId}`

  // the display of the drawer is controlled by the route, navigating to the pdf closes the drawer
  const handleClose = () => {
    uiStore.setEditedMessageId(null)
    navigate(`${pdfUrl}`, {replace: true})
  }

  const handlers = useSwipeable({
    onSwipedRight: handleClose,
  })


  const pod = podStore.pod
  if (!pod) return null

  const interaction = interactionId ? podStore.getInteraction(pdfId, interactionId) : null

  // drawer opens only if the user is on the route of an interaction
  if(interaction && interactionType && interactionId) isOpen = true

  // drawer adapts to the width of the screen and height of the header
  const drawerWidth = 500
  const width = uiStore.width
  let headerHeight = document.getElementById("app-header")?.offsetHeight
  if(!headerHeight) headerHeight = 64

  const Header = () => {
    if (!interaction) return null
    // interaction color
    const color = interaction.style.color ? interaction.style.color : uiStore.getInteractionColor(interactionType as InteractionType)
    return <Box sx={{
      alignContent: "center",
      backgroundColor: color,
      display: "grid",
      gridTemplateColumns: "min-content",
      height: "60px",
      padding: "0 20px",
      position: "sticky",
      top: 0,
      zIndex: 5
    }}
    >
      <IconButton aria-label="back" onClick={handleClose}>
        <ArrowBackIcon sx={{color: "white"}} />
      </IconButton>
    </Box>
  }

  const Content = () => {
    if (!interaction) return null
    const interactionType = interaction.interactionType
    if(interactionType === "annotation") return <Note backUrl={pdfUrl} interactionId={interactionId} op="editAnnotation" podId={podId} textInput={interaction.label} />
    if(interactionType === "comment") return <Thread interactionId={interactionId} nodeId={interaction.anchor.nodeId} />
    if(interactionType === "link") return <FollowLink linkId={interactionId} pageListRef={pageListRef} pdfId={pdfId} podId={podId} storeId={storeId} />
    if(interactionType === "tagging") return <Tagging  pageListRef={pageListRef} pdfId={pdfId} podId={podId} storeId={storeId} tag={interaction as iTag} />
    if(interactionType === "weblink") return <Weblink weblink={interaction as iWeblink} />
    if(interactionType === "emotion") return <Box sx={{ display: 'grid', gridTemplateColumns: 'max-content auto', alignItems: 'center', gridGap: '10px' }}><Emotion emotion={interaction as iEmotion} />{pod.userInfos[interaction.userId].userName}<br />{t('user_on_date')} {dayjs(interaction.tModified * 1000).format('D MMM, HH:mm')}</Box>
    return null
  }

  const Container = () => {
    return <Box
      sx={{
        display: "grid",
        gridTemplateRows: "min-content min-content auto",
        width: drawerWidth < width ? drawerWidth : width,
      }}
      role="presentation"
    >
      <Header />
      { interaction ? <Blockquote interaction={interaction} /> : null }
      <Content />
    </Box>
  }

  return (
    <Drawer
      anchor={"right"}
      open={isOpen}
      onClose={handleClose}
      {...handlers}
      transitionDuration={{ appear: 100, enter: 100, exit: 100 }}
      sx={{
        marginTop: `${headerHeight}px`,
        '&.MuiDrawer-root .MuiModal-backdrop': { position: "absolute" },
        '&.MuiDrawer-root .MuiDrawer-paper': { marginTop: `${headerHeight}px`, height: `calc(100% - ${headerHeight}px)` },
      }}
    >
      <Container />
    </Drawer>
  )
}

export default observer(InteractionDrawer)