import React from 'react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../contexts/store'

import HeaderMenuButton from './HeaderMenuButton'

import { useTranslation } from 'react-i18next'

import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { Badge, BadgeOrigin } from '@mui/material';

import { env } from '../../api/api';

const SyncStatus:React.FC = () => {
  const { uiStore } = useStore()
  const { broadcastStore, opStore } = useStore()
  const { t } = useTranslation()

  const reportMode: "plain" | "verbose" = env.dev ? 'verbose' : 'plain'
  
  const badgeAnchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  } as BadgeOrigin

  const clientQueueLength = opStore.static.queue.length ? opStore.static.queue.length : 0
  const { syncQueueLength, lastSyncTimestamp, lastSyncStatus } = broadcastStore.serviceWorkerStatus
  const timeSinceLastSync = Math.floor(Date.now()/1000) - lastSyncTimestamp

  var badgeInfo:string|number = syncQueueLength
  if (reportMode === 'verbose') badgeInfo = `${clientQueueLength}/${syncQueueLength}`

  var badgeColor: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning"
  badgeColor = 'default'

  var title = t('SHRIMP_PODS is online.')
  var connectivityIcon = <WifiIcon />

  if (uiStore.isOffline) {
    title = t('SHRIMP_PODS is offline.')
    connectivityIcon = <WifiOffIcon />
  }

  if (lastSyncStatus !== 200) {
    badgeColor = 'info'
    title += ' ' + t('The last sync failed with status {{lastSyncStatus}}.', {lastSyncStatus})
    if (badgeInfo === 0) badgeInfo = '!'
  }

  if ((lastSyncTimestamp < 1600000000) && (syncQueueLength)) {
    badgeColor = 'warning'
    title += ' ' + t('You have not yet successfully synced. You should go online soon!')
  } else if ((timeSinceLastSync > 3600) && (syncQueueLength)) {
    badgeColor = 'warning'
    title += ' ' + t('The last successful sync was more than {{count}} hours ago. You should go online soon!', {count: Math.floor(timeSinceLastSync/3600)})
  }

  if (syncQueueLength) title += ' ' + t('You currently have {{count}} unsynced changes.', {count: syncQueueLength})
  
  const badgeIcon = <Badge anchorOrigin={badgeAnchorOrigin} color={badgeColor} badgeContent={badgeInfo}>
    {connectivityIcon}
  </Badge>

  return <HeaderMenuButton to="/syncing" title={title} description={`Sync Indicator: ${title}`} icon={ badgeIcon }/>
}

export default observer(SyncStatus)