import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../contexts/store'
import podStore from '../../stores/podStore'
import api from '../../api/api';
import { idbStoredOp } from '../../../../types/Ops'
import { Button } from '@mui/material';

const System: React.FC = () => {
  const pendingOps: idbStoredOp[] = []
  const { broadcastStore, opStore } = useStore()
  const [ swFingerprint, setSwFingerprint ] = useState('')
  const [ backendFingerprint, setBackendFingerprint ] = useState('')
  const [ swPendingOps, setSwPendingOps ] = useState(pendingOps)
  const [ highlighted, setHighlighted ] = useState('')

  useEffect(() => {
    if (swPendingOps.length !== broadcastStore.serviceWorkerStatus.syncQueueLength) {
      const fetchData = async () => {
        await getServiceWorkerPendingOps(broadcastStore.serviceWorkerStatus.syncQueueLength)
      }
      fetchData().catch(console.error)
    }
  })

  var localPodFingerprint = '...'
  var serviceWorkerPodFingerprint = '...'
  const clientQueueLength = opStore.static.queue.length ? opStore.static.queue.length : 0

  const pod = podStore.pod
  if (pod) {
    localPodFingerprint = pod.fingerprint()
    if (pod.serviceWorkerFingerprint) serviceWorkerPodFingerprint = pod.serviceWorkerFingerprint
  }

  const updateSwFingerprint = async () => {
    if (podStore.pod?.podId) {
      const res = await api.getPodFingerprint(podStore.pod.podId, false)
      if (res.status === 200) {
        setSwFingerprint(res.serviceWorkerData.replace())
        setBackendFingerprint(res.backendData.replace())
      }
    }
  }

  const getServiceWorkerPendingOps = async (n:number) => {
    if ((n) && (swPendingOps.length === 0)) {
      const res = await api.getPendingOps()
      if (res) setSwPendingOps(res); else setSwPendingOps([])
    }
    return null
  }

  var decodeEntities = (function() {
    // this prevents any overhead from creating the object each time
    var element = document.createElement('div');
  
    function decodeHTMLEntities (str:any) {
      if(str && typeof str === 'string') {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = '';
      }
  
      return str;
    }
  
    return decodeHTMLEntities;
  })();  

  const handleClick = (data:any) => {
    setHighlighted(decodeEntities(data))
  }

  const frontendLines = podStore.pod?.fingerprint(false).split("\n")
  const serviceWorkerLines = swFingerprint.split("\n")
  const backendLines = backendFingerprint.split("\n")

  const fingerprintLines:Array<JSX.Element> = []
  for(var i=0; i<Math.max(frontendLines?.length, serviceWorkerLines?.length, backendLines?.length); i++) {
    var lineStyle = {
      color:'darkgreen',
    }
    if ((frontendLines[i] !== serviceWorkerLines[i]) || (serviceWorkerLines[i] !== backendLines[i])) lineStyle = {
      color:'red',
    }
    fingerprintLines.push(<tr key={i} style={lineStyle}>
      <td onMouseOver={e => handleClick(e.currentTarget.innerHTML)} style={{borderRight:'1px solid #000', borderBottom:'1px solid #000', backgroundColor:(frontendLines[i] === highlighted) ? '#afa' : '#fff'}}>{frontendLines[i]}</td>
      <td onMouseOver={e => handleClick(e.currentTarget.innerHTML)} style={{borderRight:'1px solid #000', borderBottom:'1px solid #000', backgroundColor:(serviceWorkerLines[i] === highlighted) ? '#afa' : '#fff'}}>{serviceWorkerLines[i]}</td>
      <td onMouseOver={e => handleClick(e.currentTarget.innerHTML)} style={{borderRight:'1px solid #000', borderBottom:'1px solid #000', backgroundColor:(backendLines[i] === highlighted) ? '#afa' : '#fff'}}>{backendLines[i]}</td>
    </tr>  
    )
  }


  return <div style={{overflow:'scroll'}}>
    <h1>Pod {podStore.pod?.podId}</h1>
    <button disabled={!(podStore.pod?.podId)} onClick={async() => { if (podStore.pod?.podId) { const podId = podStore.pod?.podId; await podStore.resetPod(podId); podStore.loadPod(podId) } }} title={'Unloads the pod and triggers a reload from the backend'}>
      Reset Pod
    </button>
    <h2>Sync</h2>
    <pre>
      LastSyncOid: {podStore.pod?.lastSyncOid}
    </pre>
    <h2>Fingerprint</h2>
      <pre>
        Client:        {localPodFingerprint}<br />
        ServiceWorker: {serviceWorkerPodFingerprint}
      </pre>
    Unhashed Fingerprints: <Button variant="outlined" onClick={updateSwFingerprint}>Click to load serviceWorker/Backend Data</Button><br />
    (Highlight: {highlighted})
    <table style={{fontSize:'9px', width:'100%'}}>
      <tbody>
        <tr>
          <th style={{width:'33.4%', borderBottom:'1px solid #000'}}>Client</th>
          <th style={{width:'33.3%', borderBottom:'1px solid #000'}}>Service Worker</th>
          <th style={{width:'33.3%', borderBottom:'1px solid #000'}}>Backend</th>
        </tr>
        {fingerprintLines}
      </tbody>
    </table>
    <h2>{clientQueueLength} Pending Ops in Client</h2>
    <ol>
    {opStore.static.queue.map((row: any, i:number) => {
      return <li key={i}>{row.opLogId}: {row.op}</li>
    })}
    </ol>
    <h2>{broadcastStore.serviceWorkerStatus.syncQueueLength} Pending Ops in ServiceWorker</h2>
    <table border={1}><tbody>
    { swPendingOps.map((row: any, i:number) => {
      return <tr key={i}>
        <td>{i+1}</td>
        <td>{row.podId}</td>
        <td>{row.opLogId}</td>
        <td>{row.op}</td>
      </tr>
    }) }
    </tbody></table>
  </div>
}

export default observer(System)